import React, { Component } from "react";

import moment from "moment";
import DatePicker from "react-date-picker";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  createCoupon,
  getCouponDetail,
  updateCouponDetail,
} from "../../Redux/Actions/couponAction";

import withNavigate from "../../Components/customHooks/navigate";

class Coupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      couponId: "",
      couponName: "",
      couponCode: "",
      couponType: "%",
      discount: "",
      minimumOrderAmount: "",
      couponValidity: "",
      minimumDate: "",
      couponDescription: "",
      isEdit: false,
      successMessage: "",
      errorMessage: "",
      errorData: {},
      showLoader: false,
    };

    if (
      this.props.location.pathname ===
      `/edit-coupon/${this.props.param.couponId}`
    ) {
      this.fetchCouponDetail(this.props.param.couponId);
    }
  }

  fetchCouponDetail = async (couponId) => {
    let couponDetail = await this.props.getCouponDetail(couponId);

    if (couponDetail?.data?.data?.length > 0) {
      // product found
      couponDetail = couponDetail.data.data[0];

      this.setState({
        couponId: couponDetail.id,
        couponName: couponDetail.name,
        couponCode: couponDetail.code,
        couponType: couponDetail.type,
        discount: couponDetail.discount.toString(),
        minimumOrderAmount: couponDetail.minOrderAmount.toString(),
        couponValidity: couponDetail.validUptoTS,
        couponDescription: couponDetail.description,
        isEdit: true,
      });
    } else {
      this.props.navigate("/coupon");
    }
  };

  componentDidMount() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    this.setState({
      minimumDate: [year, month, day].join("-"),
    });
  }

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }
    let value;
    if (key === "couponValidity") {
      value = e ? moment(e).format("YYYY-MM-DD") : "";
    } else {
      value = e.target.value;
    }
    if (key === "couponCode") {
      value = value.toUpperCase();
    }

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  validateDiscountAmount = () => {
    let isFormValid = true;
    this.state.errorData = {
      discount: [],
    };

    if (
      this.state.couponType.trim() === "$" &&
      +this.state.discount.trim() > +this.state.minimumOrderAmount.trim()
    ) {
      isFormValid = false;
      this.state.errorData.discount = [
        "Discount amount cannot be greater than minimum order amount.",
      ];
    }

    if (
      this.state.couponType.trim() === "%" &&
      +this.state.discount.trim() > 100
    ) {
      isFormValid = false;
      this.state.errorData.discount = [
        "Discount % cannot be greater than 100%.",
      ];
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  validateCouponInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      couponName: [],
      couponCode: [],
      couponType: [],
      discount: [],
      minimumOrderAmount: [],
      couponValidity: [],
      couponDescription: [],
    };

    if (this.state.couponName.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.couponName = ["Coupon Name field cannot be empty."];
    } else if (
      this.state.couponName.includes(`"`) ||
      this.state.couponName.includes(`'`) ||
      this.state.couponName.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.couponName = ["This field cannot contain quotes"];
    }

    if (this.state.couponCode.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.couponCode = ["Coupon Code field cannot be empty."];
    } else if (
      this.state.couponCode.includes(`"`) ||
      this.state.couponCode.includes(`'`) ||
      this.state.couponCode.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.couponCode = ["This field cannot contain quotes"];
    }

    if (this.state.couponType.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.couponType = ["Coupon Type field cannot be empty."];
    } else if (
      this.state.couponType.trim().length > 0 &&
      this.state.couponType.trim() !== "%" &&
      this.state.couponType.trim() !== "$"
    ) {
      isFormValid = false;
      this.state.errorData.couponType = [
        "Coupon Type can either be '$' or '%'",
      ];
    }

    if (this.state.discount.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.discount = ["Discount field cannot be empty."];
    } else if (
      this.state.discount.trim().length > 0 &&
      !this.state.discount.match(/^[0-9]+$/g)
    ) {
      isFormValid = false;
      this.state.errorData.discount = ["Enter a valid discount."];
    }

    if (this.state.minimumOrderAmount.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.minimumOrderAmount = [
        "Minimum Order Amount field cannot be empty.",
      ];
    } else if (
      this.state.minimumOrderAmount.trim().length > 0 &&
      !this.state.minimumOrderAmount.match(/^[0-9]+$/g)
    ) {
      isFormValid = false;
      this.state.errorData.minimumOrderAmount = [
        "Enter a valid minimum amount.",
      ];
    }

    if (this.state.couponValidity.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.couponValidity = [
        "Valid Onto field cannot be empty.",
      ];
    } else if (
      Date.parse(this.state.minimumDate) > Date.parse(this.state.couponValidity)
    ) {
      isFormValid = false;
      this.state.errorData.couponValidity = ["Please enter correct date."];
    }

    if (this.state.couponDescription.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.couponDescription = [
        "Coupon Description field cannot be empty.",
      ];
    } else if (
      this.state.couponDescription.includes(`"`) ||
      this.state.couponDescription.includes(`'`) ||
      this.state.couponDescription.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.couponDescription = [
        "This field cannot contain quotes",
      ];
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSubmit = async () => {
    await this.setState({
      successMessage: "",
      errorMessage: "",
      showLoader: true,
    });

    if (this.validateCouponInfo()) {
      // validations passed
      if (this.validateDiscountAmount()) {
        // discount validations passed
        let res;
        const {
          couponName,
          couponCode,
          couponType,
          couponDescription,
          discount,
          minimumOrderAmount,
          couponValidity,
        } = this.state;

        if (this.state.isEdit) {
          // call update action
          res = await this.props.updateCouponDetail(
            {
              couponName,
              couponCode,
              couponType,
              couponDescription,
              discount,
              minimumOrderAmount,
              couponValidity,
            },
            this.state.couponId
          );
        } else {
          // call create action
          res = await this.props.createCoupon({
            couponName,
            couponCode,
            couponType,
            couponDescription,
            discount,
            minimumOrderAmount,
            couponValidity,
          });
        }

        if (res && res.data) {
          if (res.data.isSuccess) {
            // success case
            this.setState({
              successMessage: res.data.message,
              showLoader: false,
            });

            window.scrollTo(0, 0);

            if (this.state.isEdit) {
              setTimeout(() => {
                this.setState({
                  successMessage: "",
                });
              }, 4000);
            } else {
              this.setState({
                couponName: "",
                couponCode: "",
                discount: "",
                minimumOrderAmount: "",
                couponValidity: "",
                minimumDate: "",
                couponDescription: "",
              });

              setTimeout(() => {
                this.setState({
                  successMessage: "",
                });
              }, 4000);
            }
            setTimeout(() => {
              this.props.navigate("/coupon");
            }, 1000);
          } else {
            // response with failed case
            this.setState({
              errorMessage: res.data.message,
              showLoader: false,
            });
          }
        } else {
          // error to get respone
          this.setState({
            errorMessage: "Something went wrong. Please try again later.",
            showLoader: false,
          });
        }
      } else {
        // discount validations failed
        this.setState({
          showLoader: false,
        });
      }
    } else {
      // validations failed
      this.setState({
        showLoader: false,
      });
    }
  };

  render() {
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>{this.props.param.couponId ? "Edit" : "Create"} Coupon</h2>
        </div>
        <div class="admin-card-box">
          {(() => {
            if (
              this.state.successMessage !== "" &&
              this.state.successMessage !== undefined
            ) {
              return (
                <div className="alert alert-success">
                  {this.state.successMessage}
                </div>
              );
            } else if (
              this.state.errorMessage !== "" &&
              this.state.errorMessage !== undefined
            ) {
              return (
                <div className="alert alert-danger">
                  {this.state.errorMessage}
                </div>
              );
            }
          })()}
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  coupon name
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.couponName}
                  onChange={(e) => this.handleChange(e, "couponName")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.couponName &&
                    this.state.errorData.couponName[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  coupon code
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.couponCode.trim()}
                  onChange={(e) => this.handleChange(e, "couponCode")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.couponCode &&
                    this.state.errorData.couponCode[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  type
                  <sup>*</sup>
                </label>
                <input
                  disabled={true}
                  value={this.state.couponType}
                  onChange={(e) => this.handleChange(e, "couponType")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.couponType &&
                    this.state.errorData.couponType[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  discount value
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.discount}
                  onChange={(e) => this.handleChange(e, "discount")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.discount &&
                    this.state.errorData.discount[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  Minimum Order Amount
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.minimumOrderAmount}
                  onChange={(e) => this.handleChange(e, "minimumOrderAmount")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.minimumOrderAmount &&
                    this.state.errorData.minimumOrderAmount[0]}
                </span>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  Valid Onto
                  <sup>*</sup>
                </label>

                <DatePicker
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  value={
                    this.state.couponValidity &&
                    this.state.couponValidity.length > 0
                      ? new Date(this.state.couponValidity.replace(/-/g, "/"))
                      : ""
                  }
                  onChange={(e) => this.handleChange(e, "couponValidity")}
                  minDate={new Date(this.state.minimumDate.replace(/-/g, "/"))}
                  className="form-control"
                />

                <span className="form-field-error">
                  {this.state.errorData?.couponValidity &&
                    this.state.errorData.couponValidity[0]}
                </span>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>
                  Description
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.couponDescription}
                  onChange={(e) => this.handleChange(e, "couponDescription")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.couponDescription &&
                    this.state.errorData.couponDescription[0]}
                </span>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-btn text-center">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  class="orange-outline-btn"
                >
                  submit
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span class="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createCoupon, getCouponDetail, updateCouponDetail },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(withNavigate(Coupon));
