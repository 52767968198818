export const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']


export const WEEKLY = 'weekly'
export const MONTHLY = 'monthly'
export const BI_WEEKLY = 'bi_weekly'
export const ONE_TIME = "one_time";
export const GROUP = "group";
export const ONE_ON_ONE = "one_on_one";

export const weekDayValue = {
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
}