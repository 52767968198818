import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

function setDataInLocalstorage(paramName, paramValue) {
  localStorage.setItem(paramName, JSON.stringify(paramValue));
  return;
}

function dispatchCartItems(dispatch, cartItems) {
  dispatch({
    type: "CARTITEMS",
    payload: cartItems,
  });
}

export const getCartItems = () => (dispatch) => {
  let cartItems = JSON.parse(localStorage.getItem("cartItems"));
  if (cartItems) {
    dispatchCartItems(dispatch, cartItems);
  } else {
    dispatchCartItems(dispatch, []);
  }
};

export const addToCart = (item, selectedQuantity) => (dispatch) => {
  let cartItems = JSON.parse(localStorage.getItem("cartItems"));
  if (cartItems) {
    let itemIndex = cartItems.findIndex((elem) => elem.id === item.id);
    if (itemIndex >= 0) {
      let itemInCart = cartItems[itemIndex];
      let totalSelectedQuantity =
        itemInCart.selectedQuantity + selectedQuantity;
      if (totalSelectedQuantity > item.quantity)
        itemInCart.selectedQuantity = item.quantity;
      else itemInCart.selectedQuantity = totalSelectedQuantity;

      cartItems[itemIndex] = itemInCart;
      setDataInLocalstorage("cartItems", cartItems);

      dispatchCartItems(dispatch, cartItems);
    } else {
      item["selectedQuantity"] = selectedQuantity;
      cartItems.push(item);
      setDataInLocalstorage("cartItems", cartItems);

      dispatchCartItems(dispatch, cartItems);
    }
  } else {
    item["selectedQuantity"] = selectedQuantity;
    let cartItems = [item];
    setDataInLocalstorage("cartItems", cartItems);

    dispatchCartItems(dispatch, cartItems);
  }
};

export const changeItemQuantity = (item, newQuantity) => (dispatch) => {
  item["selectedQuantity"] = newQuantity;
  let cartItems = JSON.parse(localStorage.getItem("cartItems"));
  if (cartItems) {
    let itemIndex = cartItems.findIndex((elem) => elem.id === item.id);
    cartItems[itemIndex] = item;
    setDataInLocalstorage("cartItems", cartItems);
    dispatchCartItems(dispatch, cartItems);
  }
};

export const removeItemFromCart = (item) => (dispatch) => {
  let cartItems = JSON.parse(localStorage.getItem("cartItems"));
  let itemIndex = cartItems.findIndex((elem) => elem.id === item.id);
  cartItems.splice(itemIndex, 1);
  setDataInLocalstorage("cartItems", cartItems);
  dispatchCartItems(dispatch, cartItems);
};

export const getCountriesList = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-countries-list`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getStatesList = (countryId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-states-list/${countryId}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getCitiesList = (stateId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-cities-list/${stateId}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const checkoutItem = (checkoutData) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/cart`,
      data: checkoutData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data.data && res.data.data.length > 0) {
      setDataInLocalstorage("cartItems", res.data.data);
    }
    dispatch({
      type: "CHECKOUT",
      payload: { data: res.data },
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
