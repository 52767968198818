import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getSearchCategoryList = (queryString) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/search-category/list?${queryString}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getSearchCategoryDetail = (categoryId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/search-category/detail/${categoryId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const createSearchCategory =
  (searchCategoryInfo) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/search-category/add`,
        data: searchCategoryInfo,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const updateSearchCategoryDetail =
  (searchCategoryInfo, categoryId) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/search-category/update-search-category/${categoryId}`,
        data: searchCategoryInfo,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const searchCategory = (offset, searchKeyword) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/search-category/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const hierarchyList = (childIdList) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/search-category/hierarchy`,
      data: childIdList,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getChildCategories =
  (categoryId, isSeeAllChecked, selectedParentProductCategoryId) =>
  async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/search-category/child-category?categoryId=${
          categoryId ? categoryId : 0
        }&isSeeAllChecked=${isSeeAllChecked}`,
        data: selectedParentProductCategoryId,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getAllParentCategoryList = (id) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/search-category/getAllParentCategoryList?id=${id}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({
      type: "LIST_ALL_PARENT_CATEGORY",
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getSellersCategoryList = (offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/search-category/sellers-category-list?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const searchsellerCategory =
  (offset, searchKeyword) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/search-category/by/searchSellersCategory?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };
