import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getpagesList = (offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/pages?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const searchPage = (offset, searchKeyword) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/pages/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getpageDetail = (pageId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/pages/${pageId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updatePageDetail = (pageDetail) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/pages/update-page`,
      data: pageDetail,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
