import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  createSearchCategory,
  getAllParentCategoryList,
  getSearchCategoryDetail,
  updateSearchCategoryDetail,
} from "../../Redux/Actions/searchCategoryAction";

import Select from "react-select";

import { getProductCategoryList } from "../../Redux/Actions/productCategoryAction";

import withNavigate from "../../Components/customHooks/navigate";

class Edit_Search_Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: "",
      categoryName: "",
      parentId: "0",
      categoryDescription: "",
      isEdit: false,
      successMessage: "",
      errorMessage: "",
      errorData: {},
      showLoader: false,
      parentCategoryId: null,
      isCategoryApprove: 0,
      parentList: [],
      parentCategoryList: [],
      parentGroupList: [],
      selectedParentProductCategories: [],
    };
    this.selectedProductCategory = [];
  }

  componentDidMount = async () => {
    this.fetchAllParentCategories();
    const val = await this.props.getProductCategoryList();
    const parentGroupList = val.data.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    this.setState({
      parentGroupList: parentGroupList.filter((i) =>
        [3, 101, 119].includes(i.value)
      ),
    });

    if (
      this.props.location.pathname ===
        `/edit-search-category/${this.props.param.categoryId}` ||
      this.props.location.pathname ===
        `/edit-search-category/${this.props.param.categoryId}/approve`
    ) {
      this.fetchCategoryDetail(this.props.param.categoryId);
    }
  };

  fetchAllParentCategories = async () => {
    this.props.getAllParentCategoryList(this.props.param.categoryId);
  };
  fetchCategoryDetail = async (categoryId) => {
    let categoryDetail = await this.props.getSearchCategoryDetail(categoryId);

    if (categoryDetail?.data?.data?.length > 0) {
      // product category found
      categoryDetail = categoryDetail.data.data[0];
      let parentList = categoryDetail.hierarchy_text
        .split(" ")
        .map((i) => this.replace(i, "_", " "));
      parentList.splice(parentList.length - 1, 1);
      this.selectedProductCategory = this.state?.parentGroupList.filter((i) =>
        categoryDetail?.product_cat_id
          ?.split(",")
          .map((i) => parseInt(i))
          .includes(i.value)
      );
      this.setState({
        categoryId: categoryDetail.id,
        categoryName: categoryDetail.name,
        parentCategoryId:
          categoryDetail.parentId === 0 ? null : categoryDetail.parentId,
        categoryDescription: categoryDetail.description,
        isEdit: true,
        isCategoryApprove: categoryDetail.is_active,
        parentList,
        selectedParentProductCategories: this.selectedProductCategory,
      });
    } else {
      if (this.props.location.pathname.includes("approve")) {
        this.props.navigate("/sellers-category");
      } else {
        this.props.navigate("/search-category");
      }
    }
  };

  replace(string, pattern, replacement) {
    let desiredResponse = string;
    let isSpacesLeft = true;
    while (isSpacesLeft) {
      desiredResponse = desiredResponse.replace(pattern, replacement);
      if (!desiredResponse.includes(pattern)) {
        isSpacesLeft = false;
      }
    }
    return desiredResponse;
  }

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let { value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  validateCategoryInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      categoryName: [],
      parentId: [],
      categoryDescription: [],
    };

    if (this.state.categoryName.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.categoryName = [
        "Category Name field cannot be empty.",
      ];
    }
    if (
      !this.state.selectedParentProductCategories ||
      this.state.selectedParentProductCategories.length == 0
    ) {
      isFormValid = false;
      this.state.errorData.selectedParentProductCategories = [
        "Select alteast one product category.",
      ];
    }
    setTimeout(() => {
      this.state.errorData.selectedParentProductCategories = [];
    }, 1000);
    if (this.state.categoryDescription.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.categoryDescription = [
        "Category description field cannot be empty.",
      ];
    } else if (
      this.state.categoryDescription.includes(`"`) ||
      this.state.categoryDescription.includes(`'`) ||
      this.state.categoryDescription.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.categoryDescription = [
        "This field cannot contain quotes",
      ];
    }

    if (this.state.parentId.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.parentId = [
        "Category Parent Id field cannot be empty.",
      ];
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSubmit = async () => {
    await this.setState({
      successMessage: "",
      errorMessage: "",
      showLoader: true,
    });

    if (this.validateCategoryInfo()) {
      // category validations passed
      let res;
      let {
        categoryId,
        categoryName,
        parentId,
        categoryDescription,
        parentCategoryId,
        isCategoryApprove,
        parentList,
      } = this.state;
      parentList.push(categoryName);
      parentList = parentList.map((i) => this.replace(i, " ", "_"));

      if (this.state.isEdit) {
        // call update action
        res = await this.props.updateSearchCategoryDetail(
          {
            categoryName: this.state.categoryName.trim(),
            parentId,
            categoryDescription,
            parentCategoryId,
            isCategoryApprove,
            parentList,
            selectedProductCategory:
              this.state?.selectedParentProductCategories,
          },
          categoryId
        );
      } else {
        // call create action
        res = await this.props.createSearchCategory({
          categoryName: this.state.categoryName.trim(),
          parentId,
          categoryDescription,
          parentCategoryId,
          isCategoryApprove,
          parentList,
          selectedProductCategory: this.state?.selectedParentProductCategories,
        });
      }

      if (res && res.data) {
        if (res.data.isSuccess) {
          // success case
          this.setState({
            successMessage: res.data.message,
            showLoader: false,
          });

          window.scrollTo(0, 0);

          if (this.state.isEdit) {
            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          } else {
            this.setState({
              categoryId: "",
              categoryName: "",
              parentId: "0",
              categoryDescription: "",
            });

            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          }
          if (this.props.location.pathname.includes("approve")) {
            setTimeout(() => {
              this.props.navigate("/sellers-category");
            }, 1000);
          } else {
            setTimeout(() => {
              this.props.navigate("/search-category");
            }, 1000);
          }
        } else {
          // response with failed case
          this.setState({
            errorMessage: res.data.message,
            showLoader: false,
          });
        }
      } else {
        // error to get respone
        this.setState({
          errorMessage: "Something went wrong. Please try again later.",
          showLoader: false,
        });
      }
    } else {
      // discount validations failed
      this.setState({
        showLoader: false,
      });
    }
  };

  selectParentCategory = async (e) => {
    let { value } = e.target;
    let parent = { id: 0 };
    let parentListNew = [];

    if (value !== 0) {
      parent = this.props.parentCategoryList.filter((p) => p.id === +value)[0];
      parentListNew = [...this.state.parentList];
      parentListNew = parent.name
        .split("->")
        .map((name) => this.replace(name.toString().trim(), "_", " "));
    }

    this.setState({
      parentCategoryId: parent.id,
      parentList: parentListNew,
    });
  };

  selectParentGroup = async (e, value) => {
    this.setState({
      selectedParentProductCategories: e,
    });
  };

  async handleWithAttributeChange(event) {
    const value = +event.target.value;
    this.setState({
      isCategoryApprove: value,
    });
  }

  render() {
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>
            {this.props.param.categoryId ? "Edit" : "Create"} Search Category
          </h2>
        </div>
        <div class="admin-card-box">
          {(() => {
            if (
              this.state.successMessage !== "" &&
              this.state.successMessage !== undefined
            ) {
              return (
                <div className="alert alert-success">
                  {this.state.successMessage}
                </div>
              );
            } else if (
              this.state.errorMessage !== "" &&
              this.state.errorMessage !== undefined
            ) {
              return (
                <div className="alert alert-danger">
                  {this.state.errorMessage}
                </div>
              );
            }
          })()}
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  category name
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.categoryName}
                  onChange={(e) => this.handleChange(e, "categoryName")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.categoryName &&
                    this.state.errorData.categoryName[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>category parent id</label>

                <select
                  class="form-control"
                  value={this.state.parentCategoryId}
                  onChange={this.selectParentCategory}
                >
                  <option value={0} selected>
                    {" "}
                    select a category
                  </option>
                  {this.props.parentCategoryList.length &&
                    this.props.parentCategoryList.map((cate, index) => (
                      <>
                        <option key={index} value={cate.id}>
                          {cate.name}
                        </option>
                      </>
                    ))}
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  Product category
                  <sup>*</sup>
                </label>
                <Select
                  value={this.state.selectedParentProductCategories}
                  isMulti
                  name="value"
                  options={this.state.parentGroupList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e, value) => {
                    this.selectParentGroup(e, value);
                  }}
                />
                <span className="form-field-error">
                  {this.state.errorData?.selectedParentProductCategories &&
                    this.state.errorData.selectedParentProductCategories[0]}
                </span>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>
                  Description
                  <sup>*</sup>
                </label>

                <textarea
                  value={this.state.categoryDescription}
                  onChange={(e) => this.handleChange(e, "categoryDescription")}
                  class="form-control"
                  style={{ minHeight: "100px" }}
                ></textarea>

                <span className="form-field-error">
                  {this.state.errorData?.categoryDescription &&
                    this.state.errorData.categoryDescription[0]}
                </span>
              </div>
            </div>

            <div className="">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Do you want Activate this Category?</label>
                  <div className="custom-radio-input">
                    <p className="container-sub mr-2">
                      No
                      <span>
                        <input
                          type="radio"
                          value="0"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={this.state.isCategoryApprove === 0}
                          name="withAttributeRadio"
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>

                    <p className="container-sub mr-2">
                      Yes
                      <span>
                        <input
                          type="radio"
                          value="1"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={this.state.isCategoryApprove === 1}
                          name="withAttributeRadio"
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label>Hierarchy Label</label>
                <div className="custom-radio-input">
                  <p className="container-sub mr-2">
                    {this.state.parentList.length !== 0 && (
                      <>{this.state.parentList.join(" -> ")}</>
                    )}
                    {this.state.parentList &&
                      this.state.parentList.length !== 0 &&
                      !this.state.parentList.includes(
                        this.state.categoryName
                      ) && <> - {this.state.categoryName}</>}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-btn text-center">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  class="orange-outline-btn"
                >
                  submit
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span class="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createSearchCategory,
      getSearchCategoryDetail,
      updateSearchCategoryDetail,
      getAllParentCategoryList,
      getProductCategoryList,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    parentCategoryList: state.categoryReducer.parentCategoryList,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigate(Edit_Search_Category));
