import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    createMeeting,
    getMeetingsByProductIdAndSellerId,
  
    updateSelectedEvent,
    deleteSelectedEvent,
 getMeetingsBySellerId,
} from "../../Redux/Actions/meetingActions";
import {Button, Modal} from "react-bootstrap";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.scss";
import {CLOSE_MEETING_MODAL} from "../../Redux/ActionType";
import {days} from "../../Constant/Constant";
import {BI_WEEKLY, MONTHLY, WEEKLY, GROUP, ONE_TIME} from "../../Constant/Constant";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const eventStyleGetter = function (event, start, end, isSelected) {

    let background = "#fff";
    let color = "#000";
  
    if (event.status === "pending") {
      background = "#ccc";
      color = "#000";
    }
    if ((event.meetingDurationType === ONE_TIME && (event.groupType === GROUP || event.isSlotBooked)) || (event.type === "free_event")) {
      background = "#e25e52";
      color = "#fff";
    }
    if (event.meetingDurationType === WEEKLY && (event.groupType === GROUP || event.isSlotBooked)) {
      background = "#00c12a";
      color = "#fff";
    }
    if (event.meetingDurationType === BI_WEEKLY && (event.groupType === GROUP || event.isSlotBooked)) {
      background = "#0423ff";
      color = "#fff";
  
    }
    if (event.meetingDurationType === MONTHLY && (event.groupType === GROUP || event.isSlotBooked)) {
      background = "#e4a900";
      color = "#fff";
  
    }
    var style = {
      background,
      borderRadius: "10px",
      opacity: 1,
      color,
      border: "0px",
      display: "block",
      padding: "2px 10px",
      textAlign: "center",
      margin: "3px",
      outline: "none",
    };
    return {
      style: style,
    };
  };

const MeetingCalendar = (props) => {
    let dispatch = useDispatch();
    let {meetingList: eventList, meetingExists} = useSelector(
        (state) => state.meetingReducer
    );
    const [meetingType, setMeetingType] = useState(0); // meeting type = 0 for recurring and 1 for one time
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [originalSlot, setOriginalSlot] = useState({
        start: "",
        end: "",
    });
    const [formData, setFormData] = useState({
        start: "",
        end: "",
    });
    let [eventSelectedDay, setEventSelectedDay] = useState(new Date());
   
    const [cancelMeeting, setCancelMeeting] = useState(false);
    const [editMeeting, setEditMeeting] = useState(false);
    const [showHide, setShowHide] = useState(false);
    const [meetingEvent, setMeetingEvent] = useState(null);
    const [originalMeetingType, setOriginalMeetingType] = useState(0);
    const [meetingDurationType, setMeetingDurationType] = useState(WEEKLY);
    const [originalMeetingDurationType, setOriginalMeetingDurationType] = useState(WEEKLY);
    const [view, setView] = useState("week");
    const [modalData, setModalData] = useState({open : false, data : {}})

    useEffect(() => {
        setEvents(eventList);
    }, [eventList]);


    useEffect(() => {
        fetchMeetingList();
    }, [props.title]);

    useEffect(() => {
        fetchMeetingList();
    }, [selectedDay, view]);

    const fetchMeetingList = () => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let startOfWeekTime, endOfWeekTime;
        if (view === "week") {
            startOfWeekTime = moment(selectedDay)
                .startOf("week")
                .format("YYYY-MM-DD HH:mm:ss");
            endOfWeekTime = moment(selectedDay)
                .endOf("week")
                .format("YYYY-MM-DD HH:mm:ss");
        } else if (view === "month") {
            startOfWeekTime = moment(selectedDay)
                .startOf("month")
                .format("YYYY-MM-DD HH:mm:ss");
            endOfWeekTime = moment(selectedDay)
                .endOf("month")
                .format("YYYY-MM-DD HH:mm:ss");
        }
        if (props.productId) {
            dispatch(
                getMeetingsByProductIdAndSellerId(
                    props.seller_id,
                    props.productId,
                    startOfWeekTime,
                    endOfWeekTime,
                    timezone,
                    view
                )
            )
        } else {
            const {timezone} = JSON.parse(localStorage.getItem('sellerData'))
            dispatch(
                getMeetingsBySellerId(
                    props.seller_id,
                    startOfWeekTime,
                    endOfWeekTime,
                    timezone
                )
            )
        }

    };

    const handleChange = (e) => {
        let {name, value} = e.target;
        formData[name] = value;
        setFormData({...formData});
    };

    const handleSetMeetingEvent = (e, value) => {
        e.preventDefault();
        setMeetingDurationType(WEEKLY);
        setMeetingType(+value);
    };

    const handleModalShowHide = () => {
        setShowHide(false);
        setEditMeeting(false);
        setCancelMeeting(false);
        setFormData({start: "", end: ""});
    };

    const createNewEvent = async () => {
        let selectedStartTimeStamp = new Date(
            `${moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")} ${formData["start"]
            }`
        ).getTime();
        let selectedEndTimeStamp = new Date(
            `${moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")} ${formData["end"]
            }`
        ).getTime();
        if (selectedStartTimeStamp > selectedEndTimeStamp) {
            alert("Please select time correctly");
        } else {
            let SelectedDateList = events.filter(
                (item) =>
                    moment(new Date(item.start)).format("MM-DD-YYYY") ===
                    moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")
            );
            let isError = false;
            for (const event of SelectedDateList) {
                let startTimeStamp = new Date(event.start).getTime();
                let endTimeStamp = new Date(event.end).getTime();
                if (
                    selectedStartTimeStamp >= startTimeStamp &&
                    selectedStartTimeStamp <= endTimeStamp
                ) {
                    isError = true;
                    break;
                } else if (
                    startTimeStamp >= selectedStartTimeStamp &&
                    startTimeStamp <= selectedEndTimeStamp
                ) {
                    isError = true;
                    break;
                }
                if (
                    selectedEndTimeStamp <= endTimeStamp &&
                    selectedEndTimeStamp >= startTimeStamp
                ) {
                    isError = true;
                    break;
                } else if (
                    endTimeStamp <= selectedEndTimeStamp &&
                    endTimeStamp >= selectedStartTimeStamp
                ) {
                    isError = true;
                    break;
                }
            }
                const eventSelectedDayStr =
                    eventSelectedDay.getFullYear() +
                    "-" +
                    (eventSelectedDay.getMonth() + 1) +
                    "-" +
                    eventSelectedDay.getDate();
                if (!editMeeting) {
                    dispatch(
                        createMeeting({
                            seller_id: props.seller_id,
                            product_id: props.productId,
                            slot: [formData],
                            day: days[new Date(eventSelectedDay).getDay()],
                            originalSlot: [originalSlot],
                            date: eventSelectedDayStr,
                            meetingType,
                            meetingDurationType,
                        })
                    );
                } else {
                    await dispatch(
                        updateSelectedEvent({
                            seller_id: props.seller_id,
                            product_id: props.productId,
                            slot: [formData],
                            day: days[new Date(eventSelectedDay).getDay()],
                            originalSlot: [originalSlot],
                            date: eventSelectedDayStr,
                            meetingType,
                            reschdule_id: meetingEvent?.meetingId,
                            originalMeetingType,
                            meetingDurationType,
                            originalMeetingDurationType,
                        })
                    );
                }
                setMeetingDurationType(WEEKLY);
                setTimeout(() => {
                    fetchMeetingList();
                }, 2 * 1000);
                handleModalShowHide();
            
        }
    };


    const handleSetMeetingType = (e, type, eventData) => {
        setMeetingType(type);
        setShowHide(true);
     
    };

    const handleDeleteRecurringMeeting = async () => {
        setShowHide(false);
        setCancelMeeting(false);
        setEditMeeting(false);
        const eventSelectedDayStr =
            eventSelectedDay.getFullYear() +
            "-" +
            (eventSelectedDay.getMonth() + 1) +
            "-" +
            eventSelectedDay.getDate();
        await dispatch(
            deleteSelectedEvent({
                seller_id: props.seller_id,
                product_id: props.productId,
                slot: [formData],
                day: days[new Date(eventSelectedDay).getDay()],
                date: eventSelectedDayStr,
                originalSlot: [originalSlot],
                meetingType,
                reschdule_id: meetingEvent?.meetingId,
                originalMeetingType,
            })
        );
     
        fetchMeetingList();
    };

    const handleSetMeetingDurationType = (e) => {
        const {value} = e.target;
        setMeetingDurationType(value);
    };

    const handleSelectChange = (e) => {
        const {value} = e.target;
        setMeetingDurationType(WEEKLY);
        setMeetingType(+value);
    };

    const modal = () => (
        <Modal
            show={showHide}
            onHide={handleModalShowHide}
            className="calendar-modal"
            centered
        >
            <Modal.Header
                closeButton
                onClick={() => handleModalShowHide()}
            ></Modal.Header>
            <Modal.Body>
                {editMeeting ? (
                    <>
                        <div className="form-group">
                            <label>Edit Meeting</label>
                            <select
                                value={meetingType}
                                onChange={handleSelectChange}
                                className="form-control"
                            >
                                <option value={0}>Recurring</option>
                                <option value={1}>one time</option>
                            </select>
                        </div>
                        <div className="details-slot">
                            <button className="orange-btn" onClick={() => setCancelMeeting(false)}>Reschdule</button>
                            <button
                                onClick={() => setCancelMeeting(true)}
                                className="orange-outline-btn"
                            >
                                Cancel
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="details-slot">
                            <p>Slot details</p>
                            <button
                                className={meetingType === 0 ? "orange-btn" : "orange-outline-btn"}
                                onClick={(e) => handleSetMeetingEvent(e, 0)}
                            >
                                Recurring
                            </button>
                            <button
                                className={meetingType === 1 ? "orange-btn" : "orange-outline-btn"}
                                onClick={(e) => handleSetMeetingEvent(e, 1)}
                            >
                                {" "}
                                One Time
                            </button>
                            <h5>
                                {moment(new Date(eventSelectedDay)).format("MMMM Do YYYY")}
                            </h5>
                        </div>
                    </>
                )}
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>
                                <b>PRODUCT NAME</b>
                            </label>
                            <p>{props.title}</p>
                        </div>
                    </div>

                    {!meetingType && (
                        <div className="col-md-12">
                            <div className="product-detail-radio">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input
                                            value={WEEKLY}
                                            checked={meetingDurationType === WEEKLY}
                                            type="radio"
                                            onChange={(e) => handleSetMeetingDurationType(e)}
                                            className="form-check-input"
                                        />
                                        Weekly
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input
                                            value={BI_WEEKLY}
                                            checked={meetingDurationType === BI_WEEKLY}
                                            onChange={(e) => handleSetMeetingDurationType(e)}
                                            type="radio"
                                            className="form-check-input"
                                        />
                                        Bi Weekly
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input
                                            value={MONTHLY}
                                            checked={meetingDurationType === MONTHLY}
                                            onChange={(e) => handleSetMeetingDurationType(e)}
                                            type="radio"
                                            className="form-check-input"
                                        />
                                        Monthly
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                    {!cancelMeeting ? (
                        <>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        <b>START TIME</b>
                                    </label>
                                    <input
                                        type="time"
                                        name="start"
                                        min="00:00"
                                        max="23:59"
                                        value={formData["start"]}
                                        required
                                        onChange={(e) => handleChange(e)}
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        <b>END TIME</b>
                                    </label>
                                    <input
                                        type="time"
                                        name="end"
                                        min="00:00"
                                        max="23:59"
                                        value={formData["end"]}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>
                                    <b>Add note</b>
                                </label>
                                <textarea rows="4" className="form-control"/>
                            </div>
                            <div className="button-center">
                                <button
                                    onClick={handleDeleteRecurringMeeting}
                                    className="orange-btn"
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={() => handleModalShowHide()}
                                    className="orange-outline-btn"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="button-center">
                    {!cancelMeeting && (
                        <button onClick={() => createNewEvent()} className="orange-btn">
                            Save Changes
                        </button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );

    const meetingModal = () => (
        <Modal show={meetingExists} onHide={handleCloseMeetingExistsModal}>
            <Modal.Header
                closeButton
                onClick={() => handleCloseMeetingExistsModal()}
            ></Modal.Header>
            <Modal.Body>
                <Button variant="secondary" onClick={(e) => handleSetMeetingType(e, 0)}>
                    For this slot meeting already Exists
                </Button>
            </Modal.Body>
        </Modal>
    );

    const handleCloseMeetingExistsModal = () => {
        dispatch({type: CLOSE_MEETING_MODAL, payload: false});
    };

    const handleToolTip = (event) => {
        setModalData({open : true, data : {
                start : new Date(event.start).toLocaleDateString(undefined,{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", hour12 : true }),
                end : new Date(event.end).toLocaleDateString(undefined,{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", hour12 : true }),
            }})
    };

    const meetingDetailModal = () => (
        <Modal
            show={modalData.open}
            centered
        >
            <Modal.Header
                closeButton
                onClick={() => {
                    setModalData({open : false, data : {}})
                }}
            ><b>Meeting Details</b></Modal.Header>
            <Modal.Body>
                <div>
                    <p> <b>Start Time :</b> {modalData.data.start}</p>
                    <p><b>End Time :</b> {modalData.data.end}</p>
                </div>

            </Modal.Body>

        </Modal>
    )

    return (
        <div className="calendar-colorbox">
            <ul className="represent-status">
                <li>
                    <div className="color-bar green-color"></div>
                    <p>Weekly</p>
                </li>
                <li>
                    <div className="color-bar purple-color"></div>
                    <p>Biweekly</p>
                </li>
                <li>
                    <div className="color-bar yellow-color"></div>
                    <p>Monthly</p>
                </li>
            </ul>
            <div id="ZoomMeetingCalendarModal" style={{height: 710}}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    showMultiDayTimes={true}
                    style={{height: 700}}
                    selectable
                    startAccessor="start"
                    endAccessor="end"
                    step={30}
                    view={view}
                    onView={(view) => setView(view)}
                    views={["week", "month"]}
                    onSelectEvent={(event) => handleToolTip(event)}
                    eventPropGetter={eventStyleGetter}
                    date={selectedDay}
                    onNavigate={(day) => {
                        setSelectedDay(day);
                    }}
                    dayLayoutAlgorithm="no-overlap"
                />
                {modal()}
                {meetingModal()}
                {meetingDetailModal()}
            </div>
        </div>
    );
};

export default MeetingCalendar;
