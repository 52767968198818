import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getOrderTracking } from "../../Redux/Actions/orderAction";

import "../../Styles/track-orders.scss";
import withNavigate from "../../Components/customHooks/navigate";
class Order_Track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      orderTrackType: [],
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchOrdertrackInfo(true);
  }

  fetchOrdertrackInfo = async (firstFetch) => {
    this.setState({
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      showLoader: firstFetch ? false : true,
    });
    const result = await this.props.getOrderTracking(
      this.props.param.trackId
    );

    if (!result?.data?.isError && result?.data?.data) {
      if (
        result.data.data?.trackRes?.CompletedTrackDetails?.[0]
          ?.TrackDetails?.[0]?.Events?.length > 0
      ) {
        this.createFedexTrackingDetail(
          result.data.data.trackRes.CompletedTrackDetails[0].TrackDetails[0]
            .Events
        );
      } else if (result.data.data.trackRes?.elements?.length > 0) {
        this.createUspsTrackingDetail(result.data.data.trackRes);
      } else if (
        result.data.data.package &&
        result.data.data?.package[0]?.activity
      ) {
        this.createUpsTrackingDetail(result.data.data?.package[0]?.activity);
      } else {
        this.setState({
          orderDetail: result.data.data,
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  createFedexTrackingDetail = (trackDetail) => {
    let trackStatus = [];
    const trackingDetails = trackDetail.map((trackItem, index) => {
      trackStatus.push(trackItem.EventDescription);
      const timestamp = trackItem.Timestamp
        ? new Date(trackItem.Timestamp).toISOString().substring(0, 10)
        : `-`;
      const address = trackItem.Address;
      return (
        <tr key={index}>
          <td>
            <div class="track-time">
              <p>
                <span></span> {timestamp}, {address.City ? address.City : null}{" "}
                {address.StateOrProvinceCode
                  ? address.StateOrProvinceCode
                  : null}{" "}
                {address.CountryCode ? address.CountryCode : "-"}
              </p>
            </div>
          </td>
          <td>
            <div class="track-status">
              <p>
                {trackItem.EventDescription ? trackItem.EventDescription : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });

    this.setState({
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Fedex",
    });

    return;
  };

  createUspsTrackingDetail = (trackDetail) => {
    let trackStatus = [];
    const trackingDetails = trackDetail.elements.map((trackItem, index) => {
      trackStatus.push(trackItem.elements[0].text);
      return (
        <tr key={index}>
          <td>
            <div class="track-time">
              <p>
                <span></span>
              </p>
            </div>
          </td>
          <td>
            <div class="track-status">
              <p>
                {trackItem.elements[0].text ? trackItem.elements[0].text : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });
    this.setState({
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Usps",
    });
  };

  createUpsTrackingDetail = (trackDetail) => {
    let trackStatus = [];
    let trackType = [];
    const trackingDetails = trackDetail.map((trackItem, index) => {
      trackStatus.push(trackItem.status.description);
      trackType.push(trackItem.status.type);
      const timestamp = trackItem.date;
      const address = trackItem.location.address;
      return (
        <tr key={index}>
          <td>
            <div class="track-time">
              <p>
                <span></span> {timestamp}, {address.city ? address.city : null}{" "}
                {address.stateProvince ? address.stateProvince : null}{" "}
                {address.country ? address.country : null}
              </p>
            </div>
          </td>
          <td>
            <div class="track-status">
              <p>
                {trackItem.status?.description
                  ? trackItem.status?.description
                  : null}
              </p>
            </div>
          </td>
        </tr>
      );
    });

    this.setState({
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      orderTrackType: trackType,
      showLoader: false,
      service: "Ups",
    });

    return;
  };

  render() {
    let Uspsstage4 =
      this.state.service === "Usps"
        ? this.state.orderTrackStatusRecord.find((a) => a.includes("delivered"))
        : null;
    let Uspsstage3 =
      this.state.service === "Usps"
        ? this.state.orderTrackStatusRecord.find(
            (a) => a.includes("In Transit") || a.includes("Out for Delivery")
          )
        : null;
    let Uspsstage2 =
      this.state.service === "Usps"
        ? this.state.orderTrackStatusRecord.find((a) => a.includes("Accepted"))
        : null;

    let Upsstage4 =
      this.state.service === "Ups"
        ? this.state.orderTrackType.find((a) => a.includes("D"))
        : null;
    let Upsstage3 =
      this.state.service === "Ups"
        ? this.state.orderTrackType.find((a) => a.includes("I"))
        : null;
    let Upsstage2 =
      this.state.service === "Ups"
        ? this.state.orderTrackType.find((a) => a.includes("P"))
        : null;
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>order history</h2>
        </div>
        <div class="admin-card-box">
          <div class="track-order-box">
            <ul>
              <li class="active">
                <div class="track-order-icon">
                  <i class="fas fa-shopping-basket"></i>
                </div>
                <p>order received</p>
              </li>
              <li
                className={
                  this.state.orderTrackStatusRecord.includes("Picked up") ||
                  Uspsstage4 ||
                  Uspsstage3 ||
                  Uspsstage2 ||
                  Upsstage2 ||
                  Upsstage3 ||
                  Upsstage4
                    ? "active"
                    : null
                }
              >
                <div class="track-order-icon">
                  <i class="fas fa-box"></i>
                </div>
                <p>pick up</p>
              </li>
              <li
                className={
                  this.state.orderTrackStatusRecord.includes("In transit") ||
                  Uspsstage4 ||
                  Uspsstage3 ||
                  Upsstage3 ||
                  (Upsstage2 && !Upsstage4) ||
                  Upsstage4
                    ? "active"
                    : null
                }
              >
                <div class="track-order-icon">
                  <i class="fas fa-truck"></i>
                </div>
                <p>in transit</p>
              </li>
              <li
                className={
                  this.state.orderTrackStatusRecord.includes("Delivered") ||
                  Uspsstage4 ||
                  Upsstage4
                    ? "active"
                    : null
                }
              >
                <div class="track-order-icon">
                  <i class="fas fa-check-circle"></i>
                </div>
                <p>delivered</p>
              </li>
            </ul>
          </div>
          <div class="vendor-table">
            <div class="vendor-table-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <td colspan="2">tracking details:</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orderTrackingTemplate.length > 0
                      ? this.state.orderTrackingTemplate
                      : "Tracking details not found"}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="vendor-table-bottom">
              <button
                onClick={() => this.fetchOrdertrackInfo(false)}
                type="button"
                class="orange-outline-btn"
              >
                retrack
                {(() => {
                  if (this.state?.showLoader) {
                    return (
                      <span class="spinner-border spinner-border-sm ml-1"></span>
                    );
                  }
                })()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrderTracking }, dispatch);
};

export default connect(null, mapDispatchToProps)(withNavigate(Order_Track));
