import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getReviewsList = (sellerId, offset,productId, limit = 10) => async (
    dispatch
  ) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/reported-reviews/?offset=${offset}&limit=${limit}${productId?`&productId=${productId}`: ''}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  export const searchReviews = (sellerId, offset, searchKeyword, productId) => async (
    dispatch
  ) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/reported-reviews/by/search-reviews?offset=${offset}&search=${searchKeyword}${productId?`&productId=${productId}`: ''}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };


  export const getReviewDetailByOrderId = (orderId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/reported-reviews/get-reported-review/${orderId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const deleteComment = (orderId) => async (dispatch) => {
  try {
      const res = await axios({
          method: "get",
          url: `${URL}/api/v1/admin/reported-reviews/delete/${orderId}`,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
          }
      });
      return res;
  }
  catch (error) {
      dispatch(handleError(error));
  }
}
export const disableComment = (orderId,status) => async (dispatch) => {
  try {
      const res = await axios({
          method: "get",
          url: `${URL}/api/v1/admin/reported-reviews/${orderId}/update-status/${status}`,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
          }
      });
      return res;
  }
  catch (error) {
      dispatch(handleError(error));
  }
}