import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { resetPassword } from "../../Redux/Actions/authAction";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      successMessage: "",
      errorData: {},
      errorMessage: "",
      success: null,
      showLoader: false,
    };
  }

  validateUserInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      newPassword: [],
      confirmNewPassword: [],
    };

    if (this.state.newPassword.trim().length < 6) {
      isFormValid = false;
      this.state.errorData.newPassword = [
        "New Password should be at least 6 characters.",
      ];
    }

    if (this.state.newPassword.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.newPassword = [
        "New Password field cannot be empty.",
      ];
    }

    if (
      this.state.newPassword.trim() !== this.state.confirmNewPassword.trim()
    ) {
      isFormValid = false;
      this.state.errorData.confirmNewPassword = [
        "Password confirmation does not match password.",
      ];
    }

    if (this.state.confirmNewPassword.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.confirmNewPassword = [
        "Confirm New Password field cannot be empty.",
      ];
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  async handleSubmit(event) {
    event.preventDefault();

    await this.setState({
      successMessage: "",
      errorMessage: "",
      errorData: {},
      showLoader: true,
    });

    if (this.validateUserInfo()) {
      const resetkey = this.props.location.search.split("=");
      const res = await this.props.resetPassword(
        this.state.newPassword,
        this.state.confirmNewPassword,
        resetkey[1]
      );
      if (res !== undefined) {
        if (res.data.success) {
          this.setState({
            newPassword: "",
            confirmNewPassword: "",
            errorMessage: "",
            successMessage: res.data.message,
            success: res.data.success,
            showLoader: false,
          });
        } else {
          this.setState({
            errorMessage: res.data.message,
            successMessage: "",
            success: res.data.success,
            showLoader: false,
          });
        }
      } else {
        this.setState({
          errorData: this.props.errorData,
          errorMessage: "Something went wrong! please check input fields",
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  }

  handleChange = (event) => {
    if (
      this.state.errorData &&
      this.state.errorData[event.target.name] &&
      this.state.errorData[event.target.name][0]
    ) {
      this.state.errorData[event.target.name][0] = null;
    }

    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    return (
      <div>
        <main className="mainContainer">
          <div className="container">
            <div className="signup">
              <form>
                <div className="signup-head">
                  <h2>RESET YOUR PASSWORD</h2>
                  <p>
                    ENTER NEW PASSWORD AND CONFIRM PASSWORD TO RESET YOUR
                    PASSWORD.
                  </p>
                </div>
                {(() => {
                  if (this.state.successMessage !== "" && this.state.success) {
                    return (
                      <div className="alert alert-success">
                        {this.state.successMessage}
                      </div>
                    );
                  } else if (this.state.errorMessage !== "") {
                    return (
                      <div className="alert alert-danger">
                        {this.state.errorMessage}
                      </div>
                    );
                  }
                })()}
                <div className="signup-mid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="password"
                          name="newPassword"
                          className="form-control"
                          placeholder="NEW PASSWORD"
                          value={this.state.newPassword}
                          onChange={this.handleChange}
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.newPassword &&
                            this.state.errorData.newPassword[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="password"
                          name="confirmNewPassword"
                          className="form-control"
                          placeholder="CONFIRM PASSWORD"
                          value={this.state.confirmNewPassword}
                          onChange={this.handleChange}
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.confirmNewPassword &&
                            this.state.errorData.confirmNewPassword[0]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="signup-bottom">
                  <div className="signup-submit">
                    <button
                      onClick={(event) => this.handleSubmit(event)}
                      type="button"
                    >
                      Reset Password
                      {(() => {
                        if (this.state.showLoader) {
                          return (
                            <span class="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ resetPassword }, dispatch);
};

export default connect(null, mapDispatchToProps)(ResetPassword);
