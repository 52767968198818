import React, { useState, useEffect } from "react";

import { debounce } from "lodash";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";

import InfiniteScroll from "react-infinite-scroll-component";

import {
  addProductRating,
  deleteRatings,
  getProductListByVendor,
  getProductRatings,
  getVendorsListForRating,
  productListForRating,
  searchProductForRating,
} from "../../Redux/Actions/productAction";

const ProductRating = () => {
  const [loader, setLoader] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [error, setError] = useState({
    nameError: "",
    commentError: "",
  });
  const [showModel, setShowModel] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [ratingList, setRatingList] = useState([]);
  const [showRatingModel, setShowRatingModel] = useState(false);
  const [ratingDetails, setRatingDetails] = useState({
    id: null,
    name: "",
    rating: 1,
    comment: "",
  });
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    prevOffset: 0,
    hasMore: true,
    items: [],
    maxLimit: 10,
    vendorId: null,
  });
  useEffect(() => {
    getVendorList();
  }, []);

  useEffect(() => {
    if (searchKeyword && searchKeyword.length > 0) {
      getSearchData();
      return () => {
        getSearchData.cancel();
      };
    } else {
      onSearch();
    }
  }, [searchKeyword, paginationState.maxLimit, paginationState.vendorId]);

  const fetchData = async (firsLoad) => {
    setLoader(true);
    let result;
    if (paginationState.offset !== paginationState.prevOffset || firsLoad) {
      if (
        paginationState.hasMore &&
        paginationState.maxLimit &&
        (paginationState.maxLimit === "all" ||
          paginationState.maxLimit > paginationState.offset)
      ) {
        if (searchKeyword && searchKeyword.length > 0) {
          result = await searchProductForRating(
            paginationState.offset,
            searchKeyword.trim()
          );
        } else {
          if (paginationState.vendorId) {
            result = await getProductListByVendor(
              paginationState.offset,
              paginationState.vendorId
            );
          } else {
            result = await productListForRating(paginationState.offset);
          }
        }
        if (result?.data?.data?.length > 0) {
          setLoader(false);
          setPaginationState((prevState) => ({
            ...prevState,
            prevOffset: prevState.offset,
            offset: prevState.offset + 10,
            items: searchKeyword
              ? result.data.data
              : paginationState.maxLimit === 10 && searchKeyword === ""
              ? result.data.data
              : prevState.items?.concat(result.data.data),
          }));
        } else {
          setPaginationState({ ...paginationState, hasMore: false });
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const getVendorList = async () => {
    const result = await getVendorsListForRating();
    setVendorList(result.data.data);
  };

  const handleChange = async (event, field) => {
    event.preventDefault();
    const value = event.target.value;
    if (field === "maxLimit") {
      setPaginationState({
        ...paginationState,
        maxLimit:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      fetchData(false);
    } else {
      setPaginationState({
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
        items: [],
        vendorId: value,
      });
      setSearchKeyword("");
    }
  };

  const getSearchData = debounce(() => {
    fetchData(true);
  }, 1000);

  const onSearch = debounce(async () => {
    await fetchData(true);
  }, 1000);

  const openRatingModel = (id) => {
    setShowModel(true);
    setRatingDetails({ ...ratingDetails, id: id });
  };

  const denyModal = () => {
    setShowModel(false);
    setShowRatingModel(false);
    setError({ nameError: "", commentError: "" });
    setRatingDetails({ name: "", rating: 1, comment: "" });
  };

  const addRating = async (data) => {
    if (!data.name || !data.comment) {
      if (!data.name) {
        setError({ nameError: "Please Enter Name" });
      } else {
        setError({ commentError: "Please Enter Comment" });
      }
      return null;
    }

    setLoader(true);
    const res = await addProductRating(data);
    if (!res.data.isError) {
      setPaginationState({ ...paginationState, vendorId: "" });
      setRatingDetails({ name: "", rating: 1, comment: "" });
      setSearchKeyword("");
      setLoader(false);
      setShowModel(false);
      confirmAlert({
        title: "",
        message: "Rating Added!",
        buttons: [
          {
            label: "Ok",
            onClick: async () => {},
          },
        ],
      });
      setLoader(true);
      const result = await productListForRating(0);
      if (result?.data?.data?.length > 0) {
        setLoader(false);
        setPaginationState((prevState) => ({
          ...prevState,
          prevOffset: prevState.offset,
          offset: prevState.offset,
          items: result.data.data,
        }));
      }
    }
  };

  const viewRatings = async (id) => {
    const res = await getProductRatings(id);
    if (res?.data?.data.length > 0) {
      setRatingList(res.data.data);
      setShowRatingModel(true);
    } else {
      confirmAlert({
        title: "",
        message: "No Ratings Found!",
        buttons: [
          {
            label: "Ok",
            onClick: async () => {},
          },
        ],
      });
    }
  };

  const deleteRating = async (id) => {
    const res = await deleteRatings(id);
    if (!res.data.isError) {
      setLoader(true);
      setSearchKeyword("");
      setShowRatingModel(false);
      confirmAlert({
        title: "",
        message: "Rating Deleted!",
        buttons: [
          {
            label: "Ok",
            onClick: async () => {},
          },
        ],
      });
    }
    setPaginationState({ ...paginationState, vendorId: "" });
    const result = await productListForRating(0);
    if (result?.data?.data?.length > 0) {
      setLoader(false);
      setPaginationState((prevState) => ({
        ...prevState,
        prevOffset: prevState.offset,
        offset: prevState.offset,
        items: result.data.data,
      }));
    }
  };

  const showRatingModal = () => (
    <Modal
      show={showRatingModel}
      onHide={denyModal}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={denyModal}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Ratings</h5>
      </Modal.Header>
      <Modal.Body className="ratings-all-show">
        {ratingList &&
          ratingList.map((item) => (
            <div className="box-all-ratings">
              <div className="form-group">
                <label>
                  <b>Name - </b>
                  {item.user_name}
                </label>
              </div>

              <div className="form-group">
                <label>
                  <b>Rating - </b>
                  {item.rating}
                </label>
              </div>

              <div className="form-group mb-0">
                <label>
                  <b>Comment - </b>
                  {item.comment}
                </label>
              </div>

              <button onClick={() => deleteRating(item.id)}>
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          ))}
      </Modal.Body>
    </Modal>
  );

  const modal = () => (
    <Modal
      show={showModel}
      onHide={denyModal}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={denyModal}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Add Rating</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p className="text-center">
                Are you sure to add rating for this product ?
              </p>
              <label>
                <b>Name</b>
              </label>
              <input
                className="form-control"
                placeholder="Name"
                onChange={(e) =>
                  setRatingDetails({ ...ratingDetails, name: e.target.value })
                }
              />
              <span className="form-field-error">
                {ratingDetails.name ? null : error.nameError}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <b>Rating</b>
              </label>

              <select
                className="form-control"
                placeholder="Enter text message for vendor"
                onChange={(e) =>
                  setRatingDetails({ ...ratingDetails, rating: e.target.value })
                }
                defaultValue={"1"}
              >
                <option value="1">1</option>
                <option value="1.5">1.5</option>
                <option value="2">2</option>
                <option value="2.5">2.5</option>
                <option value="3">3</option>
                <option value="3.5">3.5</option>
                <option value="4">4</option>
                <option value="4.5">4.5</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <b>Comment</b>
              </label>
              <textarea
                className="form-control height-textarea"
                placeholder="Enter Comment"
                onChange={(e) =>
                  setRatingDetails({
                    ...ratingDetails,
                    comment: e.target.value,
                  })
                }
              />
              <span className="form-field-error">
                {ratingDetails.comment ? "" : error.commentError}
              </span>
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => addRating(ratingDetails)}
            className="orange-btn"
          >
            Add
            {(() => {
              if (loader) {
                return (
                  <span class="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
          <button onClick={denyModal} className="orange-btn">
            Deny
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const itemsList =
    paginationState &&
    paginationState.items?.map((i) => {
      return (
        <tr key={i.id}>
          <td align="center">{i.id ? i.id : "-"}</td>
          <td>{i.name ? i.name : "-"}</td>
          <td>{i.firstname ? `${i.firstname} ${i.lastname}` : "-"}</td>
          <td>{i.price ? `$${i.price}` : "-"}</td>
          <td>{i.isActive === 1 ? "Yes" : "No"}</td>
          <td>
            <p class="orange">{i.status && i.status !== "" ? i.status : "-"}</p>
          </td>
          <td>
            <div class="vendor-table-btn">
              <button
                onClick={() => openRatingModel(i.id)}
                type="button"
                className="orange-outline-btn"
              >
                Add Rating
              </button>
              <button
                onClick={() => viewRatings(i.id)}
                type="button"
                class="orange-outline-btn"
              >
                View Rating
              </button>
            </div>
          </td>
        </tr>
      );
    });
  return (
    <div class="admin-card">
      <div class="admin-card-head">
        <h2> Product Rating </h2>
      </div>
      <div class="admin-card-box">
        <div class="vendor-table">
          <div class="vendor-table-head mb-4">
            <div class="row">
              <div class="col-lg-5 col-md-5">
                <div class="entries-drop mb-0">
                  <label>show</label>
                  <select
                    class="entries-controls"
                    onChange={(e) => handleChange(e, "maxLimit")}
                    value={paginationState.maxLimit}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="all">All</option>
                  </select>
                  <p>Entries</p>
                </div>
              </div>
              <div class="col-lg-7 col-md-7">
                <div
                  className="d-flex justify-content-end"
                  style={{ gap: "10px" }}
                >
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search mb-0">
                      <input
                        class="form-control"
                        onChange={async (e) => {
                          setPaginationState({
                            offset: 0,
                            prevOffset: 0,
                            hasMore: true,
                            maxLimit: 10,
                            items: [],
                            vendorId: "",
                          });
                          await setSearchKeyword(e?.target?.value);
                        }}
                        value={searchKeyword}
                        type="text"
                        placeholder="search by product"
                      />
                    </div>
                  </div>

                  <div class="entries-drop justify-content-end mb-0 vendor-select-custom mr-0">
                    <label>Sort By Vendor</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => handleChange(e, "sort-by-vendor")}
                      value={paginationState.vendorId}
                    >
                      <option>Select Vendor</option>
                      {vendorList &&
                        vendorList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.storename}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="vendor-table-body">
            <div class="table-responsive">
              <InfiniteScroll
                dataLength={paginationState?.items?.length}
                next={() => fetchData(false)}
                hasMore={paginationState.hasMore}
              >
                <table class="table">
                  <thead>
                    <tr>
                      <td align="center">id</td>
                      <td>title</td>
                      <td>Vendor Name</td>
                      <td>price</td>
                      <td>active</td>
                      <td>status</td>
                      <td align="center">action</td>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {itemsList.length > 0 ? itemsList : 
                    <tr>
                      <td colSpan={7}>
                        <div className="text-center">
                          <p className="mb-0">No Product Found!</p>
                        </div>
                      </td>
                    </tr>
                    }
                    {loader ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      {modal()}
      {showRatingModal()}
    </div>
  );
};

export default ProductRating;
