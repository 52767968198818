import React, { Component } from "react";

import Select from "react-select";
import profileAvtar from "../../assets/images-2/dummyUser.png";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserInfo } from "../../Redux/Actions/userAction";
import { getUserDetail, updateProfile } from "../../Redux/Actions/userAction";
import {
  getCountriesList,
  getCitiesList,
  getStatesList,
} from "../../Redux/Actions/cartAction";

import withNavigate from "../../Components/customHooks/navigate";

import "../../Styles/user-detail.scss";
class User_Detail extends Component {
  fieldsList = {
    address: "",
    addressId: null,
    city: "",
    country: "",
    createts: "",
    email: "",
    firstname: "",
    id: 0,
    imagename: "",
    isacceptedtnc: 0,
    isactive: 0,
    lastname: "",
    phonenumber: "",
    role: "",
    state: "",
    type: "",
    updatedTS: "",
    updatedts: "",
    userId: 0,
    zipCode: "",
    successMessage: "",
    errorMessage: "",
    selectedImg: null,
    authData: {
      name: "",
      image: "",
    },
    errorData: {},
    showLoader: false,
  };
  timestamp = "?time=" + new Date().getTime();

  constructor(props) {
    super(props);
    this.state = { ...this.fieldsList };
    this.fileSelectHandler = this.fileSelectHandler.bind(this);

    this.fileUpload = React.createRef();
  }

  validUserInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      firstname: [],
      lastname: [],
      phonenumber: [],
      address: [],
      zipCode: [],
      city: [],
      state: [],
      country: [],
    };

    if (this.state.firstname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.firstname = ["Firstname field cannot be empty."];
    } else if (
      this.state.firstname.includes(`"`) ||
      this.state.firstname.includes(`'`) ||
      this.state.firstname.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.firstname = ["This field cannot contain quotes"];
    }

    if (this.state.lastname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.lastname = ["Lastname field cannot be empty."];
    } else if (
      this.state.lastname.includes(`"`) ||
      this.state.lastname.includes(`'`) ||
      this.state.lastname.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.lastname = ["This field cannot contain quotes"];
    }

    if (!this.state.phonenumber || this.state.phonenumber.trim().length < 10) {
      isFormValid = false;
      this.state.errorData.phonenumber = ["Please Enter Correct Phone Number."];
    }

    if (!this.state.address || this.state.address.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.address = ["Address field cannot be empty."];
    } else if (
      this.state.address.includes(`"`) ||
      this.state.address.includes(`'`) ||
      this.state.address.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.address = ["This field cannot contain quotes"];
    }

    if (!this.state.zipCode || this.state.zipCode.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.zipCode = ["Zip code field cannot be empty."];
    } else if (this.state.zipCode.length < 5) {
      isFormValid = false;
      this.state.errorData.zipCode = ["Please Enter correct zipCode Number."];
    }

    if (!this.state.city || this.state.city.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.city = ["City field cannot be empty."];
    } else if (this.state.city.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      this.state.errorData.city = ["Please Enter correct City Name."];
    } else if (this.state.state.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      this.state.errorData.state = ["Please Enter correct State Name."];
    }

    if (!this.state.state || this.state.state.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.state = ["State field cannot be empty."];
    }

    if (!this.state.country || this.state.country.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.country = ["Country field cannot be empty."];
    }
    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  async fileSelectHandler(e) {
    let imgStatus = false;
    if (e.target.files[0] !== undefined)
      if (e.target.files[0].type !== undefined) {
        let imgType = e.target.files[0].type.split("/");
        let MimeTypeArray = ["jpg", "jpeg", "png", "gif"];
        if (imgType[1] !== undefined) {
          if (MimeTypeArray.includes(imgType[1])) {
            if (e.target && e.target.files[0]) {
              let img = e.target.files[0];
              this.setState({
                selectedImg: img,
              });
              this.readFile(img);
            }
          } else {
            imgStatus = true;
          }
        }
      }
    this.setState({ invalidImage: imgStatus });
  }

  async fetchCountriesList() {
    let countries = await this.props.getCountriesList();
    if (countries?.data?.data?.length > 0) {
      let countriesObjectList = countries.data.data.map((country) => ({
        countryId: country.id,
        countryName: country.name,
      }));
      countries = countries.data.data.map((country) => (
        <option
          key={country.id}
          value={JSON.stringify({
            countryId: country.id,
            countryName: country.name,
          })}
        >
          {country.name}
        </option>
      ));
      this.setState({
        countriesList: countries,
        statesList: [],
        citiesList: [],
      });

      return countriesObjectList;
    }
  }

  fetchUser = async (userId) => {
    let result = await this.props.getUserDetail(userId);
    if (result && result.data && !result.isError) {
      this.setUserState(result.data.data);
    }
  };
  async setUserState(userInfo) {
    this.setState(
      {
        ...userInfo,
      },
      async () => {
        const countriesObjectList = await this.fetchCountriesList();
        if (userInfo.country) {
          const countryId =
            countriesObjectList &&
            countriesObjectList.filter(
              (c) => c.countryName === userInfo.country
            )?.[0]?.["countryId"];
          let statesList = await this.props.getStatesList(countryId);
          statesList = statesList.data.data.map((state) => ({
            value: `${JSON.stringify({
              stateId: state.id,
              stateName: state.state_name,
            })}`,
            label: `${state.state_name}`,
          }));
          let stateId = 0;
          let cityId = 0;
          let citiesList = [];
          if (userInfo.state && userInfo.state.length !== 0) {
            stateId = statesList.filter((s) => s.label === userInfo.state)?.[0];
            if (stateId) {
              stateId = JSON.parse(stateId["value"])["stateId"];
              citiesList = await this.props.getCitiesList(stateId);
              citiesList = citiesList.data.data.map((city) => ({
                value: `${JSON.stringify({
                  cityId: city.id,
                  cityName: city.city,
                })}`,
                label: `${city.city}`,
              }));
              let cityId = citiesList.filter(
                (s) => s.label === userInfo.city
              )?.[0];
              if (cityId) {
                cityId = JSON.parse(cityId["value"])["cityId"];
              }
            }
          }

          this.setState({
            country: userInfo.country,
            statesList,
            citiesList,
            state: "",
            city: "",
            defaultSelectedState: "",
            defaultSelectedCity: "",
          });
          setTimeout(() => {
            this.setState({
              state: userInfo.state,
              city: userInfo.city,
              defaultSelectedState: {
                value: `${JSON.stringify({
                  stateId,
                  stateName: userInfo.state,
                })}`,
                label: `${userInfo.state}`,
              },
              defaultSelectedCity: {
                value: `${JSON.stringify({
                  cityId: cityId ? cityId : 0,
                  cityName: userInfo.city,
                })}`,
                label: `${userInfo.city}`,
              },
            });
          }, 100);
        }
      }
    );
  }

  componentDidMount() {
    this.fetchUser(this.props.param.userId);
  }

  readFile(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState({
        authData: {
          ...this.state.authData,
          image: [fileReader.result],
        },
      });
    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  handleCountryChange = async (event) => {
    event.preventDefault();
    const countryValue = JSON.parse(event.target.value);
    let statesList = await this.props.getStatesList(countryValue.countryId);
    if (statesList?.data?.data?.length > 0) {
      statesList = statesList.data.data.map((state) => ({
        value: `${JSON.stringify({
          stateId: state.id,
          stateName: state.state_name,
        })}`,
        label: `${state.state_name}`,
      }));

      if (
        this.state.errorData &&
        this.state.errorData["country"] &&
        this.state.errorData["country"][0]
      ) {
        this.state.errorData["country"][0] = null;
      }

      this.setState((prevState) => ({
        ...prevState,
        country: countryValue.countryName,
        statesList: statesList,
        defaultSelectedState: [],
        citiesList: [],
        defaultSelectedCity: [],
      }));
    }
  };

  handleStateChange = async (event) => {
    const stateValue = JSON.parse(event.value);
    this.setState({
      defaultSelectedState: event,
      state: stateValue.stateName,
    });
    let citiesList = await this.props.getCitiesList(stateValue.stateId);
    if (citiesList?.data?.data?.length > 0) {
      citiesList = citiesList.data.data.map((city) => ({
        value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
        label: `${city.city}`,
      }));

      if (
        this.state.errorData &&
        this.state.errorData["state"] &&
        this.state.errorData["state"][0]
      ) {
        this.state.errorData["state"][0] = null;
      }

      this.setState((prevState) => ({
        ...prevState,
        citiesList: citiesList,
        defaultSelectedCity: [],
      }));
    }
  };

  handleCityChange = (event) => {
    const cityValue = JSON.parse(event.value);
    if (
      this.state.errorData &&
      this.state.errorData["city"] &&
      this.state.errorData["city"][0]
    ) {
      this.state.errorData["city"][0] = null;
    }

    this.setState((prevState) => ({
      ...prevState,
      defaultSelectedCity: event,
      city: cityValue.cityName,
    }));
  };

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }
    let { value } = e.target;
    if (key === "phonenumber" || key === "zipCode") {
      value = value.replace(/\D+/g, "");
    }
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  handleSubmit = async () => {
    let st = this.state;
    this.setState((prevState) => ({
      ...st,
      successMessage: "",
      errorMessage: "",
      showLoader: true,
    }));
    const profile = this.state;
    if (this.validUserInfo()) {
      const result = await this.props.updateProfile({ ...profile });
      if (result.status === 201) {
        this.setState((prevState) => ({
          ...prevState,
          timestamp: Date.now(),
          successMessage: "Profile updated successfully!",
          showLoader: false,
        }));
        setTimeout(() => {
          this.props.navigate("/users");
        }, 2000);
      } else {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: "Something went wrong! Please try after some time.",
          showLoader: false,
        }));
      }
    } else {
      await this.setState({
        showLoader: false,
      });
    }
  };

  triggerClick = () => {
    this.fileUpload.current.click();
  };

  render() {
    return (
      <div class="admin-card">
        <div class="order-heading">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="mb-0">user profile</h2>
            </div>
          </div>
        </div>
        <div class="admin-card-box">
          <section className="cart-section">
            <div className="container">
              {(() => {
                if (
                  this.state.successMessage !== "" &&
                  this.state.successMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-success">
                      {this.state.successMessage}
                    </div>
                  );
                } else if (
                  this.state.errorMessage !== "" &&
                  this.state.errorMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-danger">
                      {this.state.errorMessage}
                    </div>
                  );
                }
              })()}
              <div className="profile-body">
                <div className="row">
                  <div className="col-lg-8 col-md-7 order-md-1 order-2">
                    <div className="profile-form">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              value={this.state?.firstname}
                              onChange={(event) =>
                                this.handleChange(event, "firstname")
                              }
                            />
                            <span className="form-field-error">
                              {this.state.errorData?.firstname &&
                                this.state.errorData.firstname[0]}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              value={this.state?.lastname}
                              onChange={(event) =>
                                this.handleChange(event, "lastname")
                              }
                            />
                            <span className="form-field-error">
                              {this.state.errorData?.lastname &&
                                this.state.errorData.lastname[0]}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder="Email Address"
                              value={this.state?.email}
                              onChange={(event) =>
                                this.handleChange(event, "email")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              maxLength="15"
                              className="form-control"
                              placeholder="Phone Number"
                              value={this.state?.phonenumber}
                              onChange={(event) =>
                                this.handleChange(event, "phonenumber")
                              }
                            />
                            <span className="form-field-error">
                              {this.state.errorData?.phonenumber &&
                                this.state.errorData.phonenumber[0]}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              value={this.state?.address}
                              onChange={(event) =>
                                this.handleChange(event, "address")
                              }
                            />
                            <span className="form-field-error">
                              {this.state.errorData?.address &&
                                this.state.errorData.address[0]}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <select
                              name="country"
                              className="form-control"
                              onChange={(event) =>
                                this.handleCountryChange(event)
                              }
                              value={this.state.country}
                            >
                              <option value="" selected disabled>
                                Select Country
                              </option>
                              {this.state.countriesList}
                            </select>

                            <span className="form-field-error">
                              {this.state.errorData?.country &&
                                this.state.errorData.country[0]}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Select
                              value={[this.state.defaultSelectedState]}
                              name="value"
                              options={this.state.statesList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              defaultValue={this.state.state}
                              onChange={(event) =>
                                this.handleStateChange(event)
                              }
                            />

                            <span className="form-field-error">
                              {this.state.errorData?.state &&
                                this.state.errorData.state[0]}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Select
                              value={this.state.defaultSelectedCity}
                              name="value"
                              options={this.state.citiesList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(event) => this.handleCityChange(event)}
                            />

                            <span className="form-field-error">
                              {this.state.errorData?.city &&
                                this.state.errorData.city[0]}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip Code"
                              value={this.state?.zipCode}
                              onChange={(event) =>
                                this.handleChange(event, "zipCode")
                              }
                            />
                            <span className="form-field-error">
                              {this.state.errorData?.zipCode &&
                                this.state.errorData.zipCode[0]}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Is Active?</label>
                            <div className="custom-radio-input">
                              <p className="container-sub mr-2">
                                Inactive
                                <span>
                                  <input
                                    type="radio"
                                    value="0"
                                    onChange={(event) =>
                                      this.handleChange(event, "isactive")
                                    }
                                    checked={+this.state.isactive === 0}
                                    name="radio"
                                  />
                                </span>
                                <span class="checkmark"></span>
                              </p>

                              <p className="container-sub mr-2">
                                Active
                                <span>
                                  <input
                                    type="radio"
                                    value="1"
                                    onChange={(event) =>
                                      this.handleChange(event, "isactive")
                                    }
                                    checked={+this.state.isactive === 1}
                                    name="radio"
                                  />
                                </span>
                                <span class="checkmark"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="checkout-btn">
                        <button type="button" onClick={this.handleSubmit}>
                          continue
                          {(() => {
                            if (this.state?.showLoader) {
                              return (
                                <span class="spinner-border spinner-border-sm ml-1"></span>
                              );
                            }
                          })()}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-5 order-md-2 order-1">
                    <div className="profile-picture text-center">
                      <div className="profile-picture-head">
                        <h3>profile picture</h3>
                      </div>
                      <div className="user-profile-img">
                        <img
                          alt=""
                          src={
                            this.state.authData.image ||
                            (this.state.imagename &&
                              this.state.imagename !== "undefined")
                              ? this.state.authData.image ||
                                this.state.imagename
                              : profileAvtar
                          }
                        />
                      </div>
                      {(() => {
                        if (
                          this.state?.invalidImage &&
                          this.state.invalidImage
                        ) {
                          return (
                            <span className="form-field-error">
                              Invalid image format
                            </span>
                          );
                        }
                      })()}

                      <div className="upload-btn">
                        <button onClick={this.triggerClick} type="button">
                          upload image
                        </button>
                      </div>
                      <input
                        ref={this.fileUpload}
                        className="droup-file-input"
                        type="file"
                        onChange={this.fileSelectHandler}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateProfile,
      getUserDetail,
      setUserInfo,
      getCountriesList,
      getCitiesList,
      getStatesList,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(withNavigate(User_Detail));
