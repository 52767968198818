import React, { Component } from "react";

import { debounce } from "lodash";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getProductListForApproval,
  searchProductListForApproval,
  denyProductApproval,
} from "../../Redux/Actions/productAction";

class Approve_Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      showHide: false,
      denyProductSlug: "",
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  handleRequestMeeting = () => {
    this.setState({
      showHide: true,
      adminMessage: "",
    });
  };

  handleModalShowHide = () => {
    this.setState({
      showHide: false,
      adminMessage: "",
    });
  };

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchProductListForApproval(
            this.state.offset,
            this.state.searchKeyword.trim()
          );
        } else {
          result = await this.props.getProductListForApproval(
            this.state.offset
          );
        }
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleDenyApproval = async (slug) => {
    this.setState({
      showLoader: true,
    });
    const res = await this.props.denyProductApproval(
      slug,
      this.state.adminMessage
    );
    let result;
    if (res?.data?.success) {
      result = await this.props.getProductListForApproval(0);
      if (result?.data?.data?.length >= 0) {
        this.setState((prevState) => {
          return {
            ...prevState,
            items: result.data.data,
          };
        });
        this.setState({
          showLoader: false,
          showHide: false,
        });
      }
    }
  };

  handleAlertForDenyApproval = (slug) => {
    this.setState({
      showHide: true,
      denyProductSlug: slug,
    });
  };

  modal = () => (
    <Modal
      show={this.state.showHide}
      onHide={this.handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={this.handleModalShowHide}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Deny product approval</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p className="text-center">Are you sure to deny this product ?</p>
              <label>
                <b>Message</b>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter text message for vendor"
                onChange={(e) =>
                  this.setState({ adminMessage: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleDenyApproval(this.state.denyProductSlug)}
            disabled={this.state.showLoader}
            className={
              this.state.showLoader ? `disable-btn orange-btn` : `orange-btn`
            }
          >
            yes
          </button>
          <button
            onClick={() => this.handleModalShowHide()}
            disabled={this.state.showLoader}
            className={
              this.state.showLoader ? `disable-btn orange-btn` : `orange-btn`
            }
          >
            no
          </button>
          {this.state.showLoader ? (
            <div>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );

  handleChange = async (event, field) => {
    event.preventDefault();

    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  render() {
    const itemsList = this.state.items.map((i) => {
      let viewProductPath = `${process.env.REACT_APP_HOME_URL}/product/${i.slug}`;
      viewProductPath =
        i.status && i.status === "pending"
          ? `${viewProductPath}?preview`
          : viewProductPath;

      let editProductPath = `/vendors/product/${i.userId}/edit/${i.slug}`;
      return (
        <tr key={i.id}>
          <td align="center">{i.id ? i.id : "-"}</td>
          <td>{i.name ? i.name : "-"}</td>
          <td>{i.firstname ? `${i.firstname} ${i.lastname}` : "-"}</td>
          <td>{i.price ? `$${i.price}` : "-"}</td>

          <td>{i.isActive === 1 ? "Yes" : "No"}</td>
          <td>
            <p class="orange">{i.status && i.status !== "" ? i.status : "-"}</p>
          </td>
          <td className="button-align-table">
            <div class="vendor-table-btn">
              <Link to={viewProductPath} target="_blank">
                <button type="button" class="orange-outline-btn">
                  View
                </button>
              </Link>

              <Link
                to={{
                  pathname: editProductPath,
                  search:
                    i.status && i.status === "pending" ? "?approve" : null,
                  state: { isCameFromApproveProductPage: true },
                }}
              >
                <button type="button" class="orange-outline-btn">
                  Edit
                </button>
              </Link>
              <button
                type="button"
                class="orange-outline-btn"
                onClick={() => this.handleAlertForDenyApproval(i.slug)}
              >
                deny
              </button>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>
            {this.props?.location?.vendorFirstname
              ? this.props.location.vendorFirstname
              : null}{" "}
            {this.props?.location?.vendorLastname
              ? this.props.location.vendorLastname
              : null}{" "}
            Product list For Approval
          </h2>
        </div>
        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td align="center">id</td>
                        <td>title</td>
                        <td>Vendor Name</td>
                        <td>price</td>
                        <td>active</td>
                        <td>status</td>
                        <td align="center">action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsList.length > 0 ? (
                        itemsList
                      ) : this.state.showLoader ? (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <div class="spinner-border" role="status"></div>
                          </td>
                        </tr>
                      ) : (
                        "No products for approval"
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        {this.modal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductListForApproval,
      searchProductListForApproval,
      denyProductApproval,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Approve_Products);
