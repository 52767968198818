import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getPaymentsList,
  searchPayment,
} from "../../Redux/Actions/paymentAction";
import { debounce } from "lodash";

class Payment_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      modalInfo: "",
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchPayment(
            this.state.offset,
            this.state.searchKeyword.trim()
          );
        } else {
          result = await this.props.getPaymentsList(this.state.offset);
        }

        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    let value = event.target.value;

    if (field === "maxLimit") {
      await this.setState({
        [field]: value === "all" ? value : +value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  openModal = (payment) => {
    this.setState({
      modalInfo: "",
    });
    if (payment && payment.method === "STRIPE") {
      let orderRes = payment.orderResponse
        ? JSON.parse(payment.orderResponse)
        : null;
      if (orderRes && orderRes.source) {
        let last4 = orderRes.source.last4;
        let expMonth = orderRes.source.exp_month;
        let expYear = orderRes.source.exp_year;
        let paymentMode = payment.method;
        let status = payment.status;
        let orderDescription =
          orderRes.description !== null
            ? orderRes.description
            : "Charge Creation";
        this.setState({
          modalInfo: (
            <tr>
              <td>
                <h5>billed to:</h5>
                <p>
                  ************{last4}
                  <br /> Exp. {expMonth}/{expYear}
                </p>
              </td>
              <td>
                <h5>payment mode:</h5>
                <p>
                  <span>{paymentMode}</span>
                </p>
              </td>
              <td>
                <h5>payment for:</h5>
                <p>{orderDescription}</p>
              </td>
              <td>
                <h5>Status:</h5>
                <p>
                  <span class="green">{status}</span>
                </p>
              </td>
            </tr>
          ),
        });
      } else {
        this.setState({
          modalInfo: <tr>Something went wrong!</tr>,
        });
      }
    } else if (payment && payment.method === "PAYPAL") {
      let orderRes = payment.orderResponse
        ? JSON.parse(payment.orderResponse)
        : null;
      if (orderRes) {
        orderRes = payment.amount;
        let paymentMode = payment.method;
        let status = payment.status;
        let orderDescription = "Subscription Creation";
        let payeeEmailId = orderRes.payee ? orderRes.payee.email_address : null;

        this.setState({
          modalInfo: (
            <tr>
              <td>
                <h5>billed to:</h5>
                <p>{payeeEmailId}</p>
              </td>
              <td>
                <h5>payment mode:</h5>
                <p>
                  <span>{paymentMode}</span>
                </p>
              </td>
              <td>
                <h5>payment for:</h5>
                <p>{orderDescription}</p>
              </td>
              <td>
                <h5>Status:</h5>
                <p>
                  <span class="green">{status}</span>
                </p>
              </td>
            </tr>
          ),
        });
      } else {
        this.setState({
          modalInfo: <tr>Payment Processing!</tr>,
        });
      }
    } else if (payment && payment.method === "AUTHORIZE") {
      this.setState({
        modalInfo: <tr>Payment Processing!</tr>,
      });
    }
  };

  render() {
    const payments = this.state.items.map((i) => {
      const date = i.createTS
        ? new Date(i.createTS).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={i.id}>
          <td align="center">{i.id ? i.id : `-`}</td>
          <td align="center">
            <Link class="orange" to={`/orders/${i.orderId}`}>
              #{i.orderId ? i.orderId : `-`}
            </Link>
          </td>
          <td>{i.tnxReference ? i.tnxReference : `-`}</td>
          <td>{date}</td>
          <td>{i.method ? i.method : `-`}</td>
          <td>${i.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
          <td>
            <p class="orange">{i.status ? i.status : `-`}</p>
          </td>
          <td align="center">
            <p
              style={{ cursor: "pointer" }}
              class="orange"
              onClick={() => this.openModal(i)}
              data-toggle="modal"
              data-target="#paymentdetails"
            >
              View Detail
            </p>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div class="admin-card">
          <div class="admin-card-head">
            <h2>payments list</h2>
          </div>
          <div class="admin-card-box">
            <div class="vendor-table">
              <div class="vendor-table-head">
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <div class="entries-drop">
                      <label>show</label>
                      <select
                        class="entries-controls"
                        onChange={(e) => this.handleChange(e, "maxLimit")}
                        value={this.state.maxLimit}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="all">All</option>
                      </select>
                      <p>Entries</p>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8">
                    <div class="vendor-table-head-right">
                      <div class="vendor-table-search mr-2">
                        <input
                          class="form-control"
                          onChange={(e) =>
                            this.handleChange(e, "searchKeyword")
                          }
                          value={this.state.searchKeyword}
                          type="text"
                          placeholder="search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="vendor-table-body">
                <div class="table-responsive">
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    next={() => this.fetchMoreData(false)}
                    hasMore={this.state.hasMore}
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <td align="center">PaymentId</td>
                          <td align="center">OrderId</td>
                          <td>tnxReference</td>
                          <td>created at</td>
                          <td>Method</td>
                          <td>price</td>
                          <td>status</td>
                          <td align="center">Payment Detail</td>
                        </tr>
                      </thead>
                      <tbody>
                        {payments}
                        {this.state.showLoader ? (
                          <tr>
                            <td colSpan={8} className="text-center">
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal paymentdetails-modal" id="paymentdetails">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <div class="paymentdetails-heading">
                  <h4>Payment details</h4>
                </div>
                <button type="button" class="close" data-dismiss="modal">
                  <i class="fas fa-times"></i>
                </button>
              </div>

              <div class="modal-body">
                <div class="paymentdetails-table">
                  <div class="table-responsive">
                    <table class="table">
                      <tbody>{this.state.modalInfo}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getPaymentsList, searchPayment }, dispatch);
};

export default connect(null, mapDispatchToProps)(Payment_List);
