import React, { useEffect, useState } from "react";
import * as dobaAction from "../../Redux/Actions/dobaAction";
import "./doba.scss";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";

const DobaSellerList = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [sellerList, setSellerList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [firstLoader, setFirstLoader] = useState(false);
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    hasMore: true,
  });
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [disableAddButton, setDisableAddButton] = useState(true);
  const [addedSupplierId, setAddedSupIdList] = useState([]);

  useEffect(() => {
    loadSellerList(true);
  }, []);

  const loadSellerList = async (firstLoad) => {
    if (firstLoad) {
      setFirstLoader(true);
    }
    setLoader(true);
    const response = await dispatch(
      dobaAction.getSellerList(paginationState.pageNumber)
    );
    if (response.data) {
      setSellerList(
        firstLoad
          ? response.data
          : (prevState) => [...prevState, ...response.data]
      );
      setAddedSupIdList(response.addedSupplierId);
      setPaginationState((prevState) => ({
        pageNumber: prevState.pageNumber + 1,
        hasMore: response.data.length !== 0,
      }));
      setFirstLoader(false);
      setLoader(false);
    }
  };

  const handleCheckChange = async (supplierId) => {
    let selectedSellerList = [...selectedSeller];
    if (selectedSellerList.includes(supplierId)) {
      selectedSellerList.splice(selectedSellerList.indexOf(supplierId), 1);
    } else {
      selectedSellerList.push(supplierId);
    }
    setDisableAddButton(selectedSellerList.length === 0);
    setSelectedSeller(selectedSellerList);
  };

  const submitSelectedList = async () => {
    setDisableAddButton(true);
    await dispatch(dobaAction.addSupplierListToCron(selectedSeller));
    setAddedSupIdList((prevState) => [...prevState, ...selectedSeller]);
    setSelectedSeller([]);
    setDisableAddButton(false);
  };

  const handleSubmitSeller = async (seller) => {
    Navigate(
      "/doba-product-list",
     { state: { seller }},
    );
  };

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>Seller List</h2>
      </div>
      <div className="admin-card-box">
        <div className="text-right">
          <button
            className={`orange-btn ${disableAddButton && "disable-btn"} mb-2`}
            disabled={disableAddButton}
            onClick={submitSelectedList}
          >
            Add Selected
          </button>
        </div>
        <ul className="product-category-list">
          <InfiniteScroll
            dataLength={sellerList.length}
            next={() => loadSellerList(false)}
            hasMore={paginationState.hasMore}
          >
            {firstLoader ? (
              <div className="text-center pt-2 pb-2">
                <div class="spinner-border" role="status"></div>
              </div>
            ) : (
              sellerList.map((seller, index) => (
                <li key={index} className="cursor-default">
                  <div className="row">
                    <div  className="col-md-1">
                      <label>
                        <input style={{"cursor": "pointer"}}
                          type="checkbox"
                          checked={selectedSeller.includes(seller.supplierId)}
                          onChange={(e) => handleCheckChange(seller.supplierId)}
                          disabled={addedSupplierId.includes(seller.supplierId)}
                        />
                      </label>
                    </div>

                    <div className="col-md-8">
                      <p style={{"color" : "#e25e52"}}>{seller.supplierName}</p>
                      <p className="seller-desc-p">
                        <strong>
                          {seller.addedQuantity} of {seller.productsQuantity}{" "}
                          products added{" "}
                        </strong>
                      </p>

                      <p className="seller-desc-p">
                        <strong>Category : </strong>
                        {seller.businessCategory}
                      </p>
                    </div>

                    <div className="col-md-3 text-right">
                      <a onClick={() => handleSubmitSeller(seller)}>
                        {"  "}View Products
                      </a>
                    </div>
                  </div>
                  <div></div>
                </li>
              ))
            )}
          </InfiniteScroll>
        </ul>

        {loader && !firstLoader && (
          <div className="text-center pt-2 pb-2">
            <div class="spinner-border" role="status"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DobaSellerList;
