import React, { Component } from "react";

import Select from "react-select";
import RichTextEditor from "react-rte";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getblogDetail,
  updateBlogDetail,
  addBlogDetail,
  updateReview,
  getAllBlogCategoryList,
} from "../../Redux/Actions/blogAction";

import withNavigate from "../../Components/customHooks/navigate";

import "../../Styles/order-detail.scss";
class Blog_Detail extends Component {
  generatedContentTemplate = "";

  onChange = (value) => {
    this.setState({ value });
    this.setState((prevState) => {
      return (prevState.blogDetail["content"] = value.toString("html"));
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      blogDetail: {
        title: "",
        categories: [],
        image: "",
        content: "",
        is_active: 1,
      },
      categoryOptions: [],
      isEditForm: false,
      errorData: {},
      value: RichTextEditor.createEmptyValue(),
      editorType: "text",
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchBlogDetail();
  }

  fetchBlogDetail = async () => {
    const options = await this.props.getAllBlogCategoryList();
    if (options?.data?.data) {
      this.setState({
        categoryOptions: options.data.data,
      });
    }
    if (this.props.param.blogId) {
      const result = await this.props.getblogDetail(this.props.param.blogId);
      if (result?.data?.data) {
        this.setState({
          blogDetail: result.data.data,
          value: RichTextEditor.createValueFromString(
            result.data.data.content,
            "html"
          ),
        });
      }
    }
  };

  handleChange = async (event, field) => {
    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    if (field === "categories") {
      this.setState((prevState) => {
        return (prevState.blogDetail[field] = event ? event : []);
      });
    } else {
      event.persist();
      this.setState((prevState) => {
        return (prevState.blogDetail[field] = event.target.value);
      });
    }
    if (field == "content") {
      this.setState({
        value: RichTextEditor.createValueFromString(event.target.value, "html"),
      });
    }
  };

  validateInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      title: [],
      content: [],
      categories: [],
    };

    if (this.state.blogDetail?.title?.trim()?.length === 0) {
      isFormValid = false;
      this.state.errorData.title = ["Title cannot be empty."];
    } else if (
      this.state.blogDetail?.title?.includes(`"`) ||
      this.state.blogDetail?.title?.includes(`'`) ||
      this.state.blogDetail?.title?.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.title = ["This field cannot contain quotes"];
    }

    if (this.state.blogDetail?.content?.trim()?.length === 0) {
      isFormValid = false;
      this.state.errorData.content = ["Content cannot be empty."];
    }

    if (!this.state.blogDetail?.categories?.length) {
      isFormValid = false;
      this.state.errorData.categories = ["Choose atleast one category."];
    }

    if (!this.state.blogDetail?.image?.length) {
      isFormValid = false;
      this.state.errorData.image = ["Please upload an image."];
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  onSaveForm = async () => {
    if (this.validateInfo()) {
      this.setState({
        showLoader: true,
      });

      let res;
      if (this.props.param.blogId)
        res = await this.props.updateBlogDetail({
          ...this.state.blogDetail,
          selectedImg: this.state.selectedImg,
        });
      else
        res = await this.props.addBlogDetail({
          ...this.state.blogDetail,
          selectedImg: this.state.selectedImg,
        });
      this.setState({ showLoader: false });
      if (res && res.data && !res.data.isError) {
        this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
        this.setState({
          errorData: {},
          successMessage: this.props.param.blogId
            ? "Yeah! Blog updation successful."
            : "Yeah! Blog created successful.",
        });
        setTimeout(() => this.props.navigate("/blogs"), 2000);
      } else {
        this.setState({
          ...this.statePrevCopy,
          errorData: {},
          errorMessage: "Oops! something went wrong.",
        });
      }
    }
  };

  onCancel = () => {
    this.fetchBlogDetail();
  };

  handleImageType = (img) => {
    let imgType = img?.type?.split("/");
    let MimeTypeArray = ["jpeg", "png", "jpg"];
    if (MimeTypeArray.includes(imgType[1])) {
      this.readFile(img);
    } else {
      this.setState({
        errorData: {
          ...this.state.errorData,
          image: ["Image Format is not valid."],
        },
      });
    }
  };

  fileSelectHandler = (e) => {
    let temp = this.state;
    if (this.state.errorData?.image && this.state.errorData?.image[0])
      this.state.errorData.image = [""];
    this.setState(temp);
    let img = e.target.files[0];
    this.handleImageType(img);
  };

  handleDrop = (files) => {
    let temp = this.state;
    if (this.state.errorData?.image && this.state.errorData?.image[0])
      this.state.errorData.image = [""];
    this.setState(temp);
    if (files.length > 1) {
      this.setState({
        errorData: {
          ...this.state.errorData,
          image: ["You can only add 1 file."],
        },
      });
      return;
    }
    this.handleImageType(files[0]);
  };

  readFile = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) =>
      this.setState({
        selectedImg: file,
        blogDetail: {
          ...this.state.blogDetail,
          image: event.currentTarget.result,
        },
      });
    fileReader.readAsDataURL(file);
  };

  handleUpdateReview = async (is_active, id) => {
    await this.props.updateReview({ id, is_active });
    this.fetchBlogDetail();
  };

  render() {
    return (
      <div class="admin-card">
        {(() => {
          if (
            this.state.successMessage !== "" &&
            this.state.successMessage !== undefined
          ) {
            return (
              <div className="alert alert-success">
                {this.state.successMessage}
              </div>
            );
          } else if (
            this.state.errorMessage !== "" &&
            this.state.errorMessage !== undefined
          ) {
            return (
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            );
          }
        })()}
        <div class="order-heading">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="mb-0">Blog Details</h2>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="order-edit">
                <button
                  onClick={this.onSaveForm}
                  type="text"
                  class="orange-outline-btn"
                >
                  <i class="fa fa-check"></i> Save
                  {(() => {
                    if (this.state.showLoader) {
                      return (
                        <span className="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>{" "}
                {this.props.param.blogId && (
                  <button
                    onClick={this.onCancel}
                    type="text"
                    class="orange-outline-btn"
                  >
                    <i class="fa fa-times"></i> Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="admin-card-box">
          <section class="track-section">
            <div class="container">
              <form>
                <div class="form-group">
                  <label for="blogTitleInput">Blog Title</label>
                  <input
                    type="text"
                    onChange={(event) => this.handleChange(event, "title")}
                    value={this.state.blogDetail.title}
                    class="form-control"
                    id="blogTitleInput"
                    placeholder="Add blog title"
                  />
                  <span
                    style={{ color: "red", fontSize: "12px" }}
                    className="form-field-error"
                  >
                    {this.state.errorData?.title &&
                      this.state.errorData.title[0]}
                  </span>
                </div>
                <div class="form-group">
                  <label for="blogTitleInput">Blog Categories</label>
                  <Select
                    value={this.state.blogDetail.categories}
                    onChange={(event) => this.handleChange(event, "categories")}
                    options={this.state.categoryOptions}
                    isMulti
                  />
                  <span
                    style={{ color: "red", fontSize: "12px" }}
                    className="form-field-error"
                  >
                    {this.state.errorData?.categories &&
                      this.state.errorData.categories[0]}
                  </span>
                </div>
                <div class="form-group">
                  <label for="blogTitleInput">Blog Feature Image</label>
                  <div className="drag-drop">
                    {this.state.blogDetail.image ? (
                      <div className="drag-drop-img">
                        <img src={this.state.blogDetail.image} alt="" />
                      </div>
                    ) : null}

                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => this.fileSelectHandler(e)}
                        onClick={(event) => (event.target.value = "")}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>

                    <span
                      style={{ color: "red", fontSize: "12px" }}
                      className="form-field-error"
                    >
                      {this.state?.errorData?.image &&
                        this.state?.errorData?.image[0]}
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="blogContentTextarea">Blog Content</label>
                  <div className="editor_switch mb-4">
                    <button
                      className={
                        this.state.editorType === "html"
                          ? "low-opacity-button orange-outline-btn"
                          : "orange-btn mr-3"
                      }
                      onClick={() => {
                        this.setState({ editorType: "text" });
                      }}
                      type="button"
                    >
                      Visual
                    </button>
                    <button
                      className={
                        this.state.editorType === "text"
                          ? "low-opacity-button orange-outline-btn"
                          : "orange-btn ml-3"
                      }
                      onClick={() => {
                        this.setState({ editorType: "html" });
                      }}
                      type="button"
                    >
                      HTML
                    </button>
                  </div>
                  {(() => {
                    if (this.state.editorType === "html")
                      return (
                        <textarea
                          onChange={(event) =>
                            this.handleChange(event, "content")
                          }
                          value={this.state.value.toString("html")}
                        ></textarea>
                      );
                    else
                      return (
                        <RichTextEditor
                          value={this.state.value}
                          onChange={this.onChange}
                        />
                      );
                  })()}
                  <span
                    style={{ color: "red", fontSize: "12px" }}
                    className="form-field-error"
                  >
                    {this.state.errorData?.content &&
                      this.state.errorData.content[0]}
                  </span>
                </div>
                <div className="form-group">
                  <label>Do you want to activate this blog?</label>
                  <div className="custom-radio-input">
                    <p className="container-sub mr-2">
                      No
                      <span>
                        <input
                          type="radio"
                          value="0"
                          onChange={(event) =>
                            this.handleChange(event, "is_active")
                          }
                          checked={+this.state.blogDetail.is_active === 0}
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>

                    <p className="container-sub mr-2">
                      Yes
                      <span>
                        <input
                          type="radio"
                          value="1"
                          onChange={(event) =>
                            this.handleChange(event, "is_active")
                          }
                          checked={+this.state.blogDetail.is_active === 1}
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getblogDetail,
      updateBlogDetail,
      addBlogDetail,
      getAllBlogCategoryList,
      updateReview,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(withNavigate(Blog_Detail));
