export const initialState = {
  loading: false,
  errorData: {},
  errorMessage: {},
  isRegistered: false,
  showSidebar: false,
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_STARTED":
      return {
        ...state,
        loading: true,
      };
    case "LOADING_COMPLETED":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_FAILURE":
      return {
        ...state,
        loading: false,
      };
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        showSidebar: !state.showSidebar,
      };
    case "ERROR":
      return {
        ...state,
        errorData: action.payload?.error?.errorData,
        errorMessage: action.payload?.errorMessage,
      };
    default:
      return state;
  }
};
