import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getVendorsList = (offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/list?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getVendorById = (vendorId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/by/id/${vendorId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateVendorProfile = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/vendors/update/by/id/${data.id}`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const searchVendor = (offset, searchKeyword) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/list/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getVendorProductList = (vendorId, start) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/product/list/${vendorId}?offset=${start}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getVendorProductDetail = (vendorId, slug) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/product/detail/${vendorId}/${slug}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateVendorProductDetail =
  (productInfo, vendorId, productId) => async (dispatch) => {
    try {
      let formData = new FormData();
      for (var key in productInfo) {
        formData.append(key, productInfo[key]);
      }

      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/vendors/product/update/${vendorId}?id=${productId}`,
        data: formData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const searchVendorProduct =
  (offset, vendorId, searchKeyword) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/vendors/product/list/${vendorId}/by/search?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getAttributeList = (userId, start) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/attribute/list/${userId}?offset=${start}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateProductFiles = (data, vendorId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/vendors/product/update-product-files/${vendorId}`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const removeProductFiles = (data) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/vendors/product/remove-product-files`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getActiveSubscriptionCustomerListByProudctId =
  (product_id, offset) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/product/active_subscription_list/${product_id}?offset=${offset}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      dispatch({
        type: "ACTIVE_SUBCRIPTION_USER_LIST",
        payload: { data: res.data },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const sendInvitationRequest = (data, userId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/schedule/send_invitation`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: {
        data,
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateGroupInviteMeeting = (data, userId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${URL}/api/v1/admin/schedule/update_group_invitation`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data,
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
