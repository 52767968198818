import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as dobaAction from "../../Redux/Actions/dobaAction";
import "./doba.scss";
import DobaBreadcrumb from "./DobaBreadcrumb";
import { useNavigate } from "react-router";

const DobaDashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allCategoryList, addedCatList } = useSelector(
    (state) => state.dobaReducer
  );
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryHierarchy, setCategoryHierarchy] = useState([
    { catName: "Home" },
  ]);
  const [filterState, setFilterState] = useState({
    keyword: "",
    rawCatList: [],
  });
  const [selectedCatList, setSelectedCatList] = useState([]);
  const [disableAddButton, setDisableAddButton] = useState(true);

  useEffect(() => {
    if (props.location?.state && allCategoryList.length !== 0) {
      const propsData = JSON.parse(props.location?.state);
      setCategoryList(propsData.categoryList);
      setCategoryHierarchy(propsData.categoryHierarchy);
    } else {
      loadData();
    }
  }, []);

  const loadData = async () => {
    setLoading(true);
    const response = await dispatch(dobaAction.getProductCategory(0));
    setCategoryList(response.data);
    dispatch({ type: "ADDED_CAT_LIST", payload: response.addedCatList });
    setLoading(false);
  };

  const handleSelectCategory = async (category) => {
    if (category.node) {
      setCategoryList(category.node);
      setCategoryHierarchy((prevState) => [...prevState, category]);
      setFilterState((prevState) => ({ ...prevState, keyword: "" }));
    }
  };

  const handleSubmitCategory = async (category) => {
    if (!category) {
      category = categoryHierarchy[categoryHierarchy.length - 1];
    }
    navigate("/doba-product-list",
     { state: {
        category,
        data: JSON.stringify({ categoryHierarchy, categoryList }),
      }}
    );
  };

  const handleChange = async (event) => {
    event.persist();
    const { value } = event.target;
    setFilterState((prevState) => ({ ...prevState, keyword: value }));
    if (value !== "") {
      await filterSearchResult(value);
    } else {
      loadData();
    }
  };

  const filterSearchResult = async (value) => {
    let filteredCat = [...filterState.rawCatList];
    filteredCat = filteredCat.filter((cat) =>
      cat.catName.toLowerCase().includes(value.toLowerCase())
    );
    setCategoryList(filteredCat);
  };

  useEffect(() => {
    if (filterState.keyword === "") {
      setFilterState((prevState) => ({
        ...prevState,
        rawCatList: [...categoryList],
      }));
    }
  }, [categoryList]);

  const updateBreadcrumb = (data) => {
    if (data.categoryList) {
      setCategoryList(data.categoryList);
    } else if (data.categoryHierarchy) {
      setCategoryHierarchy(data.categoryHierarchy);
    } else if (data.filterState) {
      setFilterState((prevState) => ({ ...prevState, keyword: "" }));
    }
  };

  const handleCheckChange = (catId) => {
    let catList = [...selectedCatList];
    catList.includes(catId)
      ? catList.splice(catList.indexOf(catId), 1)
      : catList.push(catId);
    setDisableAddButton(catList.length === 0);
    setSelectedCatList(catList);
  };

  const submitSelectedList = async () => {
    setDisableAddButton(true);
    await dispatch(dobaAction.addCategoryListToCron(selectedCatList));
    dispatch({
      type: "ADDED_CAT_LIST",
      payload: [...addedCatList, ...selectedCatList],
    });
    setSelectedCatList([]);
    setDisableAddButton(false);
  };

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>Product Category List</h2>
      </div>
      <div className="admin-card-box">
        <div className="row mb-3">
          <div className="col-lg-6 col-md-8 d-flex align-items-center justify-content-center">
            <DobaBreadcrumb
              categoryHierarchy={categoryHierarchy}
              updateBreadcrumb={(e) => updateBreadcrumb(e)}
              isFromCategory={true}
            />
          </div>
          <div className="col-lg-4 col-md-4 d-flex align-items-center justify-content-center">
            <div className="vendor-table-head-right w-100">
              <div className="vendor-table-search mr-2">
                <input
                  className="form-control"
                  onChange={handleChange}
                  value={filterState.keyword}
                  type="text"
                  placeholder="Search Category"
                  disabled={loading}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 d-flex align-items-center justify-content-center">
            <button
              className={`orange-btn ${disableAddButton && "disable-btn"}`}
              disabled={disableAddButton}
              onClick={submitSelectedList}
            >
              Add Selected
            </button>
          </div>
        </div>
        <ul className="product-category-list">
          {categoryList.length > 0
            ? categoryList.map((category, index) => (
                <li key={index}>
                  <div className="row">
                    <div className="col-md-1">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedCatList.includes(category.catId)}
                          onChange={(e) => handleCheckChange(category.catId)}
                          disabled={addedCatList.includes(category.catId)}
                        />
                      </label>
                    </div>
                    <div className="col-md-8">
                      {category.node ? (
                        <a onClick={() => handleSelectCategory(category)}>
                          {category.catName}
                        </a>
                      ) : (
                        <p onClick={() => handleSelectCategory(category)}>
                          {category.catName}
                        </p>
                      )}
                    </div>
                    <div className="col-md-3 text-right">
                      <a onClick={() => handleSubmitCategory(category)}>
                        {"  "}View Products
                      </a>
                    </div>
                  </div>
                </li>
              ))
            : loading
            ? "Loading..."
            : "No result found!"}
        </ul>
      </div>
    </div>
  );
};
export default DobaDashboard;
