export const initialState = {
    cartItems: [],
    orderId: null,
    orderNumber: null,
    products: [],
    isError: null,
    isPaySuceess: null,
    paymentMessage: ""
};

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CARTITEMS":
            return {
                ...state,
                cartItems: [...action.payload]
            }
        case "CHECKOUT":
            return {
                ...state,
                orderId: action.payload.data.orderId,
                orderNumber: action.payload.data.orderNumber,
                products: action.payload.data?.data ? action.payload.data?.data : [],
                isPaySuceess: typeof action.payload.data.isPaySuceess === "boolean" ? action.payload.data.isPaySuceess : null,
                isError: typeof action.payload.data.isError === "boolean" ? action.payload.data.isError : null,
                paymentMessage: action.payload.data?.paymentMessage ? action.payload.data?.paymentMessage: ""
            }
        case "ERROR":
            return{
                isError: true
            }
        default:
            return state
    }
}