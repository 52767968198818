export const initialState = {
    notificationList: [],
    notificationCount: 0,

}

const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}


export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "NOTIFICATION":
            let oldNotifications = state.notificationList.map((notification) => {
                notification["is_read"] = 1;
                return notification;
            })
            let updatedNotifications = [...oldNotifications, ...action.payload]
            updatedNotifications = updatedNotifications.map((i) => JSON.stringify(i))
            updatedNotifications = updatedNotifications.filter(onlyUnique)
            updatedNotifications = updatedNotifications.map((i) => JSON.parse(i))
            return {
                ...state,
                notificationList: updatedNotifications,

            };
            case "NOTIFICATION_COUNT":
            return {
                ...state,
                notificationCount: action.payload,

            };
        default:
            return state;
    }
}