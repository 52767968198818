import React, { Component } from "react";
import "../../Styles/order-list.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getReviewsList,
  searchReviews,
} from "../../Redux/Actions/reportedReviewAction";
import { debounce } from "lodash";
import withNavigate from "../../Components/customHooks/navigate";

class Reported_Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      isChecked: false,
    };
    this.productName = this.props?.location?.state;
  }

  componentDidMount() {
    this.productId = this.props?.param?.productId;
    (async () => {
      if (this.productId) {
        const result = await this.props.getReviewsList(
          localStorage.getItem("sellerId"),
          this.state.offset,
          this.productId
        );

        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: result.data.data,
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.fetchMoreData(true);
      }
    })();
  }
  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps?.location.pathname !== this.props?.location?.pathname) {
      (async () => {
        const result = await this.props.getReviewsList(
          localStorage.getItem("sellerId"),
          0,
          nextProps?.param?.productId
        );
        this.setState((prevState) => {
          return {
            ...prevState,
            items: result.data.data,
            showLoader: false,
          };
        });
      })();
      this.productId = nextProps?.param?.productId;
    }
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchReviews(
            localStorage.getItem("sellerId"),
            this.state.offset,
            this.state.searchKeyword.trim().replace(/[^a-zA-Z 0-9.]+/g, ""),
            this.props?.param?.productId
          );
        } else {
          result = await this.props.getReviewsList(
            localStorage.getItem("sellerId"),
            this.state.offset,
            this.props?.param?.productId
          );
        }
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();

    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  handleCheck = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  render() {
    const orderItems = this.state.items.map((i) => {
      return (
        <tr key={i.id}>
          <td>{i.id ? i.id : "-"}</td>
          {!this.productId ? (
            <td>
              <a href={`${process.env.REACT_APP_HOME_URL}/product/${i.slug}`}>
                {i.product_name ? i.product_name : "-"}
              </a>
            </td>
          ) : null}
          <td>{i.email ? i.email : "-"}</td>
          {!this.productId ? <td>{i.vendor_name}</td> : null}
          <td>{i.rating ? i.rating : "-"}</td>
          <td>{i.status ? "yes" : "no"}</td>
          <td>{i.created_ts ? i.created_ts.slice(0, 10) : "-"}</td>
          {this.productId ? <td>{i.is_reported ? "yes" : "no"}</td> : null}
          <td align="center" style={{ display: "grid" }}>
            <Link to={`/reported-review/${i.order_id}`} className="orange mr-0">
              edit
            </Link>
          </td>
        </tr>
      );
    });
    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>
            {!this.productId
              ? "reported reviews"
              : "review list - " + this.productName}
          </h2>
        </div>
        <div className="admin-card-box">
          <div className="vendor-table">
            <div className="vendor-table-head">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="entries-drop">
                    <label>show</label>
                    <select
                      className="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="vendor-table-head-right">
                    <div className="vendor-table-search mr-2">
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vendor-table-body">
              <div className="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Id</td>
                        {!this.productId ? <td>Product</td> : null}
                        <td>Customer Email</td>
                        {!this.productId ? <td>Vendor name</td> : null}
                        <td>rating</td>
                        <td>is active</td>
                        <td>Created on</td>
                        {this.productId ? <td>is reported</td> : null}
                        <td align="center">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {orderItems?.length ? orderItems : "No result found"}

                      {this.state.showLoader ? (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getReviewsList, searchReviews }, dispatch);
};

export default connect(null, mapDispatchToProps)(withNavigate(Reported_Reviews));
