import React, { Component } from "react";

import { debounce } from "lodash";
import { Link } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getVendorsList, searchVendor } from "../../Redux/Actions/vendorAction";

import "../../Styles/order-list.scss";

class Vendor_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      vendors: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchVendor(
            this.state.offset,
            this.state.searchKeyword.trim()
          );
        } else {
          result = await this.props.getVendorsList(this.state.offset);
        }
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              vendors: prevState.vendors.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      vendors: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  render() {
    const vendorsList = this.state.vendors.map((i) => {
      const date = i.createts
        ? new Date(i.createts).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={i.id}>
          <td key={i.id} align="center">
            {i.id ? i.id : "-"}
          </td>
          <td>{i.firstname ? i.firstname : "-"}</td>
          <td>{i.lastname ? i.lastname : "-"}</td>
          <td>{i.email ? i.email : "-"}</td>
          <td>{i.storename ? i.storename : "-"}</td>
          <td>{date}</td>
          <td>{i.isactive ? "Yes" : "No"}</td>
          <td align="center">
            <div class="vendor-table-btn">
              <Link to={`/vendors/${i.id}`}>
                <button type="button" class="orange-outline-btn">
                  Edit
                </button>
              </Link>

              <Link
                to={{
                  pathname: `/vendors/product/${i.id}`,
                  vendorFirstname: `${i.firstname}`,
                  vendorLastname: `${i.lastname}`,
                }}
              >
                <button type="button" class="orange-outline-btn">
                  Product List
                </button>
              </Link>
              <Link
                to={{
                  pathname: `/vendors/payments/${i.id}`,
                }}
              >
                <button type="button" class="orange-outline-btn">
                  Payment List
                </button>
              </Link>
            </div>
          </td>
        </tr>
      );
    });
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>Vendors List</h2>
        </div>
        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search mr-2">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.vendors.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td align="center">User ID.</td>
                        <td>Firstname</td>
                        <td>Lastname</td>
                        <td>Email</td>
                        <td> Store Name</td>
                        <td>Created</td>
                        <td>Active</td>
                        <td align="center">View Detail</td>
                      </tr>
                    </thead>
                    <tbody>
                      {vendorsList}

                      {this.state.showLoader ? (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getVendorsList, searchVendor }, dispatch);
};

export default connect(null, mapDispatchToProps)(Vendor_List);
