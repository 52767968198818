import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const createCoupon = (couponInfo) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/coupons/add`,
      data: couponInfo,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getCouponList = (offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/coupons/list?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getCouponDetail = (couponId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/coupons/detail/${couponId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateCouponDetail =
  (couponInfo, couponId) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/coupons/update-coupon/${couponId}`,
        data: couponInfo,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const searchCoupon = (offset, searchKeyword) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/coupons/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
