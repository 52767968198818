export const initialState = {
  productList: [],
  productCount: 0,
  allCategoryList: [],
  addedCatList: [],
};

export const dobaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_LIST":
      return {
        ...state,
        productList: action.payload.goodsList,
        productCount: action.payload.totalQuantity,
      };
    case "SET_CATEGORY_LIST":
      return {
        ...state,
        allCategoryList: action.payload.data,
      };

    case "RESET_PRODUCT_LIST":
      return {
        ...state,
        productList: [],
        productCount: 0,
      };
    case "ADDED_CAT_LIST":
      return {
        ...state,
        addedCatList: [...action.payload],
      };

    default:
      return state;
  }
};
