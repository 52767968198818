import React, { Component } from "react";

import "../../Styles/dashboard.scss";
import { Link } from "react-router-dom";

import Chart from "react-apexcharts";

import ratioGraph from "../../assets/images-2/ratio-graph.jpg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleSidebar } from "../../Redux/Actions/commonAction";
import { getUsersList } from "../../Redux/Actions/userAction";
import { getStatisticsData } from "../../Redux/Actions/dashboardAction";
import { debounce } from "lodash";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

class Dashboard extends Component {
  monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      limit: 5,
      users: [],
      filter: "monthly",
      startDate: "",
      endDate: "",
      totalPayments: 0,
      totalReceivableAmount: 0,
      totalOrders: 0,
      series: [],
      options: {},
    };
  }

  componentDidMount() {
    this.debounceStatisticsCall();
    this.fetchMoreData();
  }

  handleFilterChange = (filterName) => {
    this.setState({
      filter: filterName,
    });

    this.debounceStatisticsCall();
  };

  handleDateChange = (event, key) => {
    this.setState({
      [key]: event ? moment(event).format("YYYY-MM-DD") : "",
      filter: event ? "date" : "monthly",
    });

    this.debounceStatisticsCall();
  };

  debounceStatisticsCall = debounce(() => {
    this.fetchStatisticsData();
  }, 1000);

  fetchStatisticsData = async () => {
    let result;

    if (this.state.filter === "date") {
      result = await this.props.getStatisticsData(
        this.state.filter,
        this.state.startDate,
        this.state.endDate
      );
    } else {
      result = await this.props.getStatisticsData(this.state.filter);
    }

    if (result?.data?.isFound && result.data.data) {
      this.createSeriesData(result.data.data);
    }
  };

  createSeriesData = (statsData) => {
    let paymentData = statsData.payments;
    let orderData = statsData.orders;
    let totalPayments = 0;
    let totalReceivableAmount = 0;
    let totalOrders = 0;
    let maxPaymentValue = 0;

    let seriesData =
      paymentData.length > 0
        ? paymentData.map((item) => {
            totalPayments += item.payments;

            totalReceivableAmount += item.subtractable_amount;

            let parsedDate = new Date(item.createTS);

            if (
              this.state.filter === "weekly" ||
              this.state.filter === "monthly" ||
              this.state.filter === "date"
            ) {
              let todate = new Date(parsedDate).getDate();
              let tomonth = this.monthArray[new Date(parsedDate).getMonth()];
              parsedDate = `${todate} ${tomonth}`;
            } else if (this.state.filter === "annual") {
              parsedDate = this.monthArray[new Date(parsedDate).getMonth()];
            } else if (this.state.filter === "all") {
              parsedDate = new Date(parsedDate).getFullYear();
            }

            maxPaymentValue =
              item.payments > maxPaymentValue ? item.payments : maxPaymentValue;

            if (typeof parsedDate == "number") {
              parsedDate = parsedDate.toString();
            }

            return {
              x: parsedDate,
              y: item.payments.toFixed(2),
            };
          })
        : [];

    orderData.forEach((item) => {
      totalOrders += item.orders;
    });

    maxPaymentValue = Math.ceil(maxPaymentValue / 100) * 100;

    this.setState({
      totalPayments,
      totalReceivableAmount: totalReceivableAmount,
      totalOrders,
      series: [
        {
          name: "Total Payments",
          data: seriesData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        fill: {
          colors: ["#F44336"],
        },
        colors: ["#E25E52"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Sales Overview",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "$" + value;
            },
          },
          title: {
            text: "Amount",
          },
          min: 0,
          max: maxPaymentValue,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    });
  };

  fetchMoreData = async () => {
    const result = await this.props.getUsersList(
      this.state.offset,
      this.state.limit
    );
    if (result?.data?.data?.length > 0) {
      this.setState({
        users: result.data.data,
      });
    }
  };

  render() {
    const usersList = this.state.users.map((i) => {
      const date = i.createts
        ? new Date(i.createts).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={i.id}>
          <td key={i.id} align="center">
            {i.id ? i.id : "-"}
          </td>
          <td>{i.firstname ? i.firstname : "-"}</td>
          <td>{i.lastname ? i.lastname : "-"}</td>
          <td>{i.email ? i.email : "-"}</td>
          <td>{date}</td>
          <td>{i.isactive ? "Yes" : "No"}</td>
          <td align="center">
            <Link to={`/users/${i.id}`}>view detail</Link>
          </td>
        </tr>
      );
    });

    return (
          <div class="admin-card">
            <div class="admin-card-head">
              <h2>dashboard</h2>
              <div class="admin-card-head-right">
                <div class="dash-time">
                  <ul>
                    <li>
                      <p
                        onClick={() => this.handleFilterChange("all")}
                        className={
                          this.state.filter === "all" ? "active" : null
                        }
                      >
                        all
                      </p>
                    </li>
                    <li>
                      <p
                        onClick={() => this.handleFilterChange("weekly")}
                        className={
                          this.state.filter === "weekly" ? "active" : null
                        }
                      >
                        weekly
                      </p>
                    </li>
                    <li>
                      <p
                        onClick={() => this.handleFilterChange("monthly")}
                        className={
                          this.state.filter === "monthly" ? "active" : null
                        }
                      >
                        monthly
                      </p>
                    </li>
                    <li>
                      <p
                        onClick={() => this.handleFilterChange("annual")}
                        className={
                          this.state.filter === "annual" ? "active" : null
                        }
                      >
                        annual
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="dash-date">
                  <p>Start Date:</p>
                  <DatePicker
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={
                      this.state.startDate && this.state.startDate.length > 0
                        ? new Date(this.state.startDate)
                        : ""
                    }
                    onChange={(event) =>
                      this.handleDateChange(event, "startDate")
                    }
                    maxDate={
                      this.state.endDate && this.state.endDate.length > 0
                        ? new Date(this.state.endDate)
                        : new Date()
                    }
                  />

                  <p>End Date:</p>

                  <DatePicker
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={
                      this.state.endDate && this.state.endDate.length > 0
                        ? new Date(this.state.endDate)
                        : ""
                    }
                    onChange={(event) =>
                      this.handleDateChange(event, "endDate")
                    }
                    minDate={
                      this.state.endDate && this.state.endDate.length > 0
                        ? new Date(this.state.endDate)
                        : ""
                    }
                    maxDate={new Date()}
                  />
                </div>
              </div>
            </div>

            <div class="admin-dash-ratio">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="admin-dash-ratiobox">
                    <div class="admin-dash-ratiobox-top">
                      <div class="admin-dash-ratiobox-icon">
                        <i class="fas fa-wallet"></i>
                      </div>
                      <div class="admin-dash-ratiobox-detail">
                        <p>Total Sale</p>
                        <h3>
                          $
                          {this.state.totalPayments
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h3>
                      </div>
                    </div>
                    <div class="admin-dash-ratiobox-bottom">
                      <div class="admin-dash-ratiobox-graph">
                        <img src={ratioGraph} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="admin-dash-ratiobox">
                    <div class="admin-dash-ratiobox-top">
                      <div class="admin-dash-ratiobox-icon">
                        <i class="fas fa-comment-dollar"></i>
                      </div>
                      <div class="admin-dash-ratiobox-detail">
                        <p>Revenue</p>
                        <h3>
                          $
                          {this.state.totalReceivableAmount
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        </h3>
                      </div>
                    </div>
                    <div class="admin-dash-ratiobox-bottom">
                      <div class="admin-dash-ratiobox-graph">
                        <img src={ratioGraph} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="admin-dash-ratiobox">
                    <div class="admin-dash-ratiobox-top">
                      <div class="admin-dash-ratiobox-icon">
                        <i class="fas fa-cart-plus"></i>
                      </div>
                      <div class="admin-dash-ratiobox-detail">
                        <p>Total Orders</p>
                        {/* <h3>{this.state.totalOrders} <span>+36% last month</span></h3> */}
                        <h3>{this.state.totalOrders} </h3>
                      </div>
                    </div>
                    <div class="admin-dash-ratiobox-bottom">
                      <div class="admin-dash-ratiobox-graph">
                        <img src={ratioGraph} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="admin-dash-graph">
              <div class="admin-dash-graph-top">
                <div class="row">
                  <div class="col-md-6 co-lg-6">
                    <div class="admin-dash-graph-heading">
                      <h3>Sales Overview</h3>
                      {/* <p>Loren ipsum dolor sit amet dolor</p> */}
                    </div>
                  </div>
                  <div class="col-md-6 co-lg-6">
                    <div class="admin-dash-graph-ratio">
                      <p>Total Sale</p>
                      <h3>
                        $
                        {this.state.totalPayments
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h3>
                      {/* <p><span>1.4%</span> less than last week</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="admin-dash-graph-img">
                <div id="chart">
                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height={400}
                  />
                </div>
              </div>
            </div>

            <div class="admin-card-box">
              <div class="vendor-table">
                <div class="vendor-table-head">
                  <div class="row">
                    <div class="col-lg-4 col-md-4">
                      <div class="entries-drop">
                        <label>USERS LIST</label>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                      <div class="vendor-table-head-right">
                        <div class="download-statement-btn">
                          <Link to={"/users"}>
                            <button type="button" class="hyper-link-btn">
                              see all
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vendor-table-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <td align="center">User ID.</td>
                          <td>Firstname</td>
                          <td>Lastname</td>
                          <td>Email</td>
                          <td>Created</td>
                          <td>Active</td>
                          <td align="center">View Detail</td>
                        </tr>
                      </thead>
                      <tbody>{usersList}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { toggleSidebar, getUsersList, getStatisticsData },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Dashboard);
