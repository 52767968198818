import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getProductCategoryList = (queryString) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/product-category/list?${queryString}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getProductCategoryDetail = (categoryId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/product-category/detail/${categoryId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const createProductCategory =
  (productCategoryInfo) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/product-category/add`,
        data: productCategoryInfo,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const updateProductCategoryDetail =
  (productCategoryInfo, categoryId) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/product-category/update-product-category/${categoryId}`,
        data: productCategoryInfo,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const searchProductCategory =
  (offset, searchKeyword) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/product-category/by/search?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getAllParentCategoryList = (id) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/product-category/getAllParentCategoryList?id=${id}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({
      type: "LIST_ALL_PARENT_CATEGORY",
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getSellersCategoryList = (offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/product-category/sellers-category-list?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const searchsellerCategory =
  (offset, searchKeyword) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/product-category/by/searchSellersCategory?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };
