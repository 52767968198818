import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import DobaBreadcrumb from "./DobaBreadcrumb";
import * as dobaAction from "../../Redux/Actions/dobaAction";

import "./doba.scss";

const DobaProductDetail = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const spuId = param?.spuId;
  const [productState, setProductState] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [propsData, setPropsData] = useState(props.location?.state);
  const [messageState, setMessageState] = useState({
    success: true,
    message: "",
  });

  useEffect(() => {
    loadProduct();
    setPropsDataState();
  }, []);

  const setPropsDataState = () => {
    if (propsData && propsData.category) {
      setPropsData((prevState) => ({
        ...propsData,
        data: JSON.parse(props?.location?.state.data),
      }));
    }
  };

  const loadProduct = async () => {
    setLoader(true);
    const response = await dispatch(dobaAction.getProductDetail(spuId));
    if (response && response.success) {
      setProductState(response.data);
      setSelectedVariant(response.data.map((product) => product.children[0]));
      response.isAlreadyAdded && setAddButton(true);
      setLoader(false);
    }
  };

  const handleVariantSelect = (variant, index) => {
    let sVariant = [...selectedVariant];
    sVariant[index] = variant;
    setSelectedVariant(sVariant);
  };

  const handleSubmit = async () => {
    setAddButton(true);
    setShowModal(false);
    const response = await dispatch(dobaAction.addDobaProduct(productState[0]));
    if (response && response.success) {
      setMessageState({
        success: true,
        message: response.message,
      });
    } else {
      setMessageState({
        success: false,
        message: "Something went wrong!",
      });
    }
    setTimeout(() => {
      setMessageState({
        success: true,
        message: "",
      });
    }, 1000);
  };

  const confirmationModal = () => (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="calendar-modal"
      centered
    >
      <Modal.Header closeButton onClick={() => setShowModal(false)}>
        <p>
          <strong>Confirmation</strong>
        </p>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure to add this product to subsciety?</p>
        <div className="button-center">
          <button onClick={() => handleSubmit()} className="orange-btn">
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const limiter = (string) => {
    let limit = string.length < 16 ? string.length - 1 : 15;
    return string.substring(0, limit) + "...";
  };

  const updateBreadcrumb = (data) => {
    let tempPropsData = { ...propsData };
    if (data.categoryList) {
      tempPropsData.data.categoryList = data.categoryList;
    } else if (data.categoryHierarchy) {
      tempPropsData.data.categoryHierarchy = data.categoryHierarchy;
    }
    navigate("/doba-dashboard", { state: JSON.stringify(tempPropsData.data) });
  };

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>Product Detail</h2>
      </div>
      {messageState.message && messageState.message.length !== 0 && (
        <div
          className={`alert alert-${
            messageState.success ? "success" : "danger"
          }`}
        >
          {messageState.message}
        </div>
      )}
      {productState.length > 0 ? (
        productState.map((product, index) => (
          <div className="admin-card-box" key={index}>
            <DobaBreadcrumb
              categoryHierarchy={
                propsData?.data?.categoryHierarchy
                  ? [...propsData.data.categoryHierarchy, propsData.category]
                  : []
              }
              isProductDetailPage={true}
              productTitle={limiter(product?.title)}
              isFromCategory={propsData && propsData.category}
              updateBreadcrumb={(e) => updateBreadcrumb(e)}
              seller={propsData?.seller}
            />

            <div className="d-flex align-items-center justify-content-between pt-3 mb-3">
              <h4>{product?.title}</h4>
              <button
                className={`orange-btn ${addButton && "disable-btn"}`}
                onClick={() => setShowModal(true)}
                disabled={addButton}
              >
                Add Product
              </button>
            </div>

            {selectedVariant[index] &&
              selectedVariant[index].skuPicList.map((pic, pIndex) => (
                <img
                  alt=""
                  style={{}}
                  className="doba-product-search"
                  src={pic}
                  key={pIndex}
                />
              ))}

            <ul className="d-flex align-items-center flex-wrap doba-main p-0">
              {product.children.map((variant, vIndex) => (
                <li
                  key={vIndex}
                  className={`card m-2 ${
                    variant.skuId === selectedVariant[index]?.skuId
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleVariantSelect(variant, index)}
                >
                  <p>
                    {variant.variantProps.map((v) => v.propValue).join("-")}
                  </p>
                  <p>
                    Selling Price : {variant.currencyName}{" "}
                    {variant.stocks[0]["sellingPrice"]}
                  </p>
                  <p>
                    Market Price : {variant.currencyName} {variant.marketPrice}
                  </p>
                </li>
              ))}
            </ul>

            <div className="form-group">
              <div
                dangerouslySetInnerHTML={{ __html: product.goodsDesc }}
                className="doba-img-set"
              ></div>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Category : </strong>
                    <span>
                      {"  "}
                      {product.cateName}
                    </span>
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Seller : </strong>
                    <span>
                      {"  "}
                      {product.sellerName}
                    </span>
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Brand : </strong>
                    <span>
                      {"  "}
                      {product.brand}
                    </span>
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Ship Methods : </strong>
                    <span>
                      {"  "}
                      {product.shipMethods &&
                        product.shipMethods
                          .map((ship) => ship.shipName + "-" + ship.stockRegion)
                          .join(", ")}
                    </span>
                  </p>
                </div>
              </div>
              {product.packagingInformation && (
                <div>
                  <p>
                    <strong>Packaging Information :</strong>
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        <strong>Size</strong>
                      </p>
                      <ul>
                        <li>
                          height :{" "}
                          {product.packagingInformation.packagingSize.height +
                            " " +
                            product.packagingInformation.packagingSize.dimUnit}
                        </li>
                        <li>
                          length :{" "}
                          {product.packagingInformation.packagingSize.length +
                            " " +
                            product.packagingInformation.packagingSize.dimUnit}
                        </li>
                        <li>
                          width :{" "}
                          {product.packagingInformation.packagingSize.width +
                            " " +
                            product.packagingInformation.packagingSize.dimUnit}
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-6">
                      <p>
                        <strong>Weight :</strong>
                        <span>
                          {product.packagingInformation.packagingWeight.weight +
                            " " +
                            product.packagingInformation.packagingWeight
                              .weightUnit}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))
      ) : loader ? (
        <div colSpan={7} className="text-center">
          <div class="spinner-border" role="status"></div>
        </div>
      ) : (
        "No data found"
      )}
      {confirmationModal()}
    </div>
  );
};

export default DobaProductDetail;
