import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import "../../Styles/style.scss";
import "../../Containers/Vendor-Product-Edit/calendar.scss";

const AlertModal = (props) => {
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState('')
    const [singleButton, setSingleButton] = useState(true)
    const [title, setTitle] = useState('Alert')
    const [multiButtonState, setMultiButtonState] = useState({
        positive: '',
        negative: ''
    })

    useEffect(() => {
        setShow(props.data?.open)
        setMessage(props.data?.message)
        setSingleButton(props.data?.singleButton)
        setTitle(props.data?.title ? props.data?.title : 'Alert')
        if (!props.data?.singleButton) {
            setMultiButtonState({
                positive: props.data?.positiveButton,
                negative: props.data?.negativeButton
            })
        }
    }, [props])

    const handleModalHide = () => {
        props.closeAlertModal()
        setShow(false)
        setMessage('')
        setSingleButton(false)

    }

    const handleButtonClick = (type) => {
        props.handleAlertResponse(type, props.data?.id, props.data?.data)
        handleModalHide()
    }

    return (
        <Modal
            show={show}
            onHide={handleModalHide}
            className="calendar-modal"
        >
            <Modal.Header className="calendar-header"><h3>{title}</h3></Modal.Header>
            <Modal.Body className="text-center">
                <p>{message}</p>
                {
                    singleButton ?
                        <button className="orange-btn" onClick={handleButtonClick}>okay</button>
                        :
                        <>
                            {
                                props.data?.negativeButton &&
                                <button className="orange-btn mr-2"
                                        onClick={() => handleButtonClick('negative')}>{multiButtonState?.negative}</button>
                            }
                            <button className="orange-btn"
                                    onClick={() => handleButtonClick('positive')}>{multiButtonState?.positive}</button>
                        </>
                }

            </Modal.Body>
        </Modal>
    )

}

export default AlertModal;