import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";

import { useDispatch } from "react-redux";

import AlertModal from "../../Components/AlertModal/AlertModal";
import StarRating from "../../Components/StarRating/starRating";

import {
  getReviewDetailByOrderId,
  deleteComment,
  disableComment,
} from "../../Redux/Actions/reportedReviewAction";

import "../../Styles/profile.scss";

const Reported_Reviews_Details = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();

  const orderId = param.orderId;
  const [orderDetail, setOrderDetail] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    singleButton: true,
    request: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderAct, setShowLoaderAct] = useState(false);

  useEffect(() => {
    fetchReviewDetail();
  });

  const fetchReviewDetail = async () => {
    const response = await dispatch(getReviewDetailByOrderId(orderId));
    if (response?.data?.data) {
      setOrderDetail(response.data.data);
    }
  };

  const handleSubmit = async () => {
    setShowLoader(true);
    const res = await dispatch(deleteComment(orderId));
    if (res?.data?.data?.statusCode === 201) {
      setShowLoader(false);
      navigate("/reported-reviews");
    }
  };

  const handleDisableButton = async (status) => {
    setShowLoaderAct(true);
    const res = await dispatch(disableComment(orderId, status));
    setShowLoaderAct(false);
    if (res?.data?.data?.statusCode === 201) {
      setShowLoader(false);
      setOrderDetail((prevState) => ({ ...prevState, status }));
      navigate("/reported-reviews");
    }
  };

  const onSubmit = (type) => {
    setAlertState({
      open: true,
      message: `Are you sure you want to ${type} this comment?`,
      singleButton: true,
      request: type,
    });
  };

  const closeAlertModal = () => {
    setAlertState({
      open: false,
      message: "",
      singleButton: true,
      request: "",
    });
  };

  const handleAlertResponse = () => {
    if (alertState.request === "delete") {
      handleSubmit();
    } else {
      handleDisableButton(orderDetail.status ? 0 : 1);
    }
  };

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>Review Detail</h2>
      </div>

      <div className="admin-card-box">
        <div className="cart-box w-100">
          <div className="cart-box-detail w-100">
            <div className="cart-box-img">
              <img src={orderDetail?.image} alt="" />
            </div>
            <div className="cart-box-item-detail w-100">
              <h2>{orderDetail?.product_name}</h2>
            </div>
          </div>
        </div>
        <p>
          <strong>Customer name : </strong>

          {orderDetail?.customer_name}
        </p>
        <p>
          <strong>Vendor name : </strong>

          {orderDetail?.vendor_name}
        </p>
        <p>
          <strong>Ratings : </strong>

          <StarRating
            name="hover-feedback"
            value={orderDetail?.rating ? orderDetail?.rating : 0}
            isHalf={true}
            readOnly
          />
        </p>
        <p>
          <strong>Commented on : </strong>
          {orderDetail?.created_ts
            ? new Date(orderDetail?.created_ts).toISOString().substring(0, 10)
            : null}
        </p>
        <p>
          <strong>Comment : </strong>

          {orderDetail?.comment}
        </p>
        <p>
          <strong>Is Reported : </strong>

          {orderDetail?.is_reported ? "yes" : "no"}
        </p>
        <p>
          <strong>Review Status : </strong>

          {orderDetail?.status ? "Active" : "Disabled"}
        </p>
        <p>
          <strong>Vendor Comment : </strong>

          {orderDetail?.vendor_comment}
        </p>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-btn text-center">
              <button
                type="button"
                onClick={() => onSubmit("delete")}
                className="orange-outline-btn"
              >
                Delete comment
                {(() => {
                  if (showLoader) {
                    return (
                      <span className="spinner-border spinner-border-sm ml-1"></span>
                    );
                  }
                })()}
              </button>
              <button
                type="button"
                onClick={() =>
                  onSubmit(orderDetail.status ? "disable" : "active")
                }
                className="orange-outline-btn"
              >
                {orderDetail.status ? "Disable" : "Active"} comment
                {(() => {
                  if (showLoaderAct) {
                    return (
                      <span className="spinner-border spinner-border-sm ml-1"></span>
                    );
                  }
                })()}
              </button>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        data={alertState}
        closeAlertModal={closeAlertModal}
        handleAlertResponse={() => handleAlertResponse()}
      />
    </div>
  );
};
export default Reported_Reviews_Details;
