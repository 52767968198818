import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getDisputesList =
  (offset, limit = 10) =>
  async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/dispute/disputes?offset=${offset}&limit=${limit}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getDisputeDetail = (disputeId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/dispute/dispute/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "ORDER_STATUS", payload: res.data?.data?.status });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const searchDisputes = (offset, searchKeyword) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/dispute/by/search-disputes?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const closeDispute = (disputeId, body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/dispute/close/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: body,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const createDisputeMessage = (disputeId, body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/dispute/create-message/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: body,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getDisputeConversationById =
  (disputeId, offset) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/dispute/get-dispute-conversation/${disputeId}?offset=${offset}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getDisputeDetailByOrderId = (orderId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/dispute/get-dispute/${orderId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const openDispute = (disputeId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/dispute/open/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
