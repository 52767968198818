import React from "react";
import moment from "moment";

export const sortObject = (object) => {
  return Object.fromEntries(Object.entries(object).sort());
};

export const matchTwoObjects = (Object1 = null, Object2 = null) => {
  const sortedObject1 = sortObject(Object1);
  const sortedObject2 = sortObject(Object2);
  const isSame =
    JSON.stringify(sortedObject1) === JSON.stringify(sortedObject2);

  return isSame;
};

export const replace = (string, pattern, replacement) => {
  let desiredResponse = string;
  if (!desiredResponse) {
    return null;
  }
  let isSpacesLeft = true;
  while (isSpacesLeft) {
    desiredResponse = desiredResponse.replace(pattern, replacement);
    if (!desiredResponse.includes(pattern)) {
      isSpacesLeft = false;
    }
  }
  return desiredResponse;
};

export const getShortName = (name) => {
  if (!name) {
    return "";
  }
  const nameArray = name.split(" ");
  let shortName = "";
  for (const name of nameArray) {
    shortName += name[0];
  }
  return shortName;
};

export const convertTime = (timestamp) => {
  let unixTimeStamp = new Date(`${timestamp}`).getTime();
  let message = new Date(Math.ceil(unixTimeStamp));
  let finalDate;
  let today = new Date();
  let diff = moment(today, "DD.MM.YYYY")
    .startOf("day")
    .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
  if (diff === 0) {
    finalDate = moment(message).format("hh:mm A");
  } else if (diff === 1) {
    finalDate = "Yesterday";
  } else {
    finalDate = moment(message).format("DD/MM/YYYY");
  }
  return finalDate;
};

export const getUrlByString = (message) => {
  var array = [];
  var array2 = [];
  array = message.split(" ");
  array2 = array.map((singlemessage, index) => {
    if (singlemessage.includes("\n")) {
      const tempVar = singlemessage.split("\n").map((temp_message) =>
        temp_message.match(
          /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gi
        ) ? (
          <>
            <a
              className="usermessage"
              href={temp_message}
              target="_blank"
              rel="noreferrer"
            >
              {temp_message}
            </a>
            {`${"\n"}`}
          </>
        ) : (
          <>
            <span className="span" style={{ fontSize: 16 }}>
              {temp_message} {`${"\n"}`}
            </span>
          </>
        )
      );
      return tempVar;
    } else {
      return singlemessage.match(
        /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gi
      ) ? (
        <a
          key={index}
          className="usermessage"
          href={singlemessage}
          target="_blank"
          rel="noreferrer"
        >
          {singlemessage}{" "}
        </a>
      ) : (
        <span key={index} className="span" style={{ fontSize: 16 }}>
          {singlemessage}{" "}
        </span>
      );
    }
  });
  return array2;
};

export const fileFormats = {
  video: [
    "mp4",
    "webm",
    "3gp",
    "3gpp",
    "3g2",
    "3gpp2",
    "mpg",
    "mpeg",
    "ogg",
    "ogv",
    "ts",
    "vnd.dlna.mpeg-tts",
    "mp2t",
  ],
  image: ["jpeg", "png", "jpg", "gif"],
  doc: [
    "pdf",
    "txt",
    "plain",
    "xls",
    "vnd.ms-excel",
    "xlsx",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "doc",
    "msword",
    "docx",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
};

export const getFileNameFromUrl = (fileUrl, fileType) => {
  if (!fileUrl) {
    return "";
  }
  const fileName =
    fileUrl.split("?")[0].substring(fileUrl.lastIndexOf("/") + 1) +
    "." +
    fileType;
  return fileName;
};

export const getFileSizeByByteLength = (byteLength) => {
  let size = 0;
  let unit = "Bytes";
  if (Math.round(byteLength) < 1000) {
    size = Math.round(byteLength);
    unit = "Bytes";
  } else if (Math.round(byteLength) > 999 && byteLength < 1000000) {
    size = Math.round(byteLength / 1000);
    unit = "KB";
  } else if (Math.round(byteLength) > 999999 && byteLength < 1000000000) {
    size = Math.round(byteLength / 1000000);
    unit = "MB";
  }
  return size + " " + unit;
};
