import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getblogsList = (offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/blogs?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getReviews = (offset, search, blog) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/blogs/reviews/${offset}?${
        search ? `search=${search}` : ""
      }${blog && search ? "&" : ""}${blog ? `blog=${blog}` : ""}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const searchBlog = (offset, searchKeyword) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/blogs/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getblogDetail = (blogId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/blogs/${blogId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateBlogDetail = (blogDetail) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/blogs/update-blog`,
      data: blogDetail,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateReview = (blogDetail) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/blogs/update-review`,
      data: blogDetail,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const addBlogDetail = (blogDetail) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/blogs/create-blog`,
      data: blogDetail,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getAllBlogCategoryList =
  (queryString = "") =>
  async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/blogs/blog-category${queryString}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const searchBlogCategory =
  (offset, searchKeyword) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/blogs/category/by/search?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const createBlogCategory = (blogCategoryInfo) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/blogs/category/add`,
      data: blogCategoryInfo,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getBlogCategoryDetail = (categoryId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/blogs/category/detail/${categoryId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateBlogCategoryDetail =
  (blogCategoryInfo, categoryId) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/blogs/category/update-product-category/${categoryId}`,
        data: blogCategoryInfo,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };
