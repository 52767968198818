import React, { Suspense } from "react";

import PrivateGuard from "./privateGuard";

import { Routes, Route } from "react-router-dom";

import Sidebar from "../Containers/Sidebar/Sidebar";
import Header from "../Components/Header/Header";
import Dashboard from "../Containers/Dashboard/Dashboard";
import Order_List from "../Containers/Order-List/Order_List";
import Order_Detail from "../Containers/Order-Detail/Order_Detail";
import User_List from "../Containers/User-List/User_List";
import User_Detail from "../Containers/User-Detail/User_Detail";
import Order_Track from "../Containers/Order-Track/Order_Track";

import Payment_List from "../Containers/Payment-List/Payment_List";
import Vendor_Payment_List from "../Containers/Vendor_Payment_List/VendorPaymentList";
import Page_List from "../Containers/Page-List/Page_List";
import Page_Detail from "../Containers/Page-Detail/Page_Detail";
import Coupon_List from "../Containers/Coupon-List/Coupon_List";
import Coupon from "../Containers/Edit-Coupon/Coupon";

import Vendor_List from "../Containers/Vendor-List/Vendor_List";
import Vendor_Edit from "../Containers/Vendor-Edit/Vendor-Edit";
import Vendor_Product_List from "../Containers/Vendor-Product-List/Vendor_Product_List";
import Vendor_Product_Edit from "../Containers/Vendor-Product-Edit/Vendor_Product_Edit";

import Product_Category_List from "../Containers/Product-Category-List/Product_Category_List";
import Edit_Product_Category from "../Containers/Edit-Product-Category/Edit_Product_Category";
import Login from "../Containers/login/Login";
import ForgotPassword from "../Containers/ForgotPassword/ForgotPassword";
import ResetPassword from "../Containers/ResetPassword/ResetPassword";
import Change_Password from "../Containers/Change-Password/Change_Password";

import Approve_Products from "../Containers/Approve-Products/Approve_Products";
import Featured_Products from "../Containers/Featured-Products/Featured_Products";
import CategoryApproval from "../Containers/Category-Approval/CategoryApproval";
import Blog_List from "../Containers/Blogs/blogList";
import Blog_Detail from "../Containers/Blogs/blogDetail";
import BlogCategoryList from "../Containers/Blogs-Category-List/blogCategoryList";
import EditBlogCategory from "../Containers/Blogs-Category-List/editBlogCategory";

import Search_Category_List from "../Containers/Search-Category-List/Search_Category_List";
import Edit_Search_Category from "../Containers/Edit-Search-Category/Edit_Search_Category";
import DobaDashboard from "../Containers/Doba/DobaDashboard";
import DobaProductList from "../Containers/Doba/DobaProductList";
import DobaProductSearch from "../Containers/Doba/DobaProductSearch";
import DobaProductDetail from "../Containers/Doba/DobaProductDetail";
import DobaSellerList from "../Containers/Doba/DobaSellerList";
import Dispute_List from "../Containers/Dispute-List/Dispute_List";
import Dispute_Detail from "../Containers/Dispute-Detail/Dispute_Detail";
import Reported_Reviews from "../Containers/Reported-Reviews/Reported_Reviews";
import Reported_Reviews_Details from "../Containers/Reported-Reviews-Details/Reported_Reviews_Details";
import productRating from "../Containers/productRating/productRating";

export const WithSidebarProtected = (props) => {
  return (
    <>
      <div class="main-body">
        <Sidebar {...props} />
        <div class="main-content">
          <div class="container-fluid">
            <Header />
            <PrivateGuard {...props} />
            <div class="main-footer">
              <p>
                © {new Date().getFullYear()} Subsciety. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PrivateRoutes = () => {
  return (
    <>
      <div className="PrivateArea__content">
        <div className="main_sec">
          <Suspense fallback={<p>Loading...</p>}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route
                path={"/dashboard"}
                element={
                  <WithSidebarProtected
                    component={Dashboard}
                    route={"/dashboard"}
                  />
                }
              />
              <Route
                path={"/orders"}
                element={
                  <WithSidebarProtected
                    component={Order_List}
                    route={"/orders"}
                  />
                }
              />
              <Route
                path={"/disputes"}
                element={
                  <WithSidebarProtected
                    component={Dispute_List}
                    route={"/disputes"}
                  />
                }
              />
              <Route
                path={"/product-rating"}
                element={
                  <WithSidebarProtected
                    component={productRating}
                    route={"/product-rating"}
                  />
                }
              />
              <Route
                path={"/reported-reviews"}
                element={
                  <WithSidebarProtected
                    component={Reported_Reviews}
                    route={"/reported-reviews"}
                  />
                }
              />
              <Route
                path={"/reported-review/:orderId"}
                element={
                  <WithSidebarProtected
                    component={Reported_Reviews_Details}
                    route={"/reported-review/:orderId"}
                  />
                }
              />
              <Route
                path={"/product-rating"}
                element={
                  <WithSidebarProtected
                    component={productRating}
                    route={"/product-rating"}
                  />
                }
              />
              <Route
                path={"/doba-product-search"}
                element={
                  <WithSidebarProtected
                    component={DobaProductSearch}
                    route={"/doba-product-search"}
                  />
                }
              />
              <Route
                path={"/doba-product-list"}
                element={
                  <WithSidebarProtected
                    component={DobaProductList}
                    route={"/doba-product-list"}
                  />
                }
              />
              <Route
                path={"/doba-dashboard"}
                element={
                  <WithSidebarProtected
                    component={DobaDashboard}
                    route={"/doba-dashboard"}
                  />
                }
              />
              <Route
                path={"/doba-product-detail/:spuId"}
                element={
                  <WithSidebarProtected
                    component={DobaProductDetail}
                    route={"/doba-product-detail/:spuId"}
                  />
                }
              />

              <Route
                path={"/doba-seller-list"}
                element={
                  <WithSidebarProtected
                    component={DobaSellerList}
                    route={"/doba-seller-list"}
                  />
                }
              />
              <Route
                path={"/users"}
                element={
                  <WithSidebarProtected
                    component={User_List}
                    route={"/users"}
                  />
                }
              />
              <Route
                path={"/users/:userId"}
                element={
                  <WithSidebarProtected
                    component={User_Detail}
                    route={"/users/:userId"}
                  />
                }
              />

              <Route
                path={"/orders/:orderId"}
                element={
                  <WithSidebarProtected
                    component={Order_Detail}
                    route={"/orders/:orderId"}
                  />
                }
              />

              <Route
                path={"/dispute/:disputeId"}
                element={
                  <WithSidebarProtected
                    component={Dispute_Detail}
                    route={"/dispute/:disputeId"}
                  />
                }
              />

              <Route
                path={"/orders/track/:trackId"}
                element={
                  <WithSidebarProtected
                    component={Order_Track}
                    route={"/orders/track/:trackId"}
                  />
                }
              />

              <Route
                path={"/payments"}
                element={
                  <WithSidebarProtected
                    component={Payment_List}
                    route={"/payments"}
                  />
                }
              />

              <Route
                path={"/vendors/payments/:id"}
                element={
                  <WithSidebarProtected
                    component={Vendor_Payment_List}
                    route={"/vendors/payments/:id"}
                  />
                }
              />
              <Route
                path={"/pages"}
                element={
                  <WithSidebarProtected
                    component={Page_List}
                    route={"/pages"}
                  />
                }
              />
              <Route
                path={"/pages/:pageId"}
                element={
                  <WithSidebarProtected
                    component={Page_Detail}
                    route={"/pages/:pageId"}
                  />
                }
              />

              <Route
                path={"/coupon"}
                element={
                  <WithSidebarProtected
                    component={Coupon_List}
                    route={"/coupon"}
                  />
                }
              />

              <Route
                path={"/create-coupon"}
                element={
                  <WithSidebarProtected
                    component={Coupon}
                    route={"/create-coupon"}
                  />
                }
              />

              <Route
                path={"/edit-coupon/:couponId"}
                element={
                  <WithSidebarProtected
                    component={Coupon}
                    route={"/edit-coupon/:couponId"}
                  />
                }
              />

              <Route
                path={"/product-category"}
                element={
                  <WithSidebarProtected
                    component={Product_Category_List}
                    route={"/product-category"}
                  />
                }
              />

              <Route
                path={"/search-category"}
                element={
                  <WithSidebarProtected
                    component={Search_Category_List}
                    route={"/search-category"}
                  />
                }
              />

              <Route
                path={"/create-product-category"}
                element={
                  <WithSidebarProtected
                    component={Edit_Product_Category}
                    route={"/create-product-category"}
                  />
                }
              />

              <Route
                path={"/create-search-category"}
                element={
                  <WithSidebarProtected
                    component={Edit_Search_Category}
                    route={"/create-search-category"}
                  />
                }
              />

              <Route
                path={"/edit-product-category/:categoryId"}
                element={
                  <WithSidebarProtected
                    component={Edit_Product_Category}
                    route={"/edit-product-category/:categoryId"}
                  />
                }
              />

              <Route
                path={"/edit-search-category/:categoryId"}
                element={
                  <WithSidebarProtected
                    component={Edit_Search_Category}
                    route={"/edit-search-category/:categoryId"}
                  />
                }
              />

              <Route
                path={"/edit-search-category/:categoryId/approve"}
                element={
                  <WithSidebarProtected
                    component={Edit_Search_Category}
                    route={"/edit-search-category/:categoryId/approve"}
                  />
                }
              />

              <Route
                path={"/sellers-category"}
                element={
                  <WithSidebarProtected
                    component={CategoryApproval}
                    route={"/sellers-category"}
                  />
                }
              />

              <Route
                path={"/vendors"}
                element={
                  <WithSidebarProtected
                    component={Vendor_List}
                    route={"/vendors"}
                  />
                }
              />

              <Route
                path={"/vendors/:vendorId"}
                element={
                  <WithSidebarProtected
                    component={Vendor_Edit}
                    route={"/vendors/:vendorId"}
                  />
                }
              />

              <Route
                path={"/vendors/product/:vendorId"}
                element={
                  <WithSidebarProtected
                    component={Vendor_Product_List}
                    route={"/vendors/product/:vendorId"}
                  />
                }
              />

              <Route
                path={"/vendors/product/comments/:productId"}
                element={
                  <WithSidebarProtected
                    component={Reported_Reviews}
                    route={"/vendors/product/comments/:productId"}
                  />
                }
              />

              <Route
                path={"/vendors/product/:vendorId/edit/:slug"}
                element={
                  <WithSidebarProtected
                    component={Vendor_Product_Edit}
                    route={"/vendors/product/:vendorId/edit/:slug"}
                  />
                }
              />

              <Route path={"/forgot-password"} element={<ForgotPassword />} />

              <Route path={"/reset-password"} element={<ResetPassword />} />

              <Route
                path={"/change-password"}
                element={
                  <WithSidebarProtected
                    component={Change_Password}
                    route={"/change-password"}
                  />
                }
              />

              <Route
                path={"/product-approval"}
                element={
                  <WithSidebarProtected
                    component={Approve_Products}
                    route={"/product-approval"}
                  />
                }
              />

              <Route
                path={"/featured-product"}
                element={
                  <WithSidebarProtected
                    component={Featured_Products}
                    route={"/featured-product"}
                  />
                }
              />
              <Route
                path={"/blogs"}
                element={
                  <WithSidebarProtected
                    component={Blog_List}
                    route={"/blogs"}
                  />
                }
              />

              <Route
                path={"/blogs/:blogId"}
                element={
                  <WithSidebarProtected
                    component={Blog_Detail}
                    route={"/blogs/:blogId"}
                  />
                }
              />
              <Route
                path={"/create-blog"}
                element={
                  <WithSidebarProtected
                    component={Blog_Detail}
                    route={"/create-blog"}
                  />
                }
              />

              <Route
                path={"/blog-category"}
                element={
                  <WithSidebarProtected
                    component={BlogCategoryList}
                    route={"/blog-category"}
                  />
                }
              />

              <Route
                path={"/edit-blog-category/:categoryId"}
                element={
                  <WithSidebarProtected
                    component={EditBlogCategory}
                    route={"/edit-blog-category/:categoryId"}
                  />
                }
              />

              <Route
                path={"/add-blog-category"}
                element={
                  <WithSidebarProtected
                    component={EditBlogCategory}
                    route={"/add-blog-category"}
                  />
                }
              />
              {/*
                <PrivateGuard
                  path="/reviews/:blogId"
                  component={Container}
                  exact
                /> */}
            </Routes>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default PrivateRoutes;
