import React, { Component } from "react";

import { debounce } from "lodash";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InfiniteScroll from "react-infinite-scroll-component";

import {
  getPaymentsListForVendor,
  searchPaymentForVendor,
} from "../../Redux/Actions/paymentAction";

import withNavigate from "../../Components/customHooks/navigate";

class Vendor_Payment_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      modalInfo: "",
      showLoader: false,
      paymentStatus: null,
      orderStatus: null,
    };
  }

  componentDidMount() {
    this.sellerId = this.props.param.id;
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (
          (this.state.searchKeyword && this.state.searchKeyword.length > 0) ||
          this.state.paymentStatus ||
          this.state.orderStatus
        ) {
          result = await this.props.searchPaymentForVendor(
            this.state.offset,
            this.state.searchKeyword.trim(),
            this.sellerId,
            this.state.paymentStatus,
            this.state.orderStatus
          );
        } else {
          result = await this.props.getPaymentsListForVendor(
            this.state.offset,
            this.sellerId
          );
        }

        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    let value = event.target.value;
    if (field === "maxLimit") {
      await this.setState({
        [field]: value === "all" ? value : +value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  openModal = (payment) => {
    this.setState({
      modalInfo: "",
    });
    if (Object.keys(payment).length > 0) {
      let payoutResponse = payment.payout_response
        ? JSON.parse(payment.payout_response)
        : null;
      if (payoutResponse) {
        let payoutDate = new Date(payoutResponse.created * 1000)
          .toISOString()
          .substring(0, 10);
        let { id, amount } = payoutResponse;
        this.setState({
          modalInfo: (
            <tr>
              <td>
                <h5>Payout Date</h5>
                <p>{payoutDate}</p>
              </td>
              <td>
                <h5>payment transaction:</h5>
                <p>{id}</p>
              </td>
              <td>
                <h5>Receivable amount:</h5>
                {parseFloat((amount / 100).toFixed(2))}
              </td>
              <td>
                <h5>Status:</h5>
                <p>{payment?.payout_status.toUpperCase()}</p>
              </td>
            </tr>
          ),
        });
      } else {
        this.setState({
          modalInfo: <tr>Payout not initiated!</tr>,
        });
      }
    } else {
      this.setState({
        modalInfo: <tr>Something went wrong!</tr>,
      });
    }
  };

  render() {
    const payments = this.state.items.map((i) => {
      const date = i.createTS
        ? new Date(i.createTS).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={i.id}>
          <td align="center">
            <Link class="orange" to={`/orders/${i.orderId}`}>
              #{i.orderId ? i.orderId : `-`}
            </Link>
          </td>
          <td>{date}</td>
          <td>{i.method ? i.method : `-`}</td>
          <td>${i.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
          <td>
            ${i.totalamount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </td>
          <td>
            <p class="orange">{i.orderStatus ? i.orderStatus : `-`}</p>
          </td>
          <td>
            <p class="orange">{i.status ? i.status : `-`}</p>
          </td>
          <td>
            <p class="orange">{i.payout_status ? i.payout_status : `-`}</p>
          </td>
          <td align="center">
            <p
              style={{ cursor: "pointer" }}
              class="orange"
              onClick={() => this.openModal(i)}
              data-toggle="modal"
              data-target="#paymentdetails"
            >
              View Detail
            </p>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div class="admin-card">
          <div class="admin-card-head">
            <h2>payments list</h2>
          </div>
          <div class="admin-card-box">
            <div class="vendor-table">
              <div class="vendor-table-head">
                <div class="row">
                  <div class="col-lg-3 col-md-4">
                    <div class="entries-drop">
                      <label>show</label>
                      <select
                        class="entries-controls"
                        onChange={(e) => this.handleChange(e, "maxLimit")}
                        value={this.state.maxLimit}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="all">All</option>
                      </select>
                      <p>Entries</p>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-8">
                    <div class="vendor-table-head-right">
                      <div class="entries-drop">
                        <label>order Status</label>
                        <select
                          class="entries-controls"
                          onChange={(e) => this.handleChange(e, "orderStatus")}
                          value={this.state.orderStatus}
                        >
                          <option value="">All</option>
                          <option value="pending">PENDING</option>
                          <option value="confirmed">CONFIRMED</option>
                          <option value="succeeded">SUCCEEDED</option>
                          <option value="canceled">CANCELED</option>
                          <option value="completed">COMPLETED</option>
                        </select>
                      </div>
                      <div class="entries-drop">
                        <label>Payment Status</label>
                        <select
                          class="entries-controls"
                          onChange={(e) =>
                            this.handleChange(e, "paymentStatus")
                          }
                          value={this.state.paymentStatus}
                        >
                          <option value="">All</option>
                          <option value="pending">PENDING</option>
                          <option value="succeeded">SUCCEEDED</option>
                          <option value="paid">PAYMENT RECEIVED</option>
                        </select>
                      </div>
                      <div class="vendor-table-search mr-2">
                        <input
                          class="form-control"
                          onChange={(e) =>
                            this.handleChange(e, "searchKeyword")
                          }
                          value={this.state.searchKeyword}
                          type="text"
                          placeholder="search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="vendor-table-body">
                <div class="table-responsive">
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    next={() => this.fetchMoreData(false)}
                    hasMore={this.state.hasMore}
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <td>OrderId</td>
                          <td>created at</td>
                          <td>Method</td>
                          <td>Total</td>
                          <td>receivable Amount</td>
                          <td>Order Status</td>
                          <td>Order Payment status</td>
                          <td>Payout Status</td>
                          <td>Payout Details</td>
                        </tr>
                      </thead>
                      <tbody>{payments}</tbody>
                    </table>
                  </InfiniteScroll>
                  {this.state.showLoader ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal paymentdetails-modal" id="paymentdetails">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <div class="paymentdetails-heading">
                  <h4>Payout details</h4>
                </div>
                <button type="button" class="close" data-dismiss="modal">
                  <i class="fas fa-times"></i>
                </button>
              </div>

              <div class="modal-body">
                <div class="paymentdetails-table">
                  <div class="table-responsive">
                    <table class="table">
                      <tbody>{this.state.modalInfo}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getPaymentsListForVendor, searchPaymentForVendor },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withNavigate(Vendor_Payment_List));
