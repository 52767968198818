import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { confirmAlert } from "react-confirm-alert";

import {
  getVendorById,
  updateVendorProfile,
} from "../../Redux/Actions/vendorAction";
import { getProductListCountForApproval } from "../../Redux/Actions/productAction";

import withNavigate from "../../Components/customHooks/navigate";

import "react-confirm-alert/src/react-confirm-alert.css";

class Profile extends Component {
  fieldsList = {
    id: null,
    firstname: "",
    lastname: "",
    email: "",
    imagename: "",
    role: "",
    isacceptedtnc: null,
    isactive: null,
    reset: "",
    createts: "",
    updatedts: "",
    addressId: null,
    phonenumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    type: "",
    sellerStoreId: null,
    storename: "",
    storeEmail: "",
    timestamp: "",
    successMessage: "",
    errorMessage: "",
    errorData: {},
    showLoader: false,
  };

  constructor(props) {
    super(props);
    this.state = { ...this.fieldsList };
  }

  componentDidMount() {
    this.fetchUser(this.props.param.vendorId);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.param.vendorId !== this.props.param.vendorId) {
      this.fetchUser(this.props.param.vendorId);
    }
  }

  fetchUser = async (userId) => {
    let result = await this.props.getVendorById(userId);
    if (result && result.data && !result.isError) {
      this.setUserState(result.data.data);
    }
  };

  setUserState(userInfo) {
    this.setState({
      ...userInfo,
    });
  }

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let { value } = e.target;

    if (key === "phonenumber" || key === "zipCode") {
      value = value.replace(/\D+/g, "");
    }

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  confirmVendorInactiveAlert(event, key) {
    event.persist();
    confirmAlert({
      title: "Confirm change?",
      message:
        "Inactive vendor will also inactive all this vendor's products which will lead to cancellation of customer's subscription associated with his products.",
      buttons: [
        {
          label: "Continue",
          onClick: () => {
            this.handleChange(event, key);
          },
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  }

  validateUserInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      firstname: [],
      lastname: [],
      phonenumber: [],
      storename: [],
      storeEmail: [],
    };

    if (this.state.firstname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.firstname = ["Firstname field cannot be empty."];
    } else if (
      this.state.firstname.includes(`"`) ||
      this.state.firstname.includes(`'`) ||
      this.state.firstname.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.firstname = ["This field cannot contain quotes"];
    }

    if (this.state.lastname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.lastname = ["Lastname field cannot be empty."];
    } else if (
      this.state.lastname.includes(`"`) ||
      this.state.lastname.includes(`'`) ||
      this.state.lastname.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.lastname = ["This field cannot contain quotes"];
    }

    if (!this.state.phonenumber || this.state.phonenumber.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.phonenumber = [
        "Phone number field cannot be empty.",
      ];
    }

    if (!this.state.storename || this.state.storename.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.storename = ["Store name field cannot be empty."];
    } else if (
      this.state.storename.includes(`"`) ||
      this.state.storename.includes(`'`) ||
      this.state.storename.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.storename = ["This field cannot contain quotes"];
    }

    if (!this.state.storeEmail || this.state.storeEmail.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.storeEmail = ["Email field cannot be empty."];
    } else if (
      this.state.storeEmail.includes(`"`) ||
      this.state.storeEmail.includes(`'`) ||
      this.state.storeEmail.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.storeEmail = ["This field cannot contain quotes"];
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSubmit = async () => {
    let st = this.state;
    await this.setState((prevState) => ({
      ...st,
      successMessage: "",
      errorMessage: "",
      showLoader: true,
    }));

    if (this.validateUserInfo()) {
      const profile = this.state;
      const result = await this.props.updateVendorProfile({ ...profile });

      if (result.status === 201) {
        this.props.getProductListCountForApproval();

        this.setState((prevState) => ({
          ...prevState,
          timestamp: Date.now(),
          successMessage: "Profile updated successfully",
          showLoader: false,
        }));

        setTimeout(() => {
          this.props.navigate("/vendors");
        }, 2000);
      } else {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: "Something went wrong! Please try after some time.",
          showLoader: false,
        }));
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  render() {
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>profile</h2>
        </div>
        {(() => {
          if (
            this.state.successMessage !== "" &&
            this.state.successMessage !== undefined
          ) {
            return (
              <div className="alert alert-success">
                {this.state.successMessage}
              </div>
            );
          } else if (
            this.state.errorMessage !== "" &&
            this.state.errorMessage !== undefined
          ) {
            return (
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            );
          }
        })()}
        <div class="admin-card-box">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  first name
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="15"
                  value={this.state.firstname}
                  onChange={(event) => this.handleChange(event, "firstname")}
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.firstname &&
                    this.state.errorData.firstname[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  last name
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="15"
                  value={this.state.lastname}
                  onChange={(event) => this.handleChange(event, "lastname")}
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.lastname &&
                    this.state.errorData.lastname[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  email address
                  <sup>*</sup>
                </label>
                <input
                  disabled={true}
                  type="text"
                  value={this.state.email}
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  phone number
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="15"
                  value={this.state.phonenumber}
                  onChange={(event) => this.handleChange(event, "phonenumber")}
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.phonenumber &&
                    this.state.errorData.phonenumber[0]}
                </span>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>
                  name of store
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="100"
                  value={this.state.storename}
                  onChange={(event) => this.handleChange(event, "storename")}
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.storename &&
                    this.state.errorData.storename[0]}
                </span>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>
                  email to use for contact form
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="50"
                  value={this.state.storeEmail}
                  onChange={(event) => this.handleChange(event, "storeEmail")}
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.storeEmail &&
                    this.state.errorData.storeEmail[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>Is Active?</label>
                <div className="custom-radio-input">
                  <p className="container-sub mr-2">
                    Inactive
                    <span>
                      <input
                        type="radio"
                        value="0"
                        onChange={(event) =>
                          this.confirmVendorInactiveAlert(event, "isactive")
                        }
                        checked={this.state.isactive === 0}
                        name="radio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>

                  <p className="container-sub mr-2">
                    Active
                    <span>
                      <input
                        type="radio"
                        value="1"
                        onChange={(event) =>
                          this.handleChange(event, "isactive")
                        }
                        checked={this.state.isactive === 1}
                        name="radio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-btn text-center">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  class="orange-outline-btn"
                >
                  save
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span class="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getVendorById, updateVendorProfile, getProductListCountForApproval },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(withNavigate(Profile));
