import React, { Component } from "react";
import "../../Styles/order-list.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrdersList, searchOrder } from "../../Redux/Actions/orderAction";
import { debounce } from "lodash";


class Order_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchOrder(
            this.state.offset,
            this.state.searchKeyword.trim().replace(/[^a-zA-Z 0-9.]+/g, "")
          );
        } else {
          result = await this.props.getOrdersList(this.state.offset);
        }
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();

    this.setState({
      showLoader: true,
    });
    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
      this.setState({
        showLoader: false,
      });
    }

    this.setState({
      showLoader: false,
    });
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  render() {
    const orderItems = this.state.items.map((i) => {
      const fullAddress = i.address ? JSON.parse(i.address) : null;
      const date = i.createTS
        ? new Date(i.createTS).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={i.id}>
          <td key={i.id} align="center">
            #{i.orderNumber ? i.orderNumber : i.id}
          </td>
          <td>{i.fullname ? i.fullname : "Guest user"}</td>
          <td>{date}</td>
          <td>{fullAddress ? fullAddress.city : null}</td>
          <td>${i.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
          <td>
            <p class="orange">{i.status}</p>
          </td>
          <td align="center" style={{ display: "grid" }}>
            <Link class="orange" to={`/orders/${i.id}`}>
              order detail
            </Link>
            {i.trackingId ? (
              <Link class="orange" to={`/orders/track/${i.id}`}>
                track this order
              </Link>
            ) : null}
          </td>
        </tr>
      );
    });
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>orders list</h2>
        </div>
        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search mr-2">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td align="center">Order No.</td>
                        <td>customer</td>
                        <td>sold on</td>
                        <td>ship to</td>
                        <td>price</td>
                        <td>status</td>
                        <td align="center">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {orderItems.length > 0 && !this.state.showLoader
                        ? orderItems
                        : 
                        <tr>
                          <td colSpan={7}>
                            <div className="text-center">
                              No Result Found
                            </div>
                          </td>
                        </tr>
                        }

                      {this.state.showLoader ? (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrdersList, searchOrder }, dispatch);
};

export default connect(null, mapDispatchToProps)(Order_List);
