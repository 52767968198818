export const initialState = {
    parentCategoryList: []
  };
  
  export const categoryReducer = (state = initialState, action) => {

    switch (action.type) {
      case "LIST_ALL_PARENT_CATEGORY":
        return {
          ...state,
          parentCategoryList: action.payload,
        };
      default:
        return state;
    }
  };
  