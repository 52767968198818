import React, { Component } from "react";
import "../../Styles/dashboard.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutUser } from "../../Redux/Actions/authAction";
import { toggleSidebar } from "../../Redux/Actions/commonAction";
import {
  getNotification,
  getNotificationCount,
} from "../../Redux/Actions/dashboardAction";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMore: true,
      offset: 0,
      showDropDown: false,
    };
    this.wrapperRef = React.createRef();
  }

  handleLogout = async (event) => {
    event.preventDefault();
    this.props.logoutUser();
  };

  componentDidMount() {
    document.addEventListener("mousedown", (e) => this.handleClickOutside(e));
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", (e) =>
      this.handleClickOutside(e)
    );
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.showDropDown
    ) {
      this.setState({ showDropDown: false });
    }
  }

  handleBellClick = async () => {
    this.setState({ showDropDown: true });
    this.loadNotifications(true);
  };

  loadNotifications = async (firstLoad = false) => {
    let offset = firstLoad ? 0 : this.state.offset;
    const response = await this.props.getNotification(offset);
    offset = offset + 5;
    this.props.getNotificationCount();
    this.setState({
      offset,
      loadMore: response.totalCount !== this.props.notificationList.length,
    });
  };

  render() {
    const { notificationCount, notificationList } = this.props;
    return (
      <div class="main-header">
        <div class="row">
          <div class="col-lg-10 col-md-10">
            <div class="header-top-left">
              <div class="toggle-btn">
                <button
                  onClick={() => this.props.toggleSidebar()}
                  type="button"
                >
                  <i className="fas fa-bars" />
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="header-top-menu">
              <ul className="notifications-alert-icon">
                <li>
                  <div className="dropdown" ref={this.wrapperRef}>
                    <p
                      title="notifications"
                      className="header-addtocart-item dropdown-toggle mb-0"
                      onClick={this.handleBellClick}
                    >
                      <i className="far fa-bell" />
                      {notificationCount !== 0 && (
                        <span>{notificationCount}</span>
                      )}
                    </p>
                    {this.state.showDropDown && (
                      <ul>
                        {notificationList.length !== 0 &&
                          notificationList.map((notif, index) => (
                            <li
                              key={index}
                              className={notif.is_read ? "" : "active"}
                            >
                              <Link
                                to={JSON.parse(notif.data)["link"]}
                                key={index}
                                onClick={() =>
                                  this.setState({ showDropDown: false })
                                }
                              >
                                {notif.title}
                              </Link>
                            </li>
                          ))}
                        {notificationList.length === 0 ? (
                          <li className="text-center">
                            <p className="mb-0">No notification found!</p>
                          </li>
                        ) : (
                          this.state.loadMore && (
                            <li className="text-center">
                              <button
                                className="orange-outline-btn"
                                onClick={() => this.loadNotifications(false)}
                              >
                                Show More...
                              </button>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </div>
                </li>
                <li>
                  <p
                    title="sign out"
                    onClick={(event) => this.handleLogout(event)}
                    className="mb-0"
                  >
                    <i class="fas fa-power-off"></i>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    notificationList: state.notificationReducer.notificationList,
    notificationCount: state.notificationReducer.notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutUser,
      toggleSidebar,
      getNotification,
      getNotificationCount,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
