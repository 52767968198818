import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getProductCategory = () => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/doba/product-category`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({
            type : "SET_CATEGORY_LIST",
            payload : res.data
        })
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getProductDetail = (spuId) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/doba/product-detail/${spuId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({
            type : "SET_PRODUCT_DETAIL",
            payload : res.data
        })
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getProductList = (body) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/doba/product-list`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data : body
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const addDobaProduct = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/doba/add-product`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data : body
        });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const addProductToCron = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/doba/add-product-cron`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data : body
        });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const addCategoryListToCron = (body) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/doba/add-category-id-list`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data : body
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};


export const addSupplierListToCron = (body) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/doba/add-supplier-id-list`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data : body
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getSellerList = (pageNumber) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/doba/seller-list?pageNumber=${pageNumber}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: "LOADING_COMPLETED" });
        /*dispatch({
            type : "SET_PRODUCT_LIST",
            payload : res.data.data
        })*/
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};