import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as dobaAction from "../../Redux/Actions/dobaAction"
import "./doba.scss"
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const DobaProductSearch = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [productList, setProductList] = useState([])
    const [searchKeyword, setSearchKeyword] = useState("")
    const [timeOutState, setTimeOutState] = useState(null)
    const [selectedSpuList, setSelectedSpuList] = useState([])
    const [disableAddButton, setDisableAddButton] = useState(true)
    const [DataNotFound, setDataNotFound] = useState(false)
    const [messageState, setMessageState] = useState({ success: true, message: '' })
    const [paginationState, setPaginationState] = useState({
        pageNumber: 0,
        showLoader: false,
        hasMore: true,
        totalQuantity: 0
    })

    const handleChange = async (event) => {
        const { value } = event.target;
        setSearchKeyword(value)
    }

    useEffect(() => {
        if (timeOutState) {
            clearInterval(timeOutState);
        }
        let result = setTimeout(() => {
            setPaginationState({
                pageNumber: 0,
                showLoader: false,
                hasMore: true,
                totalQuantity: 0
            })

            loadData(true)
        }, 1000);
        setTimeOutState(result)
    }, [searchKeyword])

    const loadData = async (firstTime) => {
        if (searchKeyword.length === 0) {
            setProductList([])
            return null;
        }
        let requestBody = {
            pageNumber: paginationState.pageNumber,
            keyword: searchKeyword
        };
        setDataNotFound(false);
        const response = await dispatch(dobaAction.getProductList(requestBody))

        if (response && response.success) {
            let products = firstTime ? response.data.goodsList : [...productList, ...response.data.goodsList]
            setProductList(products)
            setPaginationState(prevState => ({
                pageNumber: prevState.pageNumber + 1,
                hasMore: +response.data.totalQuantity > ((prevState.pageNumber) * 20),
                totalQuantity: response.data.totalQuantity
            }))

            if (response.data.goodsList.length === 0) {
                setDataNotFound(true);
            }
        }

    }

    const submitSelectedList = async () => {
        setDisableAddButton(true)
        const response = await dispatch(dobaAction.addProductToCron(selectedSpuList))
        if (response && response.success) {
            setMessageState({
                success: true,
                message: response.message
            })
            let products = [...productList]
            products = products.map((product) => ({
                ...product,
                isAlreadyAdded: product.isAlreadyAdded ? product.isAlreadyAdded : selectedSpuList.includes(product.spuId)
            }))
            setProductList(products)

        } else {
            setMessageState({
                success: false,
                message: "Something went wrong!"
            })
        }
        setTimeout(() => {
            setMessageState({
              success: true,
              message: "",
            });
            navigate(-1);
          }, 2500);
    }

    const handleCheckChange = (spuId) => {
        let spuList = [...selectedSpuList]
        spuList.includes(spuId) ? spuList.splice(spuList.indexOf(spuId), 1) : spuList.push(spuId)
        setDisableAddButton(spuList.length === 0)
        setSelectedSpuList(spuList)
    }

    return (
        <div className="admin-card">

            <div className="admin-card-head">
                <h2>Product Search</h2>
            </div>
            {
                messageState.message && messageState.message.length !== 0 &&
                <div className={`alert alert-${messageState.success ? "success" : "danger"}`}>
                    {messageState.message}
                </div>
            }
            <div className="text-right mb-3">
                <button
                    className={`orange-btn ${disableAddButton && 'disable-btn'}`}
                    disabled={disableAddButton}
                    onClick={submitSelectedList}
                >Add Selected
                </button>
            </div>
            <div className="admin-card-box">
                <div>
                    {
                        productList.length !== 0 &&
                        <p className="m-0 mb-3">Showing {productList.length + " of " + paginationState.totalQuantity} items</p>
                    }
                </div>
                <input
                    type="text"
                    placeholder="Search Product"
                    className="form-control"
                    value={searchKeyword}
                    onChange={handleChange}
                />
                <ul className="doba-search-main-show">
                    <InfiniteScroll
                        dataLength={productList.length}
                        next={() => loadData(false)}
                        hasMore={+paginationState.totalQuantity > productList.length}
                    >
                        {
                            productList.map((product, index) => (
                                <li className="py-2" key={index}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-1 d-flex align-items-center justify-content-center">
                                                <label className="m-0">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedSpuList.includes(product.spuId)}
                                                        onChange={(e) => handleCheckChange(product.spuId)}
                                                        disabled={product.isAlreadyAdded}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-md-11">
                                                <Link to={`/doba-product-detail/${product.spuId}`} className="mr-0">
                                                    <p>{product.title}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </InfiniteScroll>
                    {DataNotFound ? <p>
                        No result found!
                    </p> : ""}
                    {
                        (productList.length !== 0) && (paginationState.totalQuantity > productList.length) &&
                        <p className="text-center">
                            <strong>Loading more products...</strong>
                        </p>
                    }
                </ul>
            </div>
        </div>
    )
}

export default DobaProductSearch;