import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  createBlogCategory,
  getBlogCategoryDetail,
  updateBlogCategoryDetail,
} from "../../Redux/Actions/blogAction";

import withNavigate from "../../Components/customHooks/navigate";

class EditBlogCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: "",
      categoryName: "",
      categoryDescription: "",
      isEdit: false,
      successMessage: "",
      errorMessage: "",
      errorData: {},
      showLoader: false,
      isCategoryApprove: 0,
    };
  }

  componentDidMount = async () => {
    if (
      this.props.location.pathname ===
      `/edit-blog-category/${this.props.param.categoryId}`
    ) {
      this.fetchCategoryDetail(this.props.param.categoryId);
    }
  };

  fetchCategoryDetail = async (categoryId) => {
    let categoryDetail = await this.props.getBlogCategoryDetail(categoryId);

    if (categoryDetail?.data?.data?.length > 0) {
      categoryDetail = categoryDetail.data.data[0];

      this.setState({
        categoryId: categoryDetail.id,
        categoryName: categoryDetail.name,
        categoryDescription: categoryDetail.description,
        isEdit: true,
        isCategoryApprove: categoryDetail.is_active,
      });
    } else {
      this.props.navigate("/blog-category");
    }
  };

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let { value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  validateCategoryInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      categoryName: [],
      categoryDescription: [],
    };

    if (this.state.categoryName.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.categoryName = [
        "Category Name field cannot be empty.",
      ];
    } else if (
      this.state.categoryName.includes(`"`) ||
      this.state.categoryName.includes(`'`) ||
      this.state.categoryName.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.categoryName = ["This field cannot contain quotes"];
    }

    if (this.state.categoryDescription.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.categoryDescription = [
        "Category description field cannot be empty.",
      ];
    } else if (
      this.state.categoryDescription.includes(`"`) ||
      this.state.categoryDescription.includes(`'`) ||
      this.state.categoryDescription.includes("`")
    ) {
      isFormValid = false;
      this.state.errorData.categoryDescription = [
        "This field cannot contain quotes",
      ];
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSubmit = async () => {
    await this.setState({
      successMessage: "",
      errorMessage: "",
      showLoader: true,
    });

    if (this.validateCategoryInfo()) {
      // category validations passed
      let res;
      const {
        categoryId,
        categoryName,
        categoryDescription,
        isCategoryApprove,
      } = this.state;

      if (this.state.isEdit) {
        // call update action
        res = await this.props.updateBlogCategoryDetail(
          { categoryName, categoryDescription, isCategoryApprove },
          categoryId
        );
      } else {
        // call create action
        res = await this.props.createBlogCategory({
          categoryName,
          categoryDescription,
          isCategoryApprove,
        });
      }

      if (res && res.data) {
        if (res.data.success) {
          // success case
          this.setState({
            successMessage: res.data.message,
            showLoader: false,
          });

          window.scrollTo(0, 0);

          if (this.state.isEdit) {
            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          } else {
            this.setState({
              categoryId: "",
              categoryName: "",
              categoryDescription: "",
            });

            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          }
          setTimeout(() => {
            this.props.navigate(-1);
          }, [2000]);
        } else {
          // response with failed case
          this.setState({
            errorMessage: res.data.message,
            showLoader: false,
          });
        }
      } else {
        // error to get respone
        this.setState({
          errorMessage: "Oops! something went wrong.",
          showLoader: false,
        });
      }
    } else {
      // discount validations failed
      this.setState({
        showLoader: false,
      });
    }
  };

  async handleWithAttributeChange(event) {
    const value = +event.target.value;
    this.setState({
      isCategoryApprove: value,
    });
  }

  render() {
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>
            {this.props.param.categoryId ? "Edit" : "Create"} Product Category
          </h2>
        </div>
        <div class="admin-card-box">
          {(() => {
            if (
              this.state.successMessage !== "" &&
              this.state.successMessage !== undefined
            ) {
              return (
                <div className="alert alert-success">
                  {this.state.successMessage}
                </div>
              );
            } else if (
              this.state.errorMessage !== "" &&
              this.state.errorMessage !== undefined
            ) {
              return (
                <div className="alert alert-danger">
                  {this.state.errorMessage}
                </div>
              );
            }
          })()}
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  category name
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.categoryName}
                  onChange={(e) => this.handleChange(e, "categoryName")}
                  type="text"
                  class="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.categoryName &&
                    this.state.errorData.categoryName[0]}
                </span>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>
                  Description
                  <sup>*</sup>
                </label>

                <textarea
                  value={this.state.categoryDescription}
                  onChange={(e) => this.handleChange(e, "categoryDescription")}
                  class="form-control"
                  style={{ minHeight: "100px" }}
                ></textarea>
                <span className="form-field-error">
                  {this.state.errorData?.categoryDescription &&
                    this.state.errorData.categoryDescription[0]}
                </span>
              </div>
            </div>

            <div className="">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Do you want Activate this Category?</label>
                  <div className="custom-radio-input">
                    <p className="container-sub mr-2">
                      No
                      <span>
                        <input
                          type="radio"
                          value="0"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={this.state.isCategoryApprove === 0}
                          name="withAttributeRadio"
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>

                    <p className="container-sub mr-2">
                      Yes
                      <span>
                        <input
                          type="radio"
                          value="1"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={this.state.isCategoryApprove === 1}
                          name="withAttributeRadio"
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-btn text-center">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  class="orange-outline-btn"
                >
                  submit
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span class="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createBlogCategory,
      getBlogCategoryDetail,
      updateBlogCategoryDetail,
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withNavigate(EditBlogCategory));
