import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const DobaBreadcrumb = (props) => {
  const navigate = useNavigate();
  const { allCategoryList } = useSelector((state) => state.dobaReducer);
  const [categoryHierarchy, setCategoryHierarchy] = useState([
    { catName: "Home" },
  ]);

  useEffect(() => {
    setCategoryHierarchy(props.categoryHierarchy);
  }, [props]);

  const handleBreadcrumbClick = async (index) => {
    if (!props.isProductDetailPage && index === categoryHierarchy.length - 1) {
      return null;
    }

    if (index === 0) {
      props.updateBreadcrumb({ categoryList: allCategoryList });
      props.updateBreadcrumb({ categoryHierarchy: [{ catName: "Home" }] });
      setCategoryHierarchy([{ catName: "Home" }]);
    } else {
      let hierarchy = [...categoryHierarchy];
      props.updateBreadcrumb({ categoryList: hierarchy[index]["node"] });
      const updatedHierarchy = hierarchy.splice(0, index + 1);
      setCategoryHierarchy(updatedHierarchy);
      props.updateBreadcrumb({ categoryHierarchy: updatedHierarchy });
    }
    props.updateBreadcrumb({ filterState: "" });
  };

  return (
    <nav aria-label="breadcrumb" className="w-100">
      <ol className="breadcrumb">
        {props.isFromCategory &&
          categoryHierarchy.map((category, index) => (
            <li
              className="breadcrumb-item"
              key={index}
              onClick={() => handleBreadcrumbClick(index)}
            >
              <a>{category.catName}</a>
            </li>
          ))}
        {props.isProductDetailPage && props.isFromCategory && (
          <>
            <li className="breadcrumb-item" onClick={() => navigate(-1)}>
              <a>Product List</a>
            </li>
            <li className="breadcrumb-item anchor-para">
              <a>{props.productTitle}</a>
            </li>
          </>
        )}
        {props.isProductDetailPage &&
          !props.isFromCategory &&
          !props.seller && (
            <>
              <li
                className="breadcrumb-item"
                onClick={() => navigate("/doba-product-search")}
              >
                <a>Product Search</a>
              </li>
              <li className="breadcrumb-item anchor-para">
                <a>{props.productTitle}</a>
              </li>
            </>
          )}
        {props.seller && props.seller.supplierName && (
          <>
            <li
              className="breadcrumb-item"
              onClick={() => navigate("/doba-seller-list")}
            >
              <a>{props.seller.supplierName}</a>
            </li>
            {props.isProductDetailPage && (
              <li className="breadcrumb-item" onClick={() => navigate(-1)}>
                <a>Product List</a>
              </li>
            )}
            {props.productTitle && (
              <li className="breadcrumb-item anchor-para">
                <a>{props.productTitle}</a>
              </li>
            )}
          </>
        )}
      </ol>
    </nav>
  );
};

export default DobaBreadcrumb;
