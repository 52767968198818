import axios from "axios";

import { handleError } from "./commonAction";

const URL = process.env.REACT_APP_API_URL;

export const getStatisticsData =
  (timeframe, startDate = "", endDate = "") =>
  async (dispatch) => {
    try {
      let url = "";

      if (timeframe === "date") {
        if (startDate && startDate !== "" && endDate && endDate !== "") {
          url = `${URL}/api/v1/admin/stats/${timeframe}?startDate=${startDate}&endDate=${endDate}`;
        } else if (startDate && startDate !== "") {
          url = `${URL}/api/v1/admin/stats/${timeframe}?startDate=${startDate}`;
        } else if (endDate && endDate !== "") {
          url = `${URL}/api/v1/admin/stats/${timeframe}?endDate=${endDate}`;
        }
      } else {
        url = `${URL}/api/v1/admin/stats/${timeframe}`;
      }

      const res = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getNotification = (offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/stats/notification?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({
      type: "NOTIFICATION",
      payload: res.data.data,
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getNotificationCount = () => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/stats/notification-count`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({
      type: "NOTIFICATION_COUNT",
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
