import React, { Component } from "react";

import { debounce } from "lodash";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InfiniteScroll from "react-infinite-scroll-component";

import { getCouponList, searchCoupon } from "../../Redux/Actions/couponAction";

class Coupon_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchCoupon(
            this.state.offset,
            this.state.searchKeyword.trim().replace(/[^a-zA-Z 0-9.]+/g, "")
          );
        } else {
          result = await this.props.getCouponList(this.state.offset);
        }

        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  render() {
    const itemsList = this.state.items.map((i) => {
      return (
        <tr key={i.id}>
          <td align="center">{i.id ? i.id : "-"}</td>
          <td>{i.name ? i.name : "-"}</td>
          <td>{i.code ? i.code : "-"}</td>
          <td>
            {i.type && i.discount
              ? i.type === "$"
                ? `$${i.discount}`
                : `${i.discount}%`
              : "-"}
          </td>
          <td>{i.minOrderAmount ? `$${i.minOrderAmount}` : "-"}</td>
          <td>{i.validUptoTS ? i.validUptoTS : "-"}</td>
          <td align="center">
            <div class="vendor-table-btn">
              <Link to={`/edit-coupon/${i.id}`}>
                <button type="button" class="orange-outline-btn">
                  Edit
                </button>
              </Link>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>Coupon list</h2>
          <Link to={"/create-coupon"}>
            <button type="text" class="orange-outline-btn">
              <i class="fas fa-plus"></i> Add
            </button>
          </Link>
        </div>

        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td align="center">Coupon ID</td>
                        <td>name</td>
                        <td>code</td>
                        <td>discount</td>
                        <td>Min Order Amount</td>
                        <td>Valid Onto</td>
                        <td align="center">action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsList}

                      {this.state.showLoader ? (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCouponList, searchCoupon }, dispatch);
};

export default connect(null, mapDispatchToProps)(Coupon_List);
