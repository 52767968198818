import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import * as dobaAction from "../../Redux/Actions/dobaAction";

import DobaBreadcrumb from "./DobaBreadcrumb";
import InfiniteScroll from "react-infinite-scroll-component";

import "./doba.scss";

const DobaProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [productList, setProductList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchKeywordState, setSearchKeywordState] = useState("");
  const [timeOutState, setTimeOutState] = useState(null);
  const [category] = useState({
    ...location?.state?.category,
  });
  const [seller] = useState({ ...location?.state?.seller });
  const propsData = location?.state?.data
    ? JSON.parse(location?.state?.data)
    : {};
  const [paginationState, setPaginationState] = useState({
    pageNumber: 0,
    showLoader: false,
    hasMore: true,
    totalQuantity: 0,
  });
  const [selectedSpuList, setSelectedSpuList] = useState([]);
  const [disableAddButton, setDisableAddButton] = useState(true);
  const [messageState, setMessageState] = useState({
    success: true,
    message: "",
  });

  useEffect(() => {
    loadData(true);
  }, []);

  const loadData = async (firstTime) => {
    setLoader(true);
    setPaginationState({
      showLoader: true,
    });
    let requestBody = {
      pageNumber: paginationState.pageNumber,
    };
    if (seller && seller.supplierId) {
      requestBody["busiId"] = seller.supplierId;
    } else {
      requestBody["catId"] = category.catId;
    }
    if (searchKeywordState.length !== 0) {
      requestBody["keyword"] = searchKeywordState;
    }
    const response = await dispatch(dobaAction.getProductList(requestBody));

    if (response) {
      let products = firstTime
        ? response.data.goodsList
        : [...productList, ...response.data.goodsList];
      const isProductListUpdated = products !== productList;
      setProductList(products);
      setPaginationState((prevState) => ({
        pageNumber: isProductListUpdated
          ? prevState.pageNumber + 1
          : prevState.pageNumber,
        hasMore: +response.data.totalQuantity > prevState.pageNumber * 20,
        totalQuantity: response.data.totalQuantity,
      }));
      setPaginationState({
        showLoader: false,
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    if (timeOutState) {
      clearInterval(timeOutState);
    }
    let result = setTimeout(() => {
      setPaginationState({
        pageNumber: 0,
        hasMore: true,
      });
      loadData(true);
    }, 500);
    setTimeOutState(result);
  }, [searchKeywordState]);

  const handleChange = async (event) => {
    event.persist();
    const { value } = event.target;
    setSearchKeywordState(value);
  };

  const handleCheckChange = (spuId) => {
    let spuList = [...selectedSpuList];
    spuList.includes(spuId)
      ? spuList.splice(spuList.indexOf(spuId), 1)
      : spuList.push(spuId);
    setDisableAddButton(spuList.length === 0);
    setSelectedSpuList(spuList);
  };

  const submitSelectedList = async () => {
    setDisableAddButton(true);
    const response = await dispatch(
      dobaAction.addProductToCron(selectedSpuList)
    );
    if (response && response.success) {
      setMessageState({
        success: true,
        message: response.message,
      });
      let products = [...productList];
      products = products.map((product) => ({
        ...product,
        isAlreadyAdded: product.isAlreadyAdded
          ? product.isAlreadyAdded
          : selectedSpuList.includes(product.spuId),
      }));
      setProductList(products);
    } else {
      setMessageState({
        success: false,
        message: "Something went wrong!",
      });
    }

    setTimeout(() => {
      setMessageState({
        success: true,
        message: "",
      });
      navigate(-1);
    }, 2500);
  };

  const updateBreadcrumb = (data) => {
    if (data.categoryList) {
      propsData.categoryList = data.categoryList;
    } else if (data.categoryHierarchy) {
      propsData.categoryHierarchy = data.categoryHierarchy;
    }
    navigate("/doba-dashboard", { state: JSON.stringify(propsData) });
  };

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>Product List</h2>
      </div>
      {messageState.message && messageState.message.length !== 0 && (
        <div
          className={`alert alert-${
            messageState.success ? "success" : "danger"
          }`}
        >
          {messageState.message}
        </div>
      )}
      <div className="admin-card-box">
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <DobaBreadcrumb
              categoryHierarchy={
                propsData.categoryHierarchy
                  ? [...propsData.categoryHierarchy, category]
                  : []
              }
              seller={seller}
              updateBreadcrumb={(e) => updateBreadcrumb(e)}
              isFromCategory={!!propsData.categoryHierarchy}
            />
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="vendor-table-head-right">
              <div className="vendor-table-search">
                <input
                  type="text"
                  placeholder="Search Product"
                  className="form-control"
                  value={searchKeywordState}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 text-left d-flex align-items-center">
            {productList.length !== 0 &&
              paginationState.totalQuantity !== undefined && (
                <p className="m-0">
                  Showing{" "}
                  {productList.length + " of " + paginationState.totalQuantity}{" "}
                  items
                </p>
              )}
          </div>
          <div className="col-md-6 text-right pt-2">
            <button
              className={`orange-btn ${disableAddButton && "disable-btn"}`}
              disabled={disableAddButton}
              onClick={submitSelectedList}
            >
              Add Selected
            </button>
          </div>
        </div>
        <ul className="doba-search-list-ul">
          <InfiniteScroll
            dataLength={productList.length}
            next={() => loadData(false)}
            hasMore={paginationState.totalQuantity > productList.length}
          >
            {loader ? (
               <div className="text-center pt-2 pb-2">
               <div class="spinner-border" role="status"></div>
             </div>
            
            ) : productList.length > 0 ? (
              productList.map((product, index) => {
                const propsState = {
                  data: JSON.stringify({
                    categoryHierarchy: propsData.categoryHierarchy,
                    categoryList: propsData.categoryList,
                  }),
                };
                if (seller && seller.supplierId) {
                  propsState["seller"] = seller;
                } else {
                  propsState["category"] = category;
                }
                return (
                  <li key={index} className="py-2">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-1 d-flex align-items-center justify-content-center">
                          <label className="m-0">
                            <input
                              type="checkbox"
                              checked={selectedSpuList.includes(product.spuId)}
                              onChange={(e) => handleCheckChange(product.spuId)}
                              disabled={product.isAlreadyAdded}
                            />
                          </label>
                        </div>
                        <div className="col-md-11">
                          <Link
                            to={{
                              pathname: `/doba-product-detail/${product.spuId}`,
                              state: { ...propsState },
                            }}
                            className="mr-0"
                          >
                            {product.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              "No data found"
            )}
          </InfiniteScroll>
        </ul>
      </div>
    </div>
  );
};
export default DobaProductList;
