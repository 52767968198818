import React from "react";

import { Navigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getNotificationCount } from "../Redux/Actions/dashboardAction";

class PrivateGuard extends React.Component {
  dontShowAfterLogin = [
    "/",
    "/signup",
    "/signUp",
    "/login",
    "/reset-password",
    "/forgot-password",
  ];

  componentDidMount() {
    this.props.getNotificationCount();
    setInterval(() => {
      this.props.getNotificationCount();
    }, 10000);
  }

  render() {
    const { route, component: RouteComponent } = this.props;
    let returnData = "";

    if (localStorage.getItem("adminUserId")) {
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = <Navigate to={"/dashboard"} />;
      } else returnData = <RouteComponent />;
    } else {
      if (this.dontShowAfterLogin.includes(route))
        returnData = <RouteComponent />;
      else returnData = <Navigate to={"/login"} />;
    }
    return <div>{returnData}</div>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getNotificationCount }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    ...state,
    authData: state.authReducer.authData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateGuard);
