import React, { Component } from "react";

import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { weekDayValue } from "../../Constant/Constant";
import {
  getOrderDetail,
  updateOrderDetail,
  getOrderTracking,
  updateOrderReturnRequest,
  refundOrderReturnsByOrderId,
} from "../../Redux/Actions/orderAction";

import withNavigate from "../../Components/customHooks/navigate";

import "../../Styles/order-detail.scss";
import "react-confirm-alert/src/react-confirm-alert.css";

class Order_Detail extends Component {
  statePrevCopy = {};
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      customerTrackingDetails: {},
      shippingAddress: {},
      sessionDetails: null,
      isEditForm: false,
      errorData: {},
      disableOrderStatusBtn: false,
      isShippingChargeEnable: false,
      showRefundableBar: false,
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      orderTrackType: [],
      showLoader: false,
      service: "",
      shop: null,
      accessToken: null,
      shopify_fullfillment_id: null,
      location_id: null,
      shopify_order_id: null,
      disputeStatus: null,
      showHideReturnOrderModal: false,
      showModal: false,
    };
  }

  componentDidMount() {
    this.fetchOrderDetail();
    this.fetchOrdertrackInfo(true);
  }

  fetchOrderDetail = async () => {
    const result = await this.props.getOrderDetail(this.props.param.orderId);
    if (result?.data?.data) {
      await this.setState({
        digitalUrl: result.data.data.digital_product_url
          ? JSON.parse(result.data.data.digital_product_url)
          : null,
        orderDetail: result.data.data,
        returnAddress: result.data.data.return_shipment_address
          ? JSON.parse(result.data.data.return_shipment_address)
          : null,
        customerTrackingDetails: result.data.data.trackingDetails
          ? JSON.parse(result.data.data.trackingDetails)
          : null,
        disableOrderStatusBtn:
          result.data?.data?.status === "completed" ||
          result.data?.data?.status === "canceled",
        shippingAddress: result.data.data.address
          ? JSON.parse(result.data.data.address)
          : null,
        billingAddress: result.data.data.billing_Info
          ? JSON.parse(result.data.data.billing_Info)
          : null,
        newCardDetails: localStorage.getItem("new_billing_address")
          ? JSON.parse(localStorage.getItem("new_billing_address"))
          : null,
        sessionDetails: result.data.data.booked_session_details
          ? JSON.parse(result.data.data.booked_session_details)
          : null,
        showRefundableBar: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
              [`canceled`].includes(status.status)
            )
          : false,
        isShippingChargeEnable: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
              [`shipped`, "completed"].includes(status.status)
            )
          : false,
        accessToken: result.data?.data.access_token,
        shop: result.data?.data.shop,
        shopify_fullfillment_id: result.data?.data.shopify_fullfillment_id,
        location_id: result.data?.data.vendor_location_id,
        shopify_order_id: result.data?.data.shopify_order_id,
        disputeStatus: result.data.data.disputeStatus,
        seminarDetails: result.data.data.free_seminar_details
          ? JSON.parse(result.data.data.free_seminar_details)
          : null,
      });
    }
  };

  onEdit = () => {
    this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
    this.setState({
      isEditForm: true,
    });
  };

  handleReturnProductModalShowHide = () => {
    this.setState({
      showHideReturnOrderModal: false,
      returnOrderMessage: "",
    });
  };

  handleReturnOrderAlert = () => {
    this.setState({
      showHideReturnOrderModal: true,
    });
  };

  async handleAcceptRejectReturnRequest(
    value,
    returnStatus,
    orderId,
    vendorMessage
  ) {
    value
      ? this.setState({
          showAcceptLoader: true,
        })
      : this.setState({
          showRejectLoader: true,
        });
    await this.props.updateOrderReturnRequest(
      returnStatus,
      orderId,
      vendorMessage
    );
    await this.fetchOrderDetail();
    this.setState({
      showAcceptLoader: false,
      showRejectLoader: false,
    });
  }

  handleReturnRequestStatus = async () => {
    this.setState({
      showDenyLoader: true,
    });
    const res = this.handleAcceptRejectReturnRequest(
      this.state.isAcceptReturnRequestClicked,
      this.state.isAcceptReturnRequestClicked ? "accepted" : "rejected",
      this.props.param.orderId,
      this.state.returnOrderMessage ? this.state.returnOrderMessage : null
    );
    if (!res?.data?.isError) {
      await this.fetchOrderDetail();
      this.setState({
        successMessage: "Updated successfully!",
      });
    } else {
      this.setState({ errorMessage: "Something went wrong" });
    }
    this.setState({
      showDenyLoader: false,
      showHideReturnOrderModal: false,
    });
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
      });
    }, 2000);
  };

  groupTimeSlotsModal = () => (
    <Modal show={this.state.showModal} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <b>Time slots</b>
      </Modal.Header>
      <Modal.Body className="time-slot-modal">
        <div>
          {
            <>
              {this.state.sessionDetails &&
              this.state.sessionDetails.type === "group" &&
              this.state.sessionDetails?.timeSlots ? (
                <>
                  {Object.entries(this.state.sessionDetails?.timeSlots).map(
                    ([weekDay, slotsArray]) => {
                      return (
                        <>
                          <p className="mb-2">
                            <strong>{weekDayValue[weekDay]}</strong>
                          </p>
                          <ul>
                            {slotsArray &&
                              slotsArray.map((slot) => {
                                return (
                                  <li>
                                    {moment(slot[0], "HH:mm").format("hh:mm A")}{" "}
                                    to{" "}
                                    {moment(slot[1], "HH:mm").format("hh:mm A")}{" "}
                                    {slot[2]
                                      ? `(Every ${slot[2]} ${weekDayValue[weekDay]} Of The Month)`
                                      : null}
                                  </li>
                                );
                              })}
                          </ul>
                        </>
                      );
                    }
                  )}
                </>
              ) : null}
            </>
          }
        </div>
      </Modal.Body>
    </Modal>
  );

  returnOrderModal = () => (
    <Modal
      show={this.state.showHideReturnOrderModal}
      onHide={this.handleReturnProductModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={this.handleReturnProductModalShowHide}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Return order request</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p className="text-center">
                {`Are you sure to ${
                  this.state.isAcceptReturnRequestClicked ? `accept` : `reject`
                } this return request ?`}
              </p>
              {!!!this.state.isAcceptReturnRequestClicked && (
                <>
                  <label>
                    <b>Reason</b>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter text message for vendor"
                    onChange={(e) =>
                      this.setState({ returnOrderMessage: e.target.value })
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleReturnRequestStatus()}
            className="orange-btn"
          >
            yes
            {(() => {
              if (this.state?.showDenyLoader) {
                return (
                  <span class="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
          <button
            onClick={() => this.handleReturnProductModalShowHide()}
            className="orange-btn"
          >
            no
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  handleChange = (event, field, alertPopUp, newValue) => {
    event.persist();

    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    if (!alertPopUp) {
      this.setState((prevState) => {
        return (prevState.orderDetail[field] = event.target.value);
      });
    } else {
      this.setState((prevState) => {
        return (prevState.orderDetail[field] = newValue);
      });
    }
  };

  validateUpdate = () => {
    let isFormValid = true;
    this.state.errorData = {
      trackingCompany: [],
      trackingId: [],
    };

    if (
      this.state.orderDetail.trackingId &&
      this.state.orderDetail.trackingId.length > 0
    ) {
      if (
        !this.state.orderDetail.trackingCompany ||
        this.state.orderDetail.trackingCompany.trim().length === 0
      ) {
        isFormValid = false;
        this.state.errorData.trackingCompany = [
          "Tracking company is required.",
        ];
      }
    }

    if (this.state.orderDetail.status !== "canceled") {
      if (
        this.state.orderDetail.trackingCompany &&
        this.state.orderDetail.trackingCompany.length > 0
      ) {
        if (
          !this.state.orderDetail.trackingId ||
          this.state.orderDetail.trackingId.trim().length === 0
        ) {
          isFormValid = false;
          this.state.errorData.trackingId = ["Tracking Id is required."];
        } else if (
          this.state.orderDetail.trackingId.includes(`"`) ||
          this.state.orderDetail.trackingId.includes(`'`) ||
          this.state.orderDetail.trackingId.includes("`")
        ) {
          isFormValid = false;
          this.state.errorData.trackingId = [
            "This field cannot contain quotes",
          ];
        }
      }
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  onSaveForm = async () => {
    if (this.validateUpdate()) {
      let orderHistoryStatus = [{ status: "confirmed" }];
      if (this.state.orderDetail.orders_status_history !== null) {
        orderHistoryStatus = JSON.parse(
          this.state.orderDetail.orders_status_history
        );
      }
      let orderStatusHistory = [
        ...orderHistoryStatus,
        { status: this.state.orderDetail.status },
      ];
      let isShippingChargeEnable = orderStatusHistory.some((status) =>
        ["shipped", "completed"].includes(status.status)
      );
      await this.setState({
        isEditForm: false,
        isShippingChargeEnable,
      });
      orderStatusHistory = JSON.stringify(orderStatusHistory);

      const res = await this.props.updateOrderDetail({
        ...this.state.orderDetail,
        orders_status_history: orderStatusHistory,
        isShippingChargeEnable,
        shop: this.state.shop,
        accessToken: this.state.accessToken,
        shopify_fullfillment_id: this.state.shopify_fullfillment_id,
        location_id: this.state.location_id,
        shopify_order_id: this.state.shopify_order_id,
      });
      if (res && res.data && !res.data.isError) {
        this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
        await this.fetchOrderDetail();
        this.setState({
          sameTrackingError: "",
        });
      } else if (
        res &&
        res.data &&
        res.data.isError &&
        res.data.data.data.code === "ER_DUP_ENTRY"
      ) {
        this.setState({
          isEditForm: true,
          sameTrackingError:
            "Same Tracking Id already exists with another order Id.",
        });
      } else {
        this.setState({
          ...this.statePrevCopy,
        });
      }
    }
  };

  onCancel = async () => {
    await this.setState({
      ...this.statePrevCopy,
    });
  };

  handleAlertStatusChange = async (event, field, trackingCompany) => {
    if (
      this.state.orderDetail?.paymentStatus === "pending" &&
      field === "status"
    ) {
      return this.updateSubscription();
    }
    let newValue = event.target.value;
    let isOrderDelivered = await this.updateOrderStatus();
    if (
      newValue === "completed" &&
      trackingCompany &&
      field === "status" &&
      !isOrderDelivered
    ) {
      return this.orderCompleteModal();
    }
    confirmAlert({
      title: "",
      message: `Are you sure to change order status as  ${newValue}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleChange(event, field, true, newValue),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  updateSubscription() {
    confirmAlert({
      title: "",
      message: "Order status cannot be changed as the payment is pending!",
      buttons: [
        {
          label: "Ok",
          onClick: async () => {},
        },
      ],
    });
  }

  fetchOrdertrackInfo = async (firstFetch) => {
    this.setState({
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      showLoader: firstFetch ? false : true,
    });
    const result = await this.props.getOrderTracking(this.props.param.orderId);

    if (!result?.data?.isError && result?.data?.data) {
      if (
        result.data.data?.trackRes?.CompletedTrackDetails?.[0]
          ?.TrackDetails?.[0]?.Events?.length > 0
      ) {
        this.createFedexTrackingDetail(
          result.data.data.trackRes.CompletedTrackDetails[0].TrackDetails[0]
            .Events
        );
      } else if (result.data.data.trackRes?.elements?.length > 0) {
        this.createUspsTrackingDetail(result.data.data.trackRes);
      } else if (
        result.data.data.package &&
        result.data.data?.package[0]?.activity
      ) {
        this.createUpsTrackingDetail(result.data.data?.package[0]?.activity);
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  createFedexTrackingDetail = async (trackDetail) => {
    let trackStatus = [];
    const trackingDetails = trackDetail.map((trackItem, index) => {
      trackStatus.push(trackItem.EventDescription);
      const timestamp = trackItem.Timestamp
        ? new Date(trackItem.Timestamp).toISOString().substring(0, 10)
        : `-`;
      const address = trackItem.Address;
      return (
        <tr key={index}>
          <td>
            <div class="track-time">
              <p>
                <span></span> {timestamp}, {address.City ? address.City : null}{" "}
                {address.StateOrProvinceCode
                  ? address.StateOrProvinceCode
                  : null}{" "}
                {address.CountryCode ? address.CountryCode : "-"}
              </p>
            </div>
          </td>
          <td>
            <div class="track-status">
              <p>
                {trackItem.EventDescription ? trackItem.EventDescription : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });

    await this.setState({
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Fedex",
    });
    if (this.state.orderDetail?.trackingCompany) {
      this.updateOrderStatus();
    }
    return;
  };

  createUspsTrackingDetail = (trackDetail) => {
    let trackStatus = [];
    const trackingDetails = trackDetail.elements.map((trackItem, index) => {
      trackStatus.push(trackItem.elements[0].text);
      return (
        <tr key={index}>
          <td>
            <div class="track-time">
              <p>
                <span></span>
              </p>
            </div>
          </td>
          <td>
            <div class="track-status">
              <p>
                {trackItem.elements[0].text ? trackItem.elements[0].text : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });
    this.setState({
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Usps",
    });
  };

  createUpsTrackingDetail = (trackDetail) => {
    let trackStatus = [];
    let trackType = [];
    const trackingDetails = trackDetail.map((trackItem, index) => {
      trackStatus.push(trackItem.status.description);
      trackType.push(trackItem.status.type);
      const timestamp = trackItem.date;
      const address = trackItem.location.address;
      return (
        <tr key={index}>
          <td>
            <div class="track-time">
              <p>
                <span></span> {timestamp}, {address.city ? address.city : null}{" "}
                {address.stateProvince ? address.stateProvince : null}{" "}
                {address.country ? address.country : null}
              </p>
            </div>
          </td>
          <td>
            <div class="track-status">
              <p>
                {trackItem.status?.description
                  ? trackItem.status?.description
                  : null}
              </p>
            </div>
          </td>
        </tr>
      );
    });

    this.setState({
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      orderTrackType: trackType,
      showLoader: false,
      service: "Ups",
    });

    return;
  };

  updateOrderStatus = async () => {
    let orderStatus = this.state.orderTrackStatusRecord.includes("Delivered")
      ? true
      : false;
    let Uspsstage4 =
      this.state.service === "Usps"
        ? this.state.orderTrackStatusRecord.find((a) => a.includes("delivered"))
        : false;
    let Upsstage4 =
      this.state.service === "Ups"
        ? this.state.orderTrackType.find((a) => a.includes("D"))
        : false;
    return orderStatus || Uspsstage4 || Upsstage4;
  };

  orderCompleteModal() {
    confirmAlert({
      title: "",
      message:
        "Order status cannot be changed to Completed currently order is not deliverable",
      buttons: [
        {
          label: "Ok",
          onClick: async () => {
            return;
          },
        },
      ],
    });
  }

  download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
    });
  };

  getFileName = (url) => {
    let name = url.split("/");
    return name[4] + ".jpg";
  };

  async handleAcceptRejectButtonClick(val) {
    this.setState({
      showHideReturnOrderModal: true,
    });
    val
      ? this.setState({
          isAcceptReturnRequestClicked: true,
        })
      : this.setState({
          isAcceptReturnRequestClicked: false,
        });
  }

  handleRefundForOrderReturns = async () => {
    this.setState({
      showApproveLoader: true,
    });
    const res = await this.props.refundOrderReturnsByOrderId(
      this.props.param.orderId
    );
    if (res?.data?.data.statusCode === 201) {
      await this.fetchOrderDetail();
    }
    this.setState({
      showApproveLoader: false,
    });
  };

  onApproveRequest = () => {
    confirmAlert({
      title: "",
      message: `Are you sure to refund amount for this shipment?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.handleRefundForOrderReturns();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const date = this.state.orderDetail.createTS
      ? new Date(this.state.orderDetail.createTS).toISOString().substring(0, 10)
      : null;
    let variationKey = [];
    let variationValue = [];
    if (this.state.orderDetail.variant_values) {
      const variants = JSON.parse(this.state.orderDetail.variant_values);
      for (let key in variants) {
        variationKey.push(key);
        variationValue.push(variants[key]);
      }
    }
    const orderItems =
      this.state.orderDetail.items &&
      this.state.orderDetail.items.length > 0 ? (
        this.state.orderDetail.items.map((i) => {
          return (
            <div key={i.id} class="cart-box w-100">
              <div class="cart-box-detail w-100">
                <div class="cart-box-img">
                  <img src={i.imageName} alt="" />
                </div>
                <div class="cart-box-item-detail w-100">
                  <h2>{i.name}</h2>
                  {this.state.orderDetail.subscription_timeframe && (
                    <p>
                      <strong>Frequency:</strong>
                      <span>
                        {this.state.orderDetail.order_type === "one_time"
                          ? "one time"
                          : this.state.orderDetail.subscription_timeframe}
                      </span>
                    </p>
                  )}
                  {i.customizationNote && i.customizationNote.length !== 0 && (
                    <p>
                      <strong>Customization Note: </strong>
                      <span className="item-custom-desc">
                        {i.customizationNote}
                      </span>
                    </p>
                  )}
                  {i.customizationImage &&
                    i.customizationImage.length !== 0 && (
                      <p className="d-flex align-items-center justify-content-between">
                        <div>
                          <strong>Customization Image:</strong>{" "}
                          <span>{this.getFileName(i.customizationImage)}</span>
                        </div>
                        <span>
                          <a
                            href={i.customizationImage}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View
                          </a>
                          <p
                            onClick={() => this.download(i.customizationImage)}
                          >
                            Download
                          </p>
                        </span>
                      </p>
                    )}
                  {variationKey.length > 0 && (
                    <p>
                      <strong>Variation:- </strong> <br />
                      {variationKey.map((key, index) => (
                        <div className="margin-variation">
                          <strong>{key}: </strong>{" "}
                          <span>{variationValue[index]}</span>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h3>Loading...</h3>
      );
    return (
      <div class="admin-card">
        <div class="order-heading">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="mb-0">order details</h2>
              <p>Check your order details.</p>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="order-edit">
                {(() => {
                  if (!this.state.isEditForm) {
                    return (
                      <button
                        onClick={this.onEdit}
                        type="text"
                        class="orange-outline-btn mr-2"
                        disabled={this.state.orderDetail.status === "canceled"}
                      >
                        <i class="far fa-edit"></i> Update
                      </button>
                    );
                  } else {
                    return (
                      <>
                        <button
                          onClick={this.onSaveForm}
                          type="text"
                          class="orange-outline-btn"
                        >
                          <i class="fa fa-check"></i> Save
                        </button>
                        <button
                          onClick={this.onCancel}
                          type="text"
                          class="orange-outline-btn"
                        >
                          <i class="fa fa-times"></i> Cancel
                        </button>
                      </>
                    );
                  }
                })()}
                {this.state.disputeStatus ? (
                  <Link to={`/dispute/${this.state.orderDetail.id}`}>
                    <button type="text" className="orange-outline-btn ml-2">
                      <i className="far fa-edit"></i>view dispute
                    </button>
                  </Link>
                ) : null}
                {this.state?.customerTrackingDetails?.customer_trackingId &&
                  this.state?.orderDetail?.paymentStatus !== "Refunded" &&
                  this.state?.orderDetail?.orderReturnStatus === "accepted" && (
                    <button
                      onClick={this.onApproveRequest}
                      type="text"
                      className="orange-outline-btn mr-2"
                    >
                      <i className="far fa-edit"></i> Approve
                      {(() => {
                        if (this.state?.showApproveLoader) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  )}
                {this.state.orderDetail.orderReturnId &&
                  this.state.orderDetail.orderReturnStatus === "pending" && (
                    <>
                      <button
                        className="orange-outline-btn mr-2"
                        onClick={() => this.handleAcceptRejectButtonClick(1)}
                      >
                        Accept return
                        {(() => {
                          if (this.state?.showAcceptLoader) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                      </button>
                      <button
                        className="orange-outline-btn"
                        onClick={() => this.handleAcceptRejectButtonClick(0)}
                      >
                        Reject return
                        {(() => {
                          if (this.state?.showRejectLoader) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div class="admin-card-box">
          <section class="track-section">
            <div class="container">
              {orderItems}
              {(this.state.orderDetail?.disputeStatus === "open" ||
                this.state.orderDetail?.disputeStatus === "pending") && (
                <p>
                  Note : You cannot update your order status until the
                  respective dispute is closed!{" "}
                  <Link to={`/dispute/${this.state.orderDetail.id}`}>view</Link>
                </p>
              )}

              <div class="order-detail">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="order-detail-left">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <td>Order No.:</td>
                            <td>
                              #
                              {this.state.orderDetail.orderNumber
                                ? this.state.orderDetail.orderNumber
                                : this.state.orderDetail.id}
                            </td>
                          </tr>
                          <tr>
                            <td>Recipient:</td>
                            <td>
                              {this.state.shippingAddress?.firstname
                                ? `${this.state.shippingAddress?.firstname} ${this.state.shippingAddress?.lastname}`
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipping Address:</td>
                            <td>
                              {this.state.shippingAddress?.address
                                ? `${
                                    this.state.shippingAddress?.address_2
                                      ? this.state.shippingAddress?.address_2 +
                                        ", "
                                      : ""
                                  } ${this.state.shippingAddress?.address}, ${
                                    this.state.shippingAddress?.city
                                  }, ${this.state.shippingAddress?.state}, ${
                                    this.state.shippingAddress?.country
                                  } - ${this.state.shippingAddress?.zipCode}`
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Billing Recipient:</td>
                            <td>
                              {this.state?.billingAddress?.name
                                ? `${this.state.billingAddress?.name}`
                                : this.state?.newCardDetails?.name
                                ? `${this.state?.newCardDetails?.name}`
                                : this.state.shippingAddress?.firstname
                                ? `${this.state.shippingAddress?.firstname} ${this.state.shippingAddress?.lastname}`
                                : `-`}
                            </td>
                          </tr>

                          <tr>
                            <td>Billing Address:</td>
                            <td>
                              {this.state.billingAddress?.address
                                ? `${
                                    this.state.billingAddress?.billingAddress_2
                                      ? this.state.billingAddress
                                          ?.billingAddress_2 + ", "
                                      : ""
                                  } ${this.state.billingAddress?.address}, ${
                                    this.state.billingAddress?.city
                                  }, ${this.state.billingAddress?.state}, 
                                          ${this.state.billingAddress?.zipCode}`
                                : this.state?.newCardDetails?.address
                                ? `${this.state?.newCardDetails.address}, ${this.state?.newCardDetails.city}, ${this.state?.newCardDetails.state}, ${this.state?.newCardDetails.zipCode}`
                                : this.state.shippingAddress?.address
                                ? `${
                                    this.state.shippingAddress?.address_2
                                      ? this.state?.shippingAddress?.address_2 +
                                        ", "
                                      : ""
                                  } ${this.state.shippingAddress?.address}, ${
                                    this.state.shippingAddress?.city
                                  }, ${this.state.shippingAddress?.state}, ${
                                    this.state.shippingAddress?.country
                                  } - ${this.state.shippingAddress?.zipCode}`
                                : `-`}
                            </td>
                          </tr>
                          {this.state.sessionDetails &&
                          this.state.sessionDetails.type !== "group" &&
                          this.state.sessionDetails?.timeSlots ? (
                            <>
                              <tr>
                                <td>Time Slot</td>
                                <td>{`${moment(
                                  this.state.sessionDetails.timeSlots[0][0],
                                  "HH:mm"
                                ).format("hh:mm A")}-${moment(
                                  this.state.sessionDetails.timeSlots[0][1],
                                  "HH:mm"
                                ).format("hh:mm A")}`}</td>
                              </tr>
                              <tr>
                                <td>Frequency</td>
                                <td>
                                  {this.state.sessionDetails.sessionFrequency}
                                </td>
                              </tr>
                            </>
                          ) : null}

                          {this.state.sessionDetails?.timeSlots &&
                          this.state.sessionDetails.type === "group" ? (
                            <tr>
                              <td>Time Slot:</td>
                              <div className="month-freq">
                                <div
                                  onClick={() =>
                                    this.setState({ showModal: true })
                                  }
                                >
                                  <button className="orange-outline-btn mt-2">
                                    view details
                                  </button>
                                </div>
                              </div>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Process Date:</td>
                            <td>{date ? date : `-`}</td>
                          </tr>
                          <tr>
                            <td>Order Status:</td>
                            {(() => {
                              if (!this.state.isEditForm) {
                                return (
                                  <td>
                                    {this.state.orderDetail.status
                                      ? this.state.orderDetail.status
                                          .charAt(0)
                                          .toUpperCase() +
                                        this.state.orderDetail.status.slice(1)
                                      : `-`}
                                  </td>
                                );
                              } else {
                                return (
                                  <select
                                    onChange={(event) => {
                                      this.handleAlertStatusChange(
                                        event,
                                        "status",
                                        this.state.orderDetail.trackingCompany
                                      );
                                    }}
                                    value={this.state.orderDetail.status}
                                    disabled={
                                      this.state.disableOrderStatusBtn ||
                                      this.state.orderDetail.disputeStatus ===
                                        "open" ||
                                      this.state.orderDetail.disputeStatus ===
                                        "pending"
                                    }
                                    class="edit-input form-control"
                                  >
                                    <option value="pending">Pending</option>
                                    <option value="confirmed">Confirmed</option>
                                    <option value="canceled">Canceled</option>
                                    <option value="processing">
                                      Processing
                                    </option>
                                    {this.state.orderDetail.trackingCompany && (
                                      <option value="shipped">Shipped</option>
                                    )}
                                    <option value="completed">Completed</option>
                                    {/* <option value="refund">Refund</option> */}
                                  </select>
                                );
                              }
                            })()}
                            {/* <td>{this.state.orderDetail.status ? this.state.orderDetail.status : `-`}</td> */}
                          </tr>
                          {this.state.orderDetail?.is_seminar_event ? (
                            <>
                              <tr>
                                <td>Event Date:</td>
                                <td>{this.state.seminarDetails?.date}</td>
                              </tr>
                              <tr>
                                <td>Event Time:</td>
                                <td>
                                  {moment(
                                    this.state.seminarDetails?.start,
                                    "HH:mm"
                                  ).format("hh:mm A")}{" "}
                                  to{" "}
                                  {moment(
                                    this.state.seminarDetails?.end,
                                    "HH:mm"
                                  ).format("hh:mm A")}
                                </td>
                              </tr>
                            </>
                          ) : null}
                          <tr>
                            <td>Payment Status:</td>
                            <td>
                              {this.state.orderDetail.paymentStatus
                                ? this.state.orderDetail.paymentStatus
                                    .charAt(0)
                                    .toUpperCase() +
                                  this.state.orderDetail.paymentStatus.slice(1)
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Receipts Voucher:</td>
                            <td>-</td>
                          </tr>
                          {this.state.orderDetail.trackingCompany && (
                            <>
                              {" "}
                              <tr>
                                <td>Tracking Company:</td>
                                {(() => {
                                  if (!this.state.isEditForm) {
                                    return (
                                      <td>
                                        {this.state.orderDetail.trackingCompany
                                          ? this.state.orderDetail
                                              .trackingCompany
                                          : `-`}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <select
                                        onChange={(event) => {
                                          this.handleChange(
                                            event,
                                            "trackingCompany"
                                          );
                                        }}
                                        disabled={
                                          this.state.orderDetail.status ===
                                            "completed" ||
                                          this.state.orderDetail.status ===
                                            "canceled"
                                        }
                                        value={
                                          this.state.orderDetail.trackingCompany
                                        }
                                        class="edit-input form-control"
                                      >
                                        <option
                                          selected="true"
                                          disabled="disabled"
                                        >
                                          Select a tracking company
                                        </option>
                                        <option value="Fedex">Fedex</option>
                                        <option value="USPS">USPS</option>
                                        <option value="UPS">UPS</option>
                                      </select>
                                    );
                                  }
                                })()}
                                <span className="form-field-error">
                                  {this.state.errorData?.trackingCompany &&
                                    this.state.errorData.trackingCompany[0]}
                                </span>
                              </tr>
                              <tr>
                                <td>Delivery Plan:</td>
                                <td>
                                  {this.state.orderDetail.delivery_plan
                                    ? this.state.orderDetail.delivery_plan
                                        .split("_")
                                        .join(" ")
                                    : `-`}
                                </td>
                              </tr>
                              {this.state.orderDetail?.orderReturnStatus ===
                                "accepted" &&
                                this.state.orderDetail
                                  .return_shipment_address && (
                                  <tr>
                                    <td>Return shipment address:</td>
                                    <td>
                                      {`${
                                        this.state.returnAddress
                                          ?.return_shipment_address
                                      }
                                  ${
                                    this.state.returnAddress
                                      ?.returnShipmentAddress_2
                                      ? `,${this.state.returnAddress?.returnShipmentAddress_2}`
                                      : ``
                                  }
                                  ${
                                    this.state.returnAddress?.returnCountry
                                      ? `,${this.state.returnAddress?.returnCountry}`
                                      : ``
                                  },
                                  ${this.state.returnAddress?.returnState},
                                  ${this.state.returnAddress?.returnCity},
                                  ${
                                    this.state.returnAddress
                                      ?.returnShipmentZipcode
                                  }
                                  `}
                                    </td>
                                  </tr>
                                )}
                              {!this.state.orderDetail
                                .customize_delivery_time === 0 && (
                                <tr>
                                  <td>Product Creation Time:</td>
                                  <td>
                                    {this.state.orderDetail
                                      ?.customize_delivery_time === 1
                                      ? `${this.state.orderDetail?.customize_delivery_time} Day`
                                      : `${this.state.orderDetail?.customize_delivery_time} Days`}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                {this.state.orderDetail.customize_questions &&
                                this.state.orderDetail.customize_questions !==
                                  "undefined" &&
                                JSON.parse(
                                  this.state.orderDetail.customize_questions
                                )?.length ? (
                                  <>
                                    <td>Customize Questions:</td>
                                    <ol>
                                      {JSON.parse(
                                        this.state.orderDetail
                                          .customize_questions
                                      ).map((item, index) => (
                                        <li>{item}</li>
                                      ))}
                                    </ol>
                                  </>
                                ) : null}
                              </tr>
                              <tr>
                                <td>Tracking Number:</td>
                                {(() => {
                                  if (!this.state.isEditForm) {
                                    return (
                                      <td>
                                        {this.state.orderDetail.trackingId
                                          ? this.state.orderDetail.trackingId
                                          : `-`}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <input
                                        type="text"
                                        className="edit-input form-control"
                                        disabled={
                                          this.state.orderDetail.status ===
                                            "completed" ||
                                          this.state.orderDetail.status ===
                                            "canceled"
                                        }
                                        value={
                                          this.state.orderDetail.trackingId
                                        }
                                        onChange={(event) => {
                                          this.handleChange(
                                            event,
                                            "trackingId"
                                          );
                                        }}
                                      />
                                    );
                                  }
                                })()}
                                <span className="form-field-error">
                                  {this.state.errorData?.trackingId &&
                                    this.state.errorData.trackingId[0]}
                                </span>
                                <span className="form-field-error">
                                  {this.state.sameTrackingError}
                                </span>
                                {/* <td>-</td> */}
                                {/* <td><input type="text" /></td> */}
                              </tr>
                            </>
                          )}
                          {/* <td>Recepits Voucher:</td>
                                                        <td>-</td> */}
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="order-detail-right">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <td>Item Price</td>
                            <td>
                              {this.state.orderDetail?.items?.length > 0
                                ? `$${this.state.orderDetail.items[0].pricePerUnit
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Quantity</td>
                            <td>
                              {this.state.orderDetail?.items?.length > 0
                                ? `${this.state.orderDetail.items[0].orderedQuantity}`
                                : "-"}
                            </td>
                          </tr>

                          {this.state.orderDetail.trackingCompany && (
                            <tr>
                              <td>Shipping</td>
                              <td>
                                {this.state.orderDetail.shipping_amount
                                  ? `$${this.state.orderDetail.shipping_amount
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "Free"}
                              </td>
                            </tr>
                          )}
                          {this.state.orderDetail.discount &&
                          +this.state.orderDetail.discount > 0 ? (
                            <tr>
                              <td>Discount</td>
                              <td>{`-$${(+this.state.orderDetail.discount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Subtotal</td>
                            <td>
                              {this.state.orderDetail.subTotal
                                ? `$${this.state.orderDetail.subTotal
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Tax</td>
                            <td>
                              {this.state.orderDetail.tax
                                ? `$${this.state.orderDetail.tax
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>
                                {this.state.orderDetail.total
                                  ? `$${this.state.orderDetail.total
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "-"}
                              </b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="upload-gallery mb-4">
                    <div className="d-flex">
                      {this.state.orderDetail.is_digital &&
                      this.state.digitalUrl
                        ? this.state.digitalUrl.map((item) => {
                            if (item.URL.includes("video_gallery/")) {
                              return (
                                <div className="upload-gallery-card">
                                  <video width="200" height="150" controls>
                                    <source src={item.URL} />
                                  </video>
                                </div>
                              );
                            } else if (item.URL.includes("image_gallery/")) {
                              return (
                                <div className="upload-gallery-card">
                                  <a href={item.URL} target="blank">
                                    <img
                                      width="200"
                                      height="150"
                                      src={item.URL}
                                      alt=""
                                    />
                                  </a>
                                </div>
                              );
                            } else if (item.URL.includes("doc/")) {
                              const docNameArray = item.URL.split("/");
                              const docName =
                                docNameArray[docNameArray.length - 1].split(
                                  "?"
                                );
                              return (
                                <div className="upload-gallery-card">
                                  <a href={item.URL} target="blank">
                                    <div className="file-name">
                                      <span>{docName[0]}</span>
                                    </div>
                                  </a>
                                </div>
                              );
                            }
                          })
                        : ""}
                    </div>
                  </div>
                </div>

                {(() => {
                  if (
                    !this.state.isEditForm &&
                    this.state.orderDetail.trackingId
                  ) {
                    return (
                      <div className="text-right  mb-4">
                        <Link
                          to={`/orders/track/${this.state.orderDetail.id}`}
                          type="text"
                          class="orange-outline-btn"
                        >
                          <i class="far fa-edit"></i> Track this order
                        </Link>
                      </div>
                    );
                  }
                })()}
              </div>
              <div class="order-detail-right">
                <div class="table-responsive">
                  {this.state.orderDetail?.admin_commission_percent ? (
                    <table class="table">
                      <tr>
                        <td>
                          Subsciety Commission (
                          {this.state.orderDetail?.admin_commission_percent
                            ? `${this.state.orderDetail.admin_commission_percent}%`
                            : "0"}
                          )
                        </td>
                        <td>
                          {!this.state.showRefundableBar
                            ? this.state.orderDetail
                                ?.admin_commission_percent &&
                              this.state.orderDetail.total
                              ? `$${(
                                  (this.state.orderDetail.total *
                                    this.state.orderDetail
                                      .admin_commission_percent) /
                                  100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : "0"
                            : `$${0}.00`}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Transaction Fee (
                          {this.state.orderDetail?.transaction_fee_percent
                            ? `${this.state.orderDetail.transaction_fee_percent}%`
                            : "0"}
                          )
                        </td>
                        <td>
                          {this.state.orderDetail?.transaction_fee_percent &&
                          this.state.orderDetail.total
                            ? `$${(
                                (this.state.orderDetail.total *
                                  this.state.orderDetail
                                    .transaction_fee_percent) /
                                100
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : "0"}
                        </td>
                      </tr>
                      <tr>
                        <td>Additional Transaction Fee</td>
                        <td>
                          {this.state.orderDetail.additional_transaction_fee
                            ? `$${this.state.orderDetail.additional_transaction_fee
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : "0"}
                        </td>
                      </tr>
                      {this.state.showRefundableBar && (
                        <tr>
                          <td>Total Refundable Amount</td>
                          <td>
                            {this.state.isShippingChargeEnable
                              ? this.state.orderDetail.total
                                ? `$${(
                                    this.state.orderDetail.total -
                                    (this.state.orderDetail
                                      ?.additional_transaction_fee +
                                      this.state.orderDetail.shipping_amount +
                                      (this.state.orderDetail.total / 100) *
                                        this.state.orderDetail
                                          .transaction_fee_percent)
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "0"
                              : this.state.orderDetail.total
                              ? `$${(
                                  this.state.orderDetail.total -
                                  (this.state.orderDetail
                                    ?.additional_transaction_fee +
                                    (this.state.orderDetail.total / 100) *
                                      this.state.orderDetail
                                        .transaction_fee_percent)
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : "0"}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <b>Total Subtractable Amount</b>
                        </td>
                        <td>
                          <b>
                            $
                            {!this.state.showRefundableBar
                              ? this.state.orderDetail.subtractable_amount
                                ? `${this.state.orderDetail.subtractable_amount
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "0"
                              : this.state.isShippingChargeEnable
                              ? (
                                  parseFloat(
                                    (
                                      this.state.orderDetail
                                        ?.additional_transaction_fee +
                                      (this.state.orderDetail.total / 100) *
                                        this.state.orderDetail
                                          .transaction_fee_percent
                                    ).toFixed(2)
                                  ) +
                                  parseFloat(
                                    this.state.orderDetail.total -
                                      (
                                        this.state.orderDetail
                                          ?.additional_transaction_fee +
                                        this.state.orderDetail.shipping_amount +
                                        (this.state.orderDetail.total / 100) *
                                          this.state.orderDetail
                                            .transaction_fee_percent
                                      ).toFixed(2)
                                  )
                                ).toFixed(2)
                              : `${this.state.orderDetail.total.toFixed(2)}`}
                          </b>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Vendor Receivable Amount</b>
                        </td>
                        <td>
                          <b>
                            $
                            {!this.state.showRefundableBar
                              ? this.state.orderDetail.total &&
                                this.state.orderDetail.subtractable_amount
                                ? `${(
                                    this.state.orderDetail.total -
                                    this.state.orderDetail.subtractable_amount
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "0"
                              : this.state.isShippingChargeEnable
                              ? `${this.state.orderDetail.shipping_amount.toFixed(
                                  2
                                )}`
                              : `${0}.00`}
                          </b>
                        </td>
                      </tr>
                    </table>
                  ) : null}
                </div>
              </div>
              {this.state.orderDetail.orderReturnId && (
                <div className="table-responsive border-table-status">
                  <table className="table">
                    {this.state.orderDetail.orderReturnStatus && (
                      <tr>
                        <td>
                          <strong>Order return status:</strong>
                        </td>
                        <td
                          className={`${
                            this.state.orderDetail.orderReturnStatus ===
                            "pending"
                              ? `pendingColor`
                              : this.state.orderDetail.orderReturnStatus ===
                                "rejected"
                              ? `rejectedColor`
                              : `approvedColor`
                          }`}
                        >
                          {this.state.orderDetail.orderReturnStatus
                            ? this.state.orderDetail.orderReturnStatus
                                .charAt(0)
                                .toUpperCase() +
                              this.state.orderDetail.orderReturnStatus.slice(1)
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {this.state.orderDetail.message && (
                      <tr>
                        <td>
                          <strong>Customer's Message:</strong>
                        </td>
                        <td>{this.state.orderDetail.message}</td>
                      </tr>
                    )}
                    {this.state.orderDetail.vendor_message && (
                      <tr>
                        <td>
                          <strong>Message:</strong>
                        </td>
                        <td>{this.state.orderDetail.vendor_message}</td>
                      </tr>
                    )}
                    {this.state.customerTrackingDetails
                      ?.shipping_company_by_customer && (
                      <tr>
                        <td>
                          <strong>Shipping Company:</strong>
                        </td>
                        <td>
                          {
                            this.state.customerTrackingDetails
                              ?.shipping_company_by_customer
                          }
                        </td>
                      </tr>
                    )}
                    {this.state.customerTrackingDetails
                      ?.customer_trackingId && (
                      <tr>
                        <td>
                          <strong>Tracking Id:</strong>
                        </td>
                        <td>
                          {
                            this.state.customerTrackingDetails
                              ?.customer_trackingId
                          }
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              )}
            </div>
          </section>
          {this.returnOrderModal()}
          {this.groupTimeSlotsModal()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrderDetail,
      updateOrderDetail,
      getOrderTracking,
      updateOrderReturnRequest,
      refundOrderReturnsByOrderId,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(withNavigate(Order_Detail));
